import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, Button } from '@material-ui/core';
import { ProfileStatuses } from 'common/profile/enums';

import { useGlobalStores } from 'hooks/useGlobalStores';

import styles from './styles.scss';


const OperatorConfirmComponent = (): ReactElement => {
  const { userStore } = useGlobalStores();

  useEffect(() => {
    userStore.fetchOperators(ProfileStatuses.waiting);
  }, []);

  if (!userStore.operators) return <></>;

  return (
    <Grid container className={styles.root}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>ФИО</th>
            <th>Эл. почта</th>
            <th>Оператор</th>
            <th>Действие</th>
          </tr>
        </thead>
        <tbody>
          {userStore.operators.map((operator) => (
            <tr key={operator.id}>
              <td>{operator.id}</td>
              <td>{operator.fio}</td>
              <td>{operator.email}</td>
              <td>{operator.profile.departmentUnit.shortname}</td>
              <td>
                <Button
                  color="primary"
                  className={styles.acceptButton}
                  onClick={() => userStore.changeStatus(operator.id, ProfileStatuses.accepted)}
                >
                  Подтвердить
                </Button>
                <Button
                  color="secondary"
                  className={styles.cancelButton}
                  onClick={() => userStore.changeStatus(operator.id, ProfileStatuses.canceled)}
                >
                  Отменить
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Grid>
  );
};

export default observer(OperatorConfirmComponent);
export const OperatorConfirm = observer(OperatorConfirmComponent);
