import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { makeAutoObservable } from 'mobx';
import { EventsService } from 'store/apiClients/events';
import { ResultsService } from 'store/apiClients/results';
import { ResultApi } from 'store/apiClients/results/types';
import { AttachmentsStore } from 'store/apiModels/attachments';
import { Input } from 'store/utilityClasses/Input/Input';
import { InputNumeric } from 'store/utilityClasses/InputNumeric';
import { SelectStatusCheck } from 'store/states/resultEdit/SelectStatusCheck';
import { ResultParams } from 'store/apiModels/results/types';
import { generatePath } from 'react-router-dom';
import { Routes } from 'common/routes/enums';
import { recordResultStatusCheck2State } from 'common/results/constants';
import { globalStore } from 'store/contexts';
import { getResultCheckNameByType } from 'common/results/utils';
import { ResultStatusCheck } from 'common/results/enums';
import { formatDateWithoutTZ } from 'utils/date/formatters';
import { ChangeEvent } from 'react';
import { StudentStatusApi } from 'store/apiClients/events/types';
import { Switch } from 'store/utilityClasses/Switch';
import { ResultEditApi } from './types';
import { StudentStatus } from '../eventsNext/types';


export class ResultEditState {
  status = Status.Initial;
  redirectUrl: string | null = null;

  inputComment = new Input();
  inputScore = new InputNumeric();
  inputScoreMax = new InputNumeric();
  resultExpert = new SelectStatusCheck();
  resultProctor = new SelectStatusCheck();

  inputDate: Date | null = null;
  inputTime: Date | null = null;

  switchAbsence = new Switch();
  studentStatus: StudentStatus | null = null;

  attachmentsStore = new AttachmentsStore();
  resultsStore = globalStore.resultsStore;

  readonly resultsService = new ResultsService();
  readonly eventsService = new EventsService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * saveResultAbsent({ eventId, userId }: ResultParams): Generator {
    try {
      this.status = Status.Pending;
      const { status } = (yield this.eventsService.absent(eventId, userId)) as AxiosResponse;
      this.status = Status.Fulfilled;
      this.setRedirectUrl(status, generatePath(Routes.results, { eventId }));
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * saveResult(params: ResultParams): Generator {
    try {
      this.status = Status.Pending;
      const { status } = (yield this.resultsService.saveResult(
        this.toApi(params),
      )) as AxiosResponse<ResultApi>;
      this.status = Status.Fulfilled;
      this.setRedirectUrl(status, generatePath(Routes.result, params));
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  setRedirectUrl(status: number, path: string): void {
    if (status === 200 || status === 202) {
      this.redirectUrl = path;
    }
  }

  * fetchResult(params: ResultParams): Generator {
    this.status = Status.Pending;
    yield Promise.all([this.resultsStore.fetchResult(params), this.fetchStudentStatus(params)]);
    this.status = Status.Fulfilled;
    this.fromApi();
  }

  * fetchStudentStatus(params: ResultParams): Generator {
    try {
      const { data } = (yield this.eventsService.getStudentStatus(params)) as AxiosResponse<StudentStatusApi>;
      this.studentStatus = data.studentStatus;
      if (this.isAbsentStudentStatus) this.switchAbsence.setChecked(true);
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  fromApi(): void {
    const { resultsStore: { result } } = this;

    if (result) {
      this.inputDate = result.dateTime ? new Date(result.dateTime) : new Date();
      this.inputTime = result.dateTime ? new Date(result.dateTime) : new Date();
      this.inputScoreMax.value = String(result.maxScore || '');
      this.inputScore.value = String(result.score || '');
      this.inputComment.value = result.comment || '';
      this.resultExpert.statusCheck = getResultCheckNameByType(result.isExpertChecked, ResultStatusCheck.notChecked);
      this.resultProctor.statusCheck = getResultCheckNameByType(result.isProctorChecked, ResultStatusCheck.notChecked);
      this.attachmentsStore.attachments = result.attachments || [];
    }
  }

  toApi({ eventId, userId }: ResultParams): ResultEditApi {
    if (this.inputDate && this.inputTime) {
      this.inputDate.setHours(this.inputTime.getHours(), this.inputTime.getMinutes());
    }
    return {
      dateTime: this.inputDate ? formatDateWithoutTZ(this.inputDate) : null,
      attachments: this.attachmentsStore.attachments,
      comment: this.inputComment.value || null,
      eventId,
      isExpertChecked: recordResultStatusCheck2State[this.resultExpert.statusCheck],
      isProctorChecked: recordResultStatusCheck2State[this.resultProctor.statusCheck],
      maxScore: Number(this.inputScoreMax.value),
      score: Number(this.inputScore.value),
      userId,
    };
  }

  onSave(params: ResultParams): void {
    if (this.switchAbsence.checked) {
      this.saveResultAbsent(params);
    } else {
      this.saveResult(params);
    }
  }

  setDate(date: Date | null): void { this.inputDate = date; }

  setTime(time: Date | null): void { this.inputTime = time; }

  setInputScoreToMax(): void {
    if (Number(this.inputScore.value) > Number(this.inputScoreMax.value)) {
      this.inputScore.value = this.inputScoreMax.value;
    }
  }
  
  onChangeMaxScore(e: ChangeEvent<HTMLInputElement>): void {
    this.inputScore.onChangeFractionalNumberLimit(e, Number(this.inputScoreMax.value));
  }

  get isAbsentStudentStatus(): boolean { return this.studentStatus === StudentStatus.absent; }
  get isLoading(): boolean { return this.status === Status.Pending; }
  get isEmptyFields(): boolean {
    const { inputDate, inputTime, inputScore, inputScoreMax } = this;
    return !(inputDate && inputTime && inputScore.value && inputScoreMax.value);
  }
  get isForbiddenToSave(): boolean {
    const { switchAbsence, attachmentsStore, isLoading, isEmptyFields } = this;
    return !switchAbsence.checked && (attachmentsStore.isLoading || isLoading || isEmptyFields);
  }
}
