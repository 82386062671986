export enum DateFormats {
  HHmm = 'HH:mm',
  ddMMyyyy = 'dd.MM.yyyy',
  dLLLSpaceHHmm = 'd LLL HH:mm',
  dMMMMSpaceHHmm = 'd MMMM HH:mm',
  isoDate = 'yyyy-MM-dd',
  fullDateWithoutTZ = 'yyyy-MM-dd\'T\'HH:mm:ss',
  doMMMM = 'do MMMM',
  doMMMMeeee = 'do MMMM, eeee',
  dMMMyyyy = 'd MMM yyyy',
}
