import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { makeAutoObservable } from 'mobx';
import { CertificatesService } from 'store/apiClients/certificates';
import { CertificateInfoType, CertificatesCollectionType } from './types';
import { CertificateAccessStatus } from './enums';
import { CertificateFeedbackForm } from 'store/apiModels/profileType/types';


export class CertificatesState {
  status = Status.Initial;

  certificates: CertificatesCollectionType = [];
  rejectComment: string | null = null;
  certificatesService = new CertificatesService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get acceptedCertificates(): CertificatesCollectionType {
    return this.certificates.filter(
      (certificate: CertificateInfoType) => certificate.accessStatus === CertificateAccessStatus.accepted,
    );
  }

  get notAcceptedCertificates(): CertificatesCollectionType {
    return this.certificates.filter(
      (certificate: CertificateInfoType) => certificate.accessStatus !== CertificateAccessStatus.accepted,
    );
  }

  * fetchCertificates(userId: number): Generator {
    try {
      this.status = Status.Pending;
      const { data } = (yield this.certificatesService.getCertificatesByUserId(userId)) as
        AxiosResponse<CertificatesCollectionType>;
      this.status = Status.Fulfilled;
      this.certificates = data;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }
  
  * createFeedbackForCertificate(certificateId: number, feedback: CertificateFeedbackForm): Generator {
    try {
      yield this.certificatesService.createFeedback(certificateId, feedback)
    } catch (err) {
      console.error(err);
    }
  }

  * fetchRejectComment(userId: number): Generator {
    try {
      const response = (yield this.certificatesService.getRejectComment(userId)) as
        AxiosResponse<string | null>;
      this.rejectComment = response.data;
    } catch (error) {
      //
    }
  }

  get isLoading(): boolean { return this.status === Status.Pending; }
}
