import axios, { AxiosResponse } from 'axios';
import { DepartmentTypesId } from 'common/departmens/enums';
import { departmentsApi } from 'constants/api';
import { RECORD_DEPARTMENT_LATIN_NAMES } from 'constants/departments';
import { DepartmentApi, SchoolsVkResponse } from './types';


export interface GetByTypeParams {
  type: DepartmentTypesId
  districtId?: number
  isMoscow?: boolean,
  isExamSchool?: boolean | null
  directionId?: number
}

export interface GetByCityIdParams {
  cityId: number
}

export class DepartmentsService {
  get = async (): Promise<AxiosResponse<DepartmentApi[]>> => (
    axios.get(departmentsApi, {
      params: {
        // TODO временное решение, добавить поиск по названию
        perPage: 1000,
      },
    })
  );

  getSchoolsByCityId = async ({ cityId }: GetByCityIdParams): Promise<AxiosResponse<SchoolsVkResponse>> => (
    axios.get('/proxy/vk_proxy/schools', {
      params: {
        city_id: cityId,
        limit: 5000,
        offset: 0,
      },
    })
  );

  getByType = async (
    { type, districtId, directionId, isMoscow = true, isExamSchool = null }: GetByTypeParams,
  ): Promise<AxiosResponse<DepartmentApi[]>> => (
    axios.get(`${departmentsApi}/byType`, {
      params: {
        // TODO ручка для запроса московских школ, добавить поиск по названию
        isMoscow,
        type: RECORD_DEPARTMENT_LATIN_NAMES[type].toUpperCase(),
        districtId,
        isExamSchool,
        directionId,
      },
    })
  );
}

