import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { RegConditionsApiType } from 'store/apiClients/events/types';
import { RegConditionTypes } from 'store/apiClients/events/enums';
import { RegConditionFormsType } from './types';
import { RegConditionParser } from './utils';
import { registrationConditionForms } from './constants';


export class RegistrationConditions {
  forms: RegConditionFormsType = {};

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setRegConditions(formId: string, type: RegConditionTypes): void {
    this.forms[formId] = { [type]: new registrationConditionForms[type]() };
  }

  deleteFormById(formId: string): void {
    delete this.forms[formId];
  }

  generateForm(): void {
    this.forms = { ...this.forms, [uuidv4()]: {} };
  }

  get formsKeys(): string[] {
    return Object.keys(this.forms);
  }

  getSelectedRegConditionTypeByFormId(formId: string): RegConditionTypes | null {
    const form = this.forms[formId];
    if (form) return Object.keys(form)[0];
    return null;
  }

  setFormsFromApi(rawForms: string): void {
    const rawRegConditions = JSON.parse(rawForms) as RegConditionsApiType;

    this.forms = rawRegConditions.reduce((acc, { cond_type, cond_value }) => {
      const formWithType = RegConditionParser.fromBackend({ cond_type, cond_value });
      if (formWithType) return { ...acc, [uuidv4()]: formWithType };

      return acc;
    }, {} as RegConditionFormsType);
  }

  get parsedRegConditions(): string | null {
    const formattedRegConditions = Object.values(this.forms).reduce((acc, form) => {
      Object.entries(form).forEach(([formId, formValue]) => {
        const regCondition = RegConditionParser.toBackend(formId, formValue);
        if (regCondition) acc.push(regCondition);
      });

      return acc;
    }, [] as RegConditionsApiType);

    return formattedRegConditions.length ? JSON.stringify(formattedRegConditions) : null;
  }
}
