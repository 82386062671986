import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';
import { MCKO_AUTH_SERVER } from 'constants/api';
import { ProfileTypeService } from 'store/apiClients/profileType';
import { ProfileStatuses, ProfileType, ProfileType as ProfileTypeUser } from 'common/profile/enums';
import { RouteRoles } from 'common/routes/enums';
import { Status } from 'constants/status';
import { DepartmentUnitModel, ProfileType } from './types';


export const logOutURL = `https://${MCKO_AUTH_SERVER}`;

export class ProfileTypeStore {
  private readonly TEST_OPERATOR_ID = 83193;

  status = Status.Initial;
  profile: ProfileType | null = null;

  readonly service = new ProfileTypeService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * fetchProfileType(): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.service.get()) as AxiosResponse<ProfileType>;
      this.status = Status.Fulfilled;
      this.profile = response.data;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }

  * logout(): Generator {
    try {
      this.status = Status.Pending;
      yield this.service.delete();
      // const logOutURLWithToken = `${logOutURL}&id_token_hint=${this.profile?.user.mckoToken}`;
      // this.profile = null;
      // переход на сервис МЦКО для прекращения сессии
      window.location.assign('/');
      this.status = Status.Fulfilled;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }

  * acceptPersonalData(): Generator {
    try {
      yield this.service.acceptPersonalData();
      yield this.fetchProfileType();
    } catch (e) {
      //
    }
  }

  get birthDate(): string {
    return this.profile?.user?.birthDate || '';
  }

  get snils(): string {
    return this.profile?.user?.snils || '';
  }

  get email(): string {
    return this.profile?.user?.email || '';
  }

  get isLoaded(): boolean {
    return this.status === Status.Fulfilled || this.status === Status.Rejected;
  }
  
  get isLoading(): boolean {
    return this.status === Status.Pending;
  }

  get isRegistered(): boolean {
    return Boolean(this.profile?.status === ProfileStatuses.accepted);
  }

  get isOperator(): boolean {
    return Boolean(this.profile?.profileType === ProfileTypeUser.operator);
  }

  /**
   * Проверка на тестового оператора, которому не доступен никакой контент кроме плеера
   */
  get isTestOperator(): boolean {
    return this.profile?.id === this.TEST_OPERATOR_ID;
  }

  get isStudent(): boolean {
    const profileType = this.profile?.profileType;
    return Boolean(profileType === ProfileTypeUser.student || profileType === ProfileTypeUser.teacher);
  }
  
  get isVerifier(): boolean {
    return this.profile?.profileType === ProfileType.verifier;
  }

  /**
   * Является ли пользователь учеником и только
   */
  get isStudentPure(): boolean {
    return this.profile?.profileType === ProfileTypeUser.student;
  }
  
  get isTeacher(): boolean {
    return this.profile?.profileType === ProfileType.teacher;
  }

  get isSchool(): boolean {
    return Boolean(this.profile?.profileType === ProfileTypeUser.school);
  }

  get isUniversity(): boolean {
    return Boolean(this.profile?.profileType === ProfileTypeUser.university);
  }

  get isSchoolOrUniversity(): boolean {
    return this.isSchool || this.isUniversity;
  }

  get isOrganization(): boolean {
    const organizations = [ProfileTypeUser.school, ProfileTypeUser.university, ProfileTypeUser.operator];
    return Boolean(this.profile && organizations.includes(this.profile.profileType));
  }
  
  get isParent(): boolean {
    return this.profile?.profileType === ProfileTypeUser.parent;
  }

  get routesRole(): RouteRoles {
    if (this.isTestOperator) {
      return RouteRoles.testOperator;
    }

    if (this.isSchoolOrUniversity) {
      return RouteRoles.schoolAndUniversity;
    }

    if (this.isOperator) {
      return RouteRoles.operator;
    }

    if (this.isStudent) {
      return RouteRoles.student;
    }
    
    if (this.isParent) {
      return RouteRoles.parent;
	}

    return this.profile ? RouteRoles.withoutFullProfile : RouteRoles.default;
  }

  get departmentUnit(): DepartmentUnitModel | null {
    return this.profile?.departmentUnit || null;
  }

  get departmentUnitId(): number | null {
    return this.departmentUnit?.id || null;
  }

  get departmentUnitTypeId(): number | null {
    return this.departmentUnit?.type.id || null;
  }
}
