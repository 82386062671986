import { makeAutoObservable } from 'mobx';
import { GroupUsersService } from 'store/apiClients/groupUsers';
import { Status } from 'constants/status';
import { AxiosResponse } from 'axios';
import { GroupUserModel } from 'store/apiClients/groupUsers/types';
import { saveAs } from 'file-saver';


interface FetchGroupUsersByGroupIdParams {
  groupId: number
}

export class GroupUsersStore {
  groupUsersService = new GroupUsersService();
  statusesByGroupId = new Map<number, Status>();
  groupsUsers = new Map<number, GroupUserModel[]>();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  getLoadStatusByGroupId(groupId: number): Status | null {
    return this.statusesByGroupId.get(groupId) || null;
  }

  getUsersByGroupId(groupId: number): GroupUserModel[] {
    return this.groupsUsers.get(groupId) || [];
  }

  * fetchGroupUsersByGroupId({ groupId }: FetchGroupUsersByGroupIdParams): Generator {
    try {
      this.statusesByGroupId.set(groupId, Status.Pending);
      const { data } = (yield this.groupUsersService.get({ groupId })) as AxiosResponse<GroupUserModel[]>;
      this.groupsUsers.set(groupId, data);
      this.statusesByGroupId.delete(groupId);
    } catch (e) {
      this.statusesByGroupId.set(groupId, Status.Rejected);
    }
  }
  
  * fetchParticipantsOnDateReport(date: string) {
    try {
      const { data } = (yield this.groupUsersService.getParticipantsOnDateReport(date) as AxiosResponse);
      saveAs(data, `участники на дату ${date}.xls`);
    } catch (err) {
      console.error(err);
    }
  }
}
