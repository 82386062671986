import { ChangeEvent } from 'react';
import { makeAutoObservable } from 'mobx';
import { SingleSelect } from 'store/utilityClasses/Select/SingleSelect';
import { MultiSelect } from 'store/utilityClasses/Select/MultiSelect';
import { Input } from 'store/utilityClasses/Input/Input';
import { InputNumeric } from 'store/utilityClasses/InputNumeric';
import { globalStore } from 'store/contexts';
import { User } from 'store/apiModels/user/types';
import { REGEX_RULES } from 'constants/regexRules';
import { DepartmentTypesId } from 'common/departmens/enums';
import { Status } from 'constants/status';
import { UpdateProfileParams } from 'store/apiClients/user/types';
import { createNewUserProfile } from './utils';
import { FetchUpdateProfileParams } from './types';
import { AttachmentsStore } from 'store/apiModels/attachments';


export class ProfileState {
  status = Status.Initial;
  inputFio = new Input();
  inputEmail = new Input();
  inputPhone = new InputNumeric();
  inputBirthDate = new Input();
  inputSnils = new Input();

  selectCity = new SingleSelect();
  selectRegion = new SingleSelect();
  selectGrade = new SingleSelect();
  selectDepartment = new SingleSelect();
  selectTrend = new MultiSelect();
  isMoscow = false;

  readonly profileTypeStore = globalStore.profileTypeStore;
  readonly userProfileStore = globalStore.userStore;
  readonly departmentsStore = globalStore.departmentsStore;
  readonly directionsStore = globalStore.directionsStore;
  readonly attachmentsStore = new AttachmentsStore();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setIsMoscow(isMoscow = true): void {
    this.isMoscow = isMoscow;
  }

  fillSelectsOfUser(user: User): void {
    const departmentId = this.isMoscow
      ? user.profile.departmentUnit.id : user.profile.departmentUnit.school?.vkSchoolId;
    this.inputEmail.setValue(user.email);
    this.inputFio.setValue(user.fio);
    this.inputPhone.setValue(user.profile.phoneNumber ?? '');
    this.inputBirthDate.setValue(user.birthDate ?? '');
    this.inputSnils.setValue(user.snils ?? '');
    this.selectGrade.setId(user.profile.studentProfile?.grade ?? null);
    this.selectRegion.setId(user.profile.departmentUnit.school?.vkRegionId ?? null);
    this.selectCity.setId(user.profile.departmentUnit.school?.vkCityId ?? null);
    this.selectDepartment.setId(departmentId || null);
    this.selectTrend.setValue(user.profile.trendIds);
  }

  * fetchUpdateProfile({ profileId, userId }: FetchUpdateProfileParams): Generator {
    try {
      this.status = Status.Pending;
      const {
        inputEmail, inputFio, inputPhone, selectDepartment, selectCity, selectRegion, selectGrade,
        isMoscow, isVkSchool, selectTrend, inputSnils, inputBirthDate
      } = this;
  
      const user = this.userProfileStore.getUserById(userId);
      const avatarId = this.attachmentsStore.images.length
        ? this.attachmentsStore.images[0].id
        : user && user.profile.fotoAttachment
          ? user.profile.fotoAttachment.id
          : null;
      const schoolShortname = this.departmentsStore.getShortNameById(
        selectDepartment.selectId, DepartmentTypesId.school,
      );

      const userProfile: UpdateProfileParams = createNewUserProfile({
        profileId,
        userId,
        isMoscow,
        isVkSchool,
        phoneNumber: inputPhone.value,
        departmentId: selectDepartment.selectId,
        grade: selectGrade.selectId,
        vkCityId: selectCity.selectId,
        vkRegionId: selectRegion.selectId,
        departmentShortname: schoolShortname,
        fio: inputFio.value,
        email: inputEmail.value,
        trendIds: selectTrend.selectIds,
        birthDate: inputBirthDate.value,
        snils: inputSnils.value,
        avatarId
      });

      yield this.userProfileStore.fetchUpdateProfile(userProfile);
      yield this.userProfileStore.fetchUserById(userId);
      yield this.selfUpdate(userId);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  selfUpdate(userId: number): void {
    if (this.profileTypeStore.profile?.user.id === userId) {
      this.profileTypeStore.fetchProfileType();
    }
  }

  onChangePhone(e: ChangeEvent<HTMLInputElement>): void {
    this.inputPhone.onChange(e, REGEX_RULES.nonNumericValueWithPlusPrefix);
  }

  get isVkSchool(): boolean {
    return !this.isMoscow;
  }

  get isValidForm(): boolean {
    const {
      inputFio, isMoscow, selectRegion, selectCity, selectDepartment, selectTrend,
      profileTypeStore: { profile },
    } = this;

    const isValidFio = Boolean(inputFio.value.trim());
    const isSelectLocation = isMoscow || Boolean(selectCity.selectId);
    const isSelectDepartment = Boolean(selectDepartment.selectId);

    // const isSelectedTrends = profile?.profileType !== ProfileType.student || selectTrend.selectIds.length > 0;

    return Boolean(isValidFio && isSelectLocation && isSelectDepartment);
  }

  get isLoading(): boolean { return this.status === Status.Pending; }
}
