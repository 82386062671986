import { makeAutoObservable } from 'mobx';


export class MultiSelect {
  public readonly defaultIds: number[] = [];
  public selectIds: number[] = [];

  get selectedIds(): number[] {
    const trendIds: number[] = [];
    this.selectIds.forEach((id) => {
      trendIds.push(id);
    });

    return trendIds;
  }

  constructor(defaultIds: number[] = []) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.defaultIds = defaultIds;
    this.setValue(defaultIds);
  }

  setValue(ids: number[]): void {
    this.selectIds = ids;
  }

  reset(): void {
    this.selectIds = this.defaultIds;
  }

  clear(): void {
    this.selectIds = [];
  }
}
