import { makeAutoObservable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { Input } from 'store/utilityClasses/Input/Input';
import { globalStore } from 'store/contexts';
import { Routes } from 'common/routes/enums';
import { AxiosResponse } from 'axios';
import { Time } from 'store/utilityClasses/Time/Time';
import { Status } from 'constants/status';
import { EventGroupModelApi, EventGroupStatApi } from 'store/apiClients/eventGroups/types';
import { FetchTimeslotDistributedParams } from './types';
import { LocationsState } from '../locations';


export class EventManageGroupsState {
  status = Status.Initial;
  markedGroupIds = new Set<number>([]);
  isUpdateLinksMode = false;
  inputLink = new Input();
  isSelectAllGroupsWithTimeslots = false;
  redirectUrl: null | string = null;
  eventId: null | number = null;
  timeStart = new Time();
  timeEnd = new Time();

  locationsState = new LocationsState();
  readonly eventsStore = globalStore.eventsStore;
  readonly eventGroupsStore = globalStore.eventGroupsStore;
  readonly timeslotsStore = globalStore.timeslotsStore;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get eventState(): null | EventGroupStatApi {
    const { eventGroupsStore, eventId } = this;
    const eventState = eventId ? eventGroupsStore.getEventStatById(eventId) : null;
    return eventState || null;
  }

  get eventEnd(): string | null {
    return this.eventState?.eventEnd || null;
  }
  get eventStart(): string | null {
    return this.eventState?.eventStart || null;
  }

  setEventId(eventId: number): void {
    this.eventId = eventId;
  }

  * fetchSetDistributed(eventId: number): Generator {
    try {
      const { status } = (yield this.eventsStore.fetchSetDistributedById(eventId)) as AxiosResponse;
      if (status === 200) {
        this.redirectUrl = generatePath(Routes.registeredMembers, { id: eventId });
      }
    } catch (e) {
      // do nothing
    }
  }

  * fetchTimeslotDistributed({ timeslotId, groupId }: FetchTimeslotDistributedParams): Generator {
    try {
      const isDistributed = (yield this.timeslotsStore.fetchTimeslotDistributed(timeslotId)) as boolean;
      if (isDistributed) {
        yield this.eventGroupsStore.fetchEventGroupById(groupId);
      }
    } catch (e) {
      // do nothing
    }
  }

  * fetchDataByEventId(eventId: number): Generator {
    try {
      this.status = Status.Pending;
      const response = (
        yield this.eventGroupsStore.fetchEventGroupsStat(eventId)
      ) as AxiosResponse<EventGroupStatApi>;

      const responseEventGroups = (
        yield this.eventGroupsStore.fetchEventGroups(eventId)
      ) as AxiosResponse<EventGroupModelApi[]>;

      this.setRedirectToRegisteredMembers(eventId, response.data.openReg);
      this.setRedirectToCreateGroups(eventId, !responseEventGroups.data.length);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * fetchDeleteForEvents(eventId: number): Generator {
    try {
      yield this.eventGroupsStore.fetchDeleteForEvents(eventId);
      const responseEventGroups = (
        yield this.eventGroupsStore.fetchEventGroups(eventId)
      ) as AxiosResponse<EventGroupModelApi[]>;
      this.setRedirectToCreateGroups(eventId, !responseEventGroups.data.length);
    } catch (e) {
      // do nothing
    }
  }

  get listMarkedGroupIds(): number[] {
    return [...this.markedGroupIds];
  }

  setRedirectToRegisteredMembers(eventId: number, openReg: boolean): void {
    if (openReg) {
      this.redirectUrl = generatePath(Routes.registeredMembers, { id: eventId });
    }
  }

  setRedirectToCreateGroups(eventId: number, isEventGroups: boolean): void {
    if (isEventGroups) {
      this.redirectUrl = generatePath(Routes.createGroups, { id: eventId });
    }
  }

  setIsSelectAllGroupsWithTimeslots = (isSelect: boolean): void => {
    this.isSelectAllGroupsWithTimeslots = isSelect;
  };

  setMarkedGroupIds = (markedGroupIds: number[]): void => {
    this.markedGroupIds = new Set(markedGroupIds);
  };

  deleteCheckedGroupId = (id: number): void => {
    this.markedGroupIds.delete(id);
  };

  addCheckedGroupId = (id: number): void => {
    this.markedGroupIds.add(id);
  };

  turnOnUpdateLinksMode = (): void => {
    this.isUpdateLinksMode = true;
  };

  cancelUpdateLinksMode = (): void => {
    this.inputLink.clear();
    this.isUpdateLinksMode = false;
  };

  onClearMarkedGroupIds = (): void => {
    this.isSelectAllGroupsWithTimeslots = false;
    this.markedGroupIds = new Set<number>();
  };

  resetTimeInterval(): void {
    this.timeStart.reset();
    this.timeEnd.reset();
  }

  get isLoading(): boolean { return this.status === Status.Pending; }
}
