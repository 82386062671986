import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DialogWithAccepted } from 'components/elements/DialogWithAccepted';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { TEXT, COOKIE_KEY_PREFIX } from './constants';

const AcceptorCookiesComp = (): ReactElement => {
  const { profileTypeStore: { profile } } = useGlobalStores();
  const [open, setOpen] = useState<boolean>(false);
  
  useEffect(() => {
    if (profile) {
      const isAccepted = Boolean(localStorage.getItem(`${COOKIE_KEY_PREFIX}_${profile.id}`) || null);
      setOpen(!isAccepted);
	}
  }, [profile]);
  
  
  const onConfirm = () => {
    localStorage.setItem(`${COOKIE_KEY_PREFIX}_${profile?.id}`, 'true');
  };
  
  const onClose = () => setOpen(false);
  
  return (
	<DialogWithAccepted
	  open={open}
	  contentText={(
	    <>
		  { TEXT }
		</>
	  )}
	  onClose={onClose}
	  onConfirm={onConfirm}
	  label='Принять все файлы cookie'
	  buttonConfirmText='Продолжить'
	  required
	/>
  );
};

export const AcceptorCookies = observer(AcceptorCookiesComp);
