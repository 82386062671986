import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { DialogWithAccepted } from 'components/elements/DialogWithAccepted';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { LINK, TEXT } from './constants';


const AcceptorPersonalDataComp = (): ReactElement => {
  const { profileTypeStore, profileTypeStore: { profile } } = useGlobalStores();

  if (!profile) return <></>;

  const onConfirm = () => {
    profileTypeStore.acceptPersonalData();
  };

  return (
    <DialogWithAccepted
      open={!profile.user.personalDataAgreement}
      contentText={(
        <>
          {TEXT}
          <br />
          <br />
          <a href={LINK}>
            {LINK}
          </a>
        </>
      )}
      onClose={() => {}}
      onConfirm={onConfirm}
      required
    />
  );
};

export const AcceptorPersonalData = observer(AcceptorPersonalDataComp);
