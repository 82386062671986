export enum EventUserStatuses {
  absent = 'absent',
  expert = 'expert',
  proctor = 'proctor',
  results = 'results',
  waiting = 'waiting',
  accepted = 'accepted',
  canceled = 'canceled',
  rejected = 'rejected',
  subscribed = 'subscribed',
}
