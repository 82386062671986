import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { AcademicYearApi } from 'store/apiClients/academicYear/types';
import { AcademicYearService } from 'store/apiClients/academicYear';
import { Status } from 'constants/status';
import { AcademicYearType } from './types';


export class AcademicYearStore {
  public readonly service: AcademicYearService;
  public status = Status.Initial;
  public years: AcademicYearType = new Map<number, AcademicYearApi>();
  public defaultYear: number | null = null;

  public getYearNameById = (id: number): string => this.years.get(id)?.name || '';

  get yearIds(): number[] {
    return [...this.years.keys()];
  }

  constructor() {
    makeAutoObservable(this);
    this.service = new AcademicYearService();
  }

  * fetchYears(): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.service.getYears()) as AxiosResponse<AcademicYearApi[]>;
      this.setYearsFromApi(response.data);
      this.defaultYear = this.getDefaultYear();
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  private setYearsFromApi(years: AcademicYearApi[]): void {
    years.forEach((year) => {
      this.years.set(year.startYear, year);
    });
  }

  private getDefaultYear(): number | null {
    const years = [...this.years.values()];
    if (!years) {
      return null;
    }

    const defaultYear = years.find((y) => y.defaultSelect);

    return defaultYear ? defaultYear.startYear : null;
  }
}
