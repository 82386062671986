import { makeAutoObservable } from 'mobx';
import { SubscriberApi } from 'store/apiClients/globalEvent/types';
import { Input } from 'store/utilityClasses/Input/Input';
import { SignersType } from './types';


export class Signers {
  signers: SignersType[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get signersIds(): Array<number> {
    return [...this.signers.keys()];
  }

  createSigner(event?: MouseEvent, subscriber?: SubscriberApi): void {
    const inputPosition = new Input(subscriber?.post || '');
    const inputName = new Input(subscriber?.fio || '');

    this.signers.push({ inputPosition, inputName });
  }

  deleteSignerById(id: number): void {
    this.signers.splice(id, 1);
  }
}
