import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';
import { globalStore } from 'store/contexts';
import { Pagination } from 'store/utilityClasses/Pagination';
import { MetaEventCodeType, MetaEventType, OrganizerApi } from 'store/apiClients/globalEvent/types';
import { GlobalEventService } from 'store/apiClients/globalEvent';
import { FilterInput } from 'store/utilityClasses/FilterInput';
import { Status } from 'constants/status';
import { setFiltersToStore } from 'utils/filter';
import { GlobalEventsFilters } from './enums';
import { GlobalEventsDepartments } from './UtilityClasses/GlobalEventsDepartments';
import { GlobalEventTypes } from './UtilityClasses/GlobalEventTypes';


export class GlobalEventsFiltersState {
  status = Status.Initial;

  pagination: Pagination;
  departmentId = new FilterInput(GlobalEventsFilters.departmentId);
  globalEventTypeId = new FilterInput(GlobalEventsFilters.globalEventTypeId);

  globalEventTypes = new GlobalEventTypes();
  organizers = new GlobalEventsDepartments();

  readonly globalEventsStore = globalStore.globalEventsStore;

  readonly service = new GlobalEventService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.pagination = new Pagination(this.fetchGlobalEvents);
    setFiltersToStore<this, GlobalEventsFilters>(
      this,
      Object.values(GlobalEventsFilters),
      this.fetchGlobalEvents,
      this.pagination.resetPage,
    );
  }

  * fetchGlobalEvents(): Generator {
    try {
      this.status = Status.Pending;

      const params = {
        page: this.pagination.page,
        organizerId: this.departmentId.selectedNumberValue,
        globalEventId: this.globalEventTypeId.selectedNumberValue,
      };

      const response = (yield this.globalEventsStore.fetchGlobalEvents(params)) as AxiosResponse<MetaEventType[]>;
      this.pagination.setPages(response.headers);

      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * fetchGlobalEventFilterInfo(): Generator {
    try {
      const [responseGlobalEventsType, responseOrganizers] = (yield Promise.all([
        this.service.getGlobalEventsTypes(),
        this.service.getGlobalEventsOrganizers(),
      ])) as [AxiosResponse<MetaEventCodeType[]>, AxiosResponse<OrganizerApi[]>];

      this.globalEventTypes.setGlobalEventTypes(responseGlobalEventsType.data);
      this.organizers.setOrganizers(responseOrganizers.data);
    } catch (e) {
      //
    }
  }

  resetDepartmentFilter(): void {
    this.pagination.resetPage();
    this.departmentId.clearFilter();
    this.fetchGlobalEvents();
  }

  resetFilters(): void {
    this.pagination.resetPage();
    this.departmentId.clearFilter();
    this.globalEventTypeId.clearFilter();
    this.fetchGlobalEvents();
  }
}
