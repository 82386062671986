import { AxiosResponse } from 'axios';


export enum StudentStatus {
  absent = 'absent',
  accepted = 'accepted',
  participated = 'participated',
  results = 'results',
  subscribed = 'subscribed',
  waiting = 'waiting',
}

export type EventNextPartnersType = {
  id: number,
  partnerName: string,
  partnerTypeId: number,
};

export type EventNextType = {
  eventDate?: string,
  giveChooseTimeslot?: boolean,
  id: number,
  link?: string,
  partners: Array<EventNextPartnersType>,
  shortText: string,
  startDate: string,
  startTime: string,
  studentStatus: StudentStatus,
  title: string,
  location: {
    id: number,
    locationAddress: string,
    locationName: string,
  },
  direction?: {
    id: number;
    name: string;
    latinName: string
  }
};

export interface EventNextWaiting extends EventNextType {
  directionId: number,
}

export type EventOrgNotificationType = {
  count: number,
  id: number,
  partners: Array<EventNextPartnersType>,
  shortText: string,
  title: string
};

export type EventsNextDatesObjType = Record<string, EventNextType[]>;

export type FetchEventsNextAxiosResponse = [
  AxiosResponse<EventNextType[]>,
  AxiosResponse<EventNextWaiting[]>,
  AxiosResponse<EventNextType[]>,
];
