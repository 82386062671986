import { ChangeEvent } from 'react';
import { WIDTH_AREA } from './constants';
import { Position } from './types';


export const getY = (count: number): Partial<Position> => ({ y: count * 70 + 50 });

export const getImgRelHeight = (event: ChangeEvent<HTMLInputElement>, cb: (height: number) => void): void => {
  if (!event.target?.files?.length) return;

  const image = new Image();
  image.src = window.URL.createObjectURL(event.target.files[0]);
  image.onload = () => {
    cb(Math.round((WIDTH_AREA / image.width) * image.height));
  };
};
