import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, generatePath } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { Routes } from 'common/routes/enums';
import { UserPhotoStatus } from 'store/states/studentsPhotos/enum';
import { PHOTO_REQUIREMENTS } from './constants';
import styles from './styles.scss';

const InvalidPhotoModalComp = (): ReactElement => {
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const { profileTypeStore: { profile, isStudentPure } } = useGlobalStores();
  
  useEffect(() => {
    if (profile) {
      setOpen(profile?.fotoStatus === UserPhotoStatus.canceled && isStudentPure);
	}
  }, [profile]);
  
  const onConfirm = () => {
  	onClose();
  	history.push(generatePath(Routes.profile, {id: profile?.user.id}));
  };
  
  const onClose = () => {
    setOpen(false);
  };
  
  return (
	<Dialog
	  open={open}
	  onClose={onClose}
	  className={styles.dialog}
	  classes={{
		paper: styles.paperOverride
	  }}
	>
	  <IconButton aria-label="закрыть окно" className={styles.buttonClose} onClick={onClose}>
		<IconClose />
	  </IconButton>
	  <DialogContent className={styles.dialogContent} dividers>
		<Typography variant="h5">Ваше фото для валидации отклонено</Typography>
		<Box className={styles.contentBlock}>
		  <Typography className={styles.blockTitle}>Причина отклонения фото:</Typography>
		  <Typography>{ profile?.fotoComment }</Typography>
		</Box>
		<Box className={styles.contentBlock}>
		  <Typography className={styles.blockTitle}>Требования к фото</Typography>
		  <ul className={styles.requirementsList}>
			{
			  PHOTO_REQUIREMENTS.map((requirement, idx) => (
				<li key={idx}><Typography>{requirement}</Typography></li>
			  ))
			}
		  </ul>
		</Box>
	  </DialogContent>
	  <DialogActions className={styles.buttonActionBox}>
		<Button onClick={onConfirm} color="primary">Перейти в редактирование профиля</Button>
		<Button onClick={onClose} color="primary">Закрыть</Button>
	  </DialogActions>
	</Dialog>
  );
};

export const InvalidPhotoModal = observer(InvalidPhotoModalComp);
