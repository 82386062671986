import { makeAutoObservable } from 'mobx';
import { GetTrendsParams, TrendsApi } from 'store/apiClients/trends/types';
import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { TrendsService } from 'store/apiClients/trends';
import { Trend } from './types';


export class TrendsStore {
  status = Status.Initial;
  trends = new Map<number, Trend>();
  readonly service: TrendsService;

  constructor() {
    makeAutoObservable(this);
    this.service = new TrendsService();
  }

  * fetchTrends(params: GetTrendsParams): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.service.get(params)) as AxiosResponse<TrendsApi[]>;
      this.resetTrends();
      this.setTrendsFromApi(response.data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  setTrendsFromApi = (data: TrendsApi[]): void => {
    data.forEach((trend) => {
      this.trends.set(trend.id, {
        id: trend.id,
        name: trend.name,
        directionId: trend.directionId,
      });
    });
  };

  get trendsIds(): Array<number> {
    return [...this.trends.keys()];
  }

  getTrendById = (id: number): Trend | null => this.trends.get(id) || null;

  getTrendNameById = (id: number): string => this.getTrendById(id)?.name || '';

  getTrendNameByStrId = (id: string | number): string => {
    const idParsed = Number(id);

    return this.getTrendById(idParsed)?.name || '';
  };

  get isTrendsWasLoaded(): boolean {
    const { trends, status } = this;
    return Boolean(trends.size) && status === Status.Fulfilled;
  }

  resetTrends = (): void => {
    this.trends.clear();
  };
}
