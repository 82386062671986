import { makeAutoObservable } from 'mobx';
import { OrganizerApi } from 'store/apiClients/globalEvent/types';


export class GlobalEventsDepartments {
  organizers: OrganizerApi[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setOrganizers(data: OrganizerApi[]): void {
    this.organizers = data;
  }

  get organizersIds(): number[] {
    return this.organizers.map((item) => item.id);
  }

  getShortNameById(id: number): string {
    return this.organizers.find((item) => item.id === id)?.shortname || '';
  }
}
