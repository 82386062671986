import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { AppBar } from '@material-ui/core';
import { NavLink, Link } from 'react-router-dom';
import { Routes } from 'common/routes/enums';
import { useGlobalStores } from 'hooks/useGlobalStores';
import {
  NavLinkType, NAV_LINKS, NAV_LINKS_OPERATOR,
  NAV_LINKS_SCHOOL, NAV_LINKS_UNIVERSITY, NAV_LINKS_STUDENTS,
} from 'constants/routes';
import { LogInButton } from 'components/elements/LogInButton';
import logoMcko from 'images/mcko-logo.png';
import { DropdownNavItemButton } from 'components/elements/Buttons/DropdownNavItemButton';
import { Flex } from 'components/elements/Utilities/Flex';
import { ProfileInfo } from './ProfileInfo';
import { getKimLink, getLinkCertificate } from './utils';
import styles from './styles.scss';


export const NavBarComp = (): ReactElement => {
  const { profileTypeStore } = useGlobalStores();
  const {
    isRegistered, isStudent, isSchool, isOperator, isUniversity, profile,
    isTestOperator, isParent
  } = profileTypeStore;

  const isActive = (item: NavLinkType): boolean => {
    return (item.children || [])
      .map((child: NavLinkType) => child.to)
      .filter(Boolean)
      .includes(window.location.pathname)
  };
  
  const innerLink = (item: NavLinkType) => (
    <NavLink
      exact={item.to === Routes.landing}
      to={item.to}
      className={styles.navLink}
      activeClassName={styles.active}
    >
      {item.title}
    </NavLink>
  );

  const externalLink = (item: NavLinkType) => (
    <a
      href={item.to}
      className={styles.navLink}
      target="_blank"
      rel="noreferrer"
    >
      {item.title}
    </a>
  );
  
  const dropdownNavItem = (item: NavLinkType) => (
    <DropdownNavItemButton item={item} isActive={isActive(item)}/>
  );

  const renderNavLinks = (item: NavLinkType) => (
    <React.Fragment key={item.title}>
      {!item.isExternal && !item.children && innerLink(item)}
      {item.isExternal && !item.children && externalLink(item)}
      {item.children && dropdownNavItem(item)}
    </React.Fragment>
  );

  const getLinksByProfileType = () => {
    switch (true) {
      case isTestOperator: return [];
      case isStudent:
        return [...NAV_LINKS_STUDENTS, getLinkCertificate(profile?.user.id)]
      case isParent:
        return [...NAV_LINKS, getLinkCertificate(profile?.user.id)];
      case isUniversity: return [...NAV_LINKS_UNIVERSITY, getKimLink()];
      case isSchool: return NAV_LINKS_SCHOOL;
      case isOperator: return [...NAV_LINKS_OPERATOR, getKimLink()];
      default: return NAV_LINKS;
    }
  };

  return (
    <AppBar component="nav" className={styles.root}>
      <Link className={styles.logoWrapper} to={Routes.landing}>
        <img className={styles.logo} src={logoMcko} alt="Логотип МЦКО" />
        <h1 className={styles.title}>
          Московский центр
          <br />
          качества образования
        </h1>
      </Link>
      <Flex spacing={0}>
        {isRegistered && getLinksByProfileType().map(renderNavLinks)}
      </Flex>
      <Flex spacing={4} align="center">
        <ProfileInfo />
        <LogInButton />
      </Flex>
    </AppBar>
  );
};

export const NavBar = observer(NavBarComp);
