import clsx from 'clsx';
import React, { ReactElement } from 'react';
import styles from './styles.scss';


interface Props {
  show?: boolean,
  relative?: boolean,
  fixed?: boolean,
  top?: boolean,
}

export const Loader = ({ show, relative, fixed, top }: Props): ReactElement => (
  show ? (
    <div className={clsx(
      styles.layout,
      relative && styles.relative,
      fixed && styles.fixed,
    )}
    >
      <div className={clsx(styles.loader, top && styles.top)}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  ) : <></>
);
