import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Variant } from '@material-ui/core/styles/createTypography';
import styles from './styles.scss';


interface Props {
  variant?: Variant,
  title: string,
  isEdit: boolean,
  className?: string,
}

const EditorTitleComp = ({ variant = 'h4', title, isEdit, className }: Props): ReactElement => {
  const { landingStore: { edited: { title: { value, onChange } } } } = useGlobalStores();

  return isEdit ? (
    <TextField
      multiline
      inputProps={{ className: styles.input }}
      className={clsx(styles.textArea, className)}
      variant="outlined"
      aria-label="введите заголовок"
      value={value}
      onChange={onChange}
    />
  ) : (
    <Typography variant={variant} className={clsx(styles.text, className)}>{title}</Typography>
  );
};

export const EditorTitle = observer(EditorTitleComp);
