import axios, { AxiosResponse } from 'axios';
import { eventApi, eventsApi, globalEventsApi } from 'constants/api';
import { EventResult } from 'common/eventResult/types';
import { TypeOfEvent } from 'store/states/createEvent/utilityClasses/SelectTypeOfEvent/enum';
import { ResultParams } from 'store/apiModels/results/types';
import {
  CheckPairEventInfoForUser,
  CheckPairEventInfoParams,
  CreateEventDataApiType,
  GetEventResultsParams,
  StudentStatusApi,
} from './types';
import { EventCardType, GlobalEventCardType } from '../../apiModels/events/types';


export enum EventPeriodParam {
  next = 'next',
  past = 'past',
}

export interface EventsGetParams {
  page?: number | null,
  perPage?: number,
  partnerId?: number | null,
  directionId?: number| null
  trendId?: number | null,
  donmFilter?: string | null,
  onDate?: string | null,
  onlyActual?: boolean | null,
  onlyMy?: boolean,
  tags?: Array<number>,
  studentId?: number,
  period?: EventPeriodParam | null,
  schoolYear?: number | null,
  trendIds?: number[] | null,
  onlyActualReg?: boolean,
  eventType?: TypeOfEvent | null,
}

export interface EventsAcceptParams {
  eventId: number,
  departmentUnitId?: number | null,
  timeslotId?: number | null,
  userId?: number | null,
}

export interface GlobalEventsParams {
  page?: number | null;
  perPage?: number,
  onlyActual?: boolean | null;
}

export interface PaginationProps {
  page?: number | null;
  perPage?: number
}

export interface SubscribeGlobalEventParams {
  combineEventId: number;
  userId: number | null;
  departmentUnitId: number | null;
  trendIds: number[];
}

export class EventsService {
  get = async ({
    page = 1, perPage = 100, partnerId, directionId, trendId, onDate,
    onlyActual = null, onlyMy, tags, studentId, period = null, donmFilter,
    schoolYear, trendIds, onlyActualReg, eventType,
  }: EventsGetParams = {}): Promise<AxiosResponse> => axios.get(
    eventsApi, {
      params: {
        page,
        per_page: perPage,
        partner_id: partnerId,
        direction_id: directionId,
        trend_id: trendId,
        donm_filter: donmFilter,
        on_date: onDate,
        only_actual: onlyActual,
        only_my: onlyMy,
        tags: tags?.join(',') || null,
        student_id: studentId,
        period_type: period,
        school_year: schoolYear,
        trend_ids: trendIds?.join(',') || null,
        only_actual_reg: onlyActualReg,
        event_type: eventType || null,
      },
    },
  );

  deleteById = async (id: number): Promise<AxiosResponse> => (
    axios.delete(`${eventsApi}/${id}`)
  );

  getEventResults = async ({ event_id, fio_email, page }: GetEventResultsParams): Promise<AxiosResponse<EventResult[]>> => (
    axios.get(`${ eventApi }/results`, {
      params: {
        event_id,
        fio_email,
        per_page: 100,
        page,
      },
    })
  );

  getStudentStatus = async ({ eventId, userId }: ResultParams): Promise<AxiosResponse<StudentStatusApi>> => (
    axios.get(`${eventApi}/results/current_status`, {
      params: {
        event_id: eventId,
        user_id: userId,
      },
    })
  );

  getById = async (id: string | number): Promise<AxiosResponse> => axios.get(`${eventsApi}/${id}`);

  create = async (data: CreateEventDataApiType): Promise<AxiosResponse> => (
    axios.post(eventsApi, data)
  );

  accept = async (
    { eventId, departmentUnitId = null, timeslotId = null, userId = null }: EventsAcceptParams,
  ): Promise<AxiosResponse> => (
    axios.put(`${eventsApi}/accept/${eventId}`, null, {
      params: {
        department_unit_id: departmentUnitId,
        timeslot_id: timeslotId,
        user_id: userId,
      },
    })
  );

  absent = async (eventId: string | number, userId: string | number): Promise<AxiosResponse> => (
    axios.put(`${eventsApi}/absent/${eventId}`, null, { params: { user_id: userId } })
  );

  cancel = async (id: string | number): Promise<AxiosResponse> => (
    axios.put(`${eventsApi}/cancel/${id}`)
  );

  update = async (data: CreateEventDataApiType): Promise<AxiosResponse> => (
    axios.put(eventsApi, data)
  );

  subscribe = async (
      eventId: string | number,
      locationId: number | null,
      timeslotId: number | null
  ): Promise<AxiosResponse> => (
    axios.post(`${eventsApi}/subscribe/${eventId}`, null, { params: { location_id: locationId, timeslot_id: timeslotId } })
  );
  
  changeSubscriptionParameters = async (
    eventId: string | number,
    locationId: number | null,
    timeslotId: number | null
  ): Promise<AxiosResponse> => (
    axios.put(`${eventsApi}/change_subscription_parameters/${eventId}`, null, { params: { location_id: locationId, timeslot_id: timeslotId } })
  );

  subscribeToTeamEvent = async (eventId: string | number, locationId: number | null, teamId: number): Promise<AxiosResponse> => (
    axios.post(`${eventsApi}/subscribe/${eventId}`, null, { params: { location_id: locationId, team_id: teamId }})
  );
  
  unsubscribe = async (eventId: string | number): Promise<AxiosResponse> => (
    axios.delete(`${eventsApi}/unsubscribe/${eventId}`)
  );

  setDistributed = async (eventId: number): Promise<AxiosResponse> => (
    axios.put(`${eventsApi}/distributed/${eventId}`)
  );

  getStatisticTopSchools = async (): Promise<AxiosResponse> => (
    axios.get(`${eventsApi}/top-school-by-stages`)
  );

  getStatisticTopSchoolsByCategories = async (): Promise<AxiosResponse> => (
    axios.get(`${eventsApi}/top-school-by-categories`)
  );



  /**
   * Проверить наличие парного события для указанного
   */
  checkPairEventInfoForUser = async (
    params: CheckPairEventInfoParams,
  ): Promise<AxiosResponse<CheckPairEventInfoForUser>> => (
    axios.get(`${eventsApi}/check_pair_event_info_for_user`, { params })
  );
  
  getGlobalEvents = async ({ page = 1, perPage = 250, onlyActual = null }: GlobalEventsParams): Promise<AxiosResponse<GlobalEventCardType[]>> => (
    axios.get(globalEventsApi, {
      params: {
        page,
        per_page: perPage,
        only_actual: onlyActual
      }
    })
  );
  
  subscribeToGlobalEvent = async (params: SubscribeGlobalEventParams): Promise<AxiosResponse<any>> => (
    axios.post(`${eventApi}/combine_event_user`, params)
  );
  
  getEventsWithoutRegistrationForGlobalEvent = async (): Promise<AxiosResponse> => (
    axios.get(`${eventsApi}/events_to_continue_reg`)
  );
  
  checkNeedContinueRegistrationEvents = async (): Promise<AxiosResponse<boolean>> => (
    axios.get(`${eventsApi}/need_continue_registration`)
  );
  
  getOnlySubscribedForActualGlobalEvents = async ({ page = 1, perPage = 250}: PaginationProps): Promise<AxiosResponse> => (
    axios.get(eventsApi, {
      params: {
      	page,
		per_page: perPage,
		only_subscribed_for_actual_combine: true
      }
    })
  );
  
  getSubscribeInfoByGlobalEvent = async (globalEventId: number): Promise<AxiosResponse> => (
    axios.get(`${eventApi}/combine_event_user/combine_event_subscribe_info/${globalEventId}`)
  );
  
  unsubscribeCombineEventTrends = async (combineEventId: number, trendId: number): Promise<AxiosResponse> => (
    axios.delete(
      `${eventApi}/combine_event_user/combine_event_unsubscribe_trend/${combineEventId}`,
      { params: { trend_id: trendId }}
    )
  );
  
  unsubscribeFromGlobalEvent = async (eventId: number): Promise<AxiosResponse> => (
    axios.delete(`${eventApi}/combine_event_user/combine_event_full_delete/${eventId}`)
  );
  
  fetchMyConsultations = async ({page = 1, perPage = 250}: PaginationProps): Promise<AxiosResponse<EventCardType[]>> => (
    axios.get(eventsApi, {
      params: {
        page,
        per_page: perPage,
        event_type: 'consultation',
        only_actual: true,
        only_my: true
      }
    })
  );
  
  fetchConsultationsByTrend = async ({page = 1, perPage = 250}: PaginationProps): Promise<AxiosResponse<EventCardType[]>> => (
    axios.get(eventsApi, {
      params: {
        page,
        per_page: perPage,
        event_type: 'consultation',
        only_actual: true,
        only_by_trend_for_actual_combine_subscription: true
      }
    })
  );
}

