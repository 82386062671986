import { ResultStatusCheck } from 'common/results/enums';
import { makeAutoObservable } from 'mobx';
import { ChangeEvent } from 'react';


export class SelectStatusCheck {
  statusCheck = ResultStatusCheck.notChecked;

  constructor() {
    makeAutoObservable(this);
  }

  onChange = (e: ChangeEvent<{ value: unknown }>): void => {
    this.statusCheck = e.target.value as ResultStatusCheck;
  };
}
