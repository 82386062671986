import { DateFormats } from 'constants/dateFormats';
// eslint-disable-next-line import/no-duplicates
import { format, addDays, subDays } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ru from 'date-fns/locale/ru';


export const getISODatePlus3Hours = (date: string): string => (
  format(new Date(`${date}+03:00`), DateFormats.isoDate)
);

export const formatRu = (date: string, dateFormat: DateFormats): string => (
  format(new Date(date), dateFormat, { locale: ru })
);

export const formatDate2RuMonthNameWithTime = (date: Date): string => (
  format(date, DateFormats.dLLLSpaceHHmm, { locale: ru })
);

export const formatDate2Time = (date: Date): string => (
  format(date, DateFormats.HHmm)
);

/**
 * @param {Date} dateStart
 * @param {Date} dateEnd
 * @return {string} '3 фев. 10:00–13:59'
 */
export const formatDayOfMonthWithTimeInterval = (dateStart: Date, dateEnd: Date): string => {
  const dayOfMonthWithTime = formatDate2RuMonthNameWithTime(dateStart);
  const dayEndTimeFormatted = formatDate2Time(dateEnd);

  return `${dayOfMonthWithTime}–${dayEndTimeFormatted}`;
};

/**
 * @param {Date} date
 * @return {Date} date with added one day
 */
export const addDay = (date: Date): Date => addDays(date, 1);

/**
 * @param {Date} date
 * @return {Date} date with subtracted one day
 */
export const subtractDay = (date: Date): Date => subDays(date, 1);

/**
 * @param {Date} date
 * @return {String} date without TZ (2021-02-01T21:00:01)
 */
export const formatDateWithoutTZ = (date: Date): string => format(date, DateFormats.fullDateWithoutTZ);
