import { ChangeEvent } from 'react';
import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { AttachmentsService } from 'store/apiClients/attachments';
import { AttachmentEntityType } from 'store/apiClients/events/types';
import { AttachmentTypes } from 'store/apiClients/events/enums';


export class AttachmentsStore {
  status = Status.Initial;
  readonly service = new AttachmentsService();
  attachments: AttachmentEntityType[] = [];
  idsWithLoadStatus = new Set<number>();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * uploadAttachment(data: FormData | string): Generator {
    try {
      this.status = Status.Pending;
      const request = typeof data === 'string'
        ? this.service.createLink(data)
        : this.service.createFile(data);
      const response = (yield request) as AxiosResponse<AttachmentEntityType>;
      this.pushAttachmentFromApi(response.data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * onUploadFile(event: ChangeEvent<HTMLInputElement>): Generator {
    try {
      if (!event.target?.files?.length) return;

      const { files } = event.target;
      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);

      yield this.uploadAttachment(formData);
      // eslint-disable-next-line no-param-reassign
      event.target.value = ''; // this is needed to re-upload the same document
    } catch (e) {
      console.error(e);
    }
  }

  * fetchDeleteAttachmentById(id: number): Generator {
    try {
      this.idsWithLoadStatus.add(id);

      yield this.service.deleteById(id);
      this.deleteAttachmentFromStore(id);
    } catch (e) {
      //
    } finally {
      this.idsWithLoadStatus.delete(id);
    }
  }

  deleteAttachmentFromStore(id: number): void {
    this.attachments = this.attachments.filter((attachment) => attachment.id !== id);
  }

  clearAttachmentsInStore(): void {
    this.attachments = [];
  }

  pushAttachmentFromApi(data: AttachmentEntityType): void {
    this.attachments.push(data);
  }

  get isLoading(): boolean {
    return this.status === Status.Pending;
  }

  get images(): AttachmentEntityType[] {
    return this.attachments
      .filter(({ attachmentType }) => attachmentType === AttachmentTypes.image);
  }

  get documents(): AttachmentEntityType[] {
    return this.attachments
      .filter(({ attachmentType }) => attachmentType === AttachmentTypes.document);
  }

  get links(): AttachmentEntityType[] {
    return this.attachments
      .filter(({ attachmentType }) => attachmentType === AttachmentTypes.documentLink);
  }

  get documentsAndLinks(): AttachmentEntityType[] {
    return this.attachments
      .filter(({ attachmentType }) => (
        attachmentType === AttachmentTypes.document || attachmentType === AttachmentTypes.documentLink
      ));
  }
}
