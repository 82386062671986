import { Status } from 'constants/status';
import { makeAutoObservable } from 'mobx';
import { GlobalEventService } from 'store/apiClients/globalEvent';
import { AxiosResponse } from 'axios';
import { MetaEventType } from 'store/apiClients/globalEvent/types';
import { GetGlobalEventsParams } from './types';


export class GlobalEventsStore {
  status = Status.Initial;
  readonly service = new GlobalEventService();

  globalEvents: MetaEventType[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * fetchGlobalEvents(params: GetGlobalEventsParams = {}): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.service.getGlobalEvents(params)) as AxiosResponse<MetaEventType[]>;
      this.globalEvents = response.data;
      this.status = Status.Fulfilled;
      return response;
    } catch (error) {
      this.status = Status.Rejected;
      return error.response;
    }
  }

  get isLoading(): boolean {
    return this.status === Status.Pending;
  }
}
