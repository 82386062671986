import { FilterInput } from 'store/utilityClasses/FilterInput';


export function setFiltersToStore<T extends Record<K, FilterInput>, K extends string>(
  store: T,
  filterList: Readonly<K[]>,
  fetchData: () => void,
  resetPage: () => void,
): void {
  filterList.forEach((filterName) => {
    // eslint-disable-next-line no-param-reassign
    store[filterName] = new FilterInput(filterName, fetchData, resetPage) as T[K];
  });
}
