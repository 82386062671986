import axios, { AxiosResponse } from 'axios';
import { eventsApi } from 'constants/api';
import { EventNextType, EventNextWaiting, EventOrgNotificationType } from 'store/states/eventsNext/types';


interface EventsNextGetParams {
  maxCount?: number
}

export class EventsNextService {
  getNext = async (
    { maxCount = 10 }: EventsNextGetParams = {},
  ): Promise<AxiosResponse<EventNextType[]>> => axios.get(
    `${eventsApi}/student/next_events`, {
      params: {
        max_count: maxCount,
      },
    },
  );

  getWaiting = async (): Promise<AxiosResponse<EventNextWaiting[]>> => axios.get(
    `${eventsApi}/student/waiting_events`,
  );

  getMySkills = async (): Promise<AxiosResponse<EventNextType[]>> => axios.get(
    `${eventsApi}/student/myskills_events`,
  );

  getNotificationForOrganizer = async (): Promise<AxiosResponse<EventOrgNotificationType[]>> => axios.get(
    `${eventsApi}/count_notification_for_organizer`,
  );

  getNotificationForSchool = async (): Promise<AxiosResponse<EventOrgNotificationType[]>> => axios.get(
    `${eventsApi}/count_notification_for_school`,
  );
}
