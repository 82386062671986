import { makeAutoObservable } from 'mobx';
import { Input } from 'store/utilityClasses/Input/Input';
import { RejectUserPersonalDataParamsType } from 'store/apiClients/personalData/types';

import { ConfigAgreementDialogType } from './types';


export class AgreementDialog {
  configAgreementDialog: ConfigAgreementDialogType | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setAcceptAgreementConfig(userId: number, callback: (userId: number) => void): void {
    this.configAgreementDialog = {
      title: 'Вы уверены, что хотите принять?',
      onConfirm: () => {
        callback(userId);
        this.clearConfigAgreementDialog();
      },
    };
  }

  setRejectAgreementConfig(userId: number, callback: (params: RejectUserPersonalDataParamsType) => void): void {
    this.configAgreementDialog = {
      title: 'Вы уверены, что хотите отклонить?',
      onConfirm: () => {
        callback({ userId, rejectReason: this.rejectReason });
        this.clearConfigAgreementDialog();
      },
      rejectReason: new Input(),
    };
  }

  get rejectReason(): string | null {
    return this.configAgreementDialog?.rejectReason?.value.trim() || null;
  }

  closeAgreementDialog(): void {
    this.clearConfigAgreementDialog();
  }

  clearConfigAgreementDialog(): void {
    this.configAgreementDialog = null;
  }
}
