import { GridDirection, GridItemsAlignment, GridJustification } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';

import styles from './styles.scss';


interface Props {
  grow?: boolean,
  direction?: GridDirection,
  align?: GridItemsAlignment,
  justify?: GridJustification,
  wrap?: boolean,
  spacing?: 0 | 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5,
  className?: string,
  children?: ReactNode,
}

export const Flex = ({
  grow = false,
  direction = 'row',
  align = 'stretch',
  justify = 'flex-start',
  wrap = false,
  spacing = 1,
  className,
  children,
}: Props): ReactElement => {
  const spacingValue = String(spacing).replace('.', '_');

  return (
    <div
      className={clsx(
        {
          [styles.root]: true,
          [styles.grow]: grow,
          [styles.wrap]: wrap,
          [styles[`direction--${direction}`]]: direction !== 'row',
          [styles[`align--${align}`]]: align !== 'stretch',
          [styles[`justify--${justify}`]]: justify !== 'flex-start',
          [styles[`${direction}-spacing--${spacingValue}`]]: spacing,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
