import { makeAutoObservable } from 'mobx';
import { MetaEventCodeType } from 'store/apiClients/globalEvent/types';


export class GlobalEventTypes {
  globalEventTypes: MetaEventCodeType[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setGlobalEventTypes(data: MetaEventCodeType[]): void {
    this.globalEventTypes = data;
  }

  get globalEventIds(): number[] {
    return this.globalEventTypes.map((item) => item.id);
  }

  getGlobalEventNameById(id: number): string {
    return this.globalEventTypes.find((item) => item.id === id)?.name || '';
  }
}
