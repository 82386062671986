export enum Distribute {
  groups = 'groups',
  users = 'users',
  school = 'school',
  locations = 'locations',
}

export interface DistributeValueType {
  count: number,
  reserved: number,
}
