import axios, { AxiosResponse } from 'axios';
import { filtersApi, teamsApi } from 'constants/api';
import { CreateTeamProps, Participant, ParticipantsProps, Team, TeamsListProps } from './types';


export class TeamsService {
  getList = async (params: TeamsListProps): Promise<AxiosResponse<Team[]>> => (
    axios.get(teamsApi, { params })
  );
  
  getById = async (teamId: number): Promise<AxiosResponse<Team>> => (
    axios.get(`${teamsApi}/${teamId}`)
  );
  
  create = async (body: CreateTeamProps): Promise<AxiosResponse<number | null>> => (
    axios.post(teamsApi, body)
  );
  
  update = async (data: Team): Promise<AxiosResponse> => (
    axios.put(teamsApi, data)
  );
  
  delete = async (teamId: number): Promise<AxiosResponse> => (
    axios.delete(`${teamsApi}/${teamId}`)
  );
  
  getParticipants = async (params: ParticipantsProps): Promise<AxiosResponse<Participant[]>> => (
    axios.get(`${filtersApi}/pageSortFilterByDepartmentIdAndProfileType`, { params })
  );
}
