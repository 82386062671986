import { makeAutoObservable } from 'mobx';
import { InputNumeric } from 'store/utilityClasses/InputNumeric';
import { RegConditionByResultsValueType } from 'store/apiClients/events/types';
import { SelectCompareMark } from 'store/states/createEvent/utilityClasses/SelectCompareMark';


export class RegConditionByResultForm {
  inputEventId = new InputNumeric();
  inputRestriction = new InputNumeric();
  selectCompareMark = new SelectCompareMark();

  constructor(values: RegConditionByResultsValueType | null = null) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.setValues(values);
  }

  setValues(values: RegConditionByResultsValueType | null): void {
    if (values) {
      this.inputEventId.setValue(String(values.event_id));
      this.inputRestriction.setValue(String(values.result));
      this.selectCompareMark.setSelectCompareMark(values.compare_mark);
    }
  }

  get parsedRegConditionResultForms(): RegConditionByResultsValueType | null {
    const { inputEventId, selectCompareMark, inputRestriction, isValidForm } = this;
    if (isValidForm && selectCompareMark.selectedCompareMark) {
      return {
        result: Number(inputRestriction.value),
        event_id: Number(inputEventId.value),
        compare_mark: selectCompareMark.selectedCompareMark,
      };
    }
    return null;
  }

  get isValidForm(): boolean {
    const { inputEventId, selectCompareMark, inputRestriction } = this;
    const inputEventIdIsValid = Boolean(inputEventId.value.trim() && Number(inputEventId.value) > 0);
    const inputRestrictionIsValid = Boolean(inputRestriction.value.trim() && Number(inputRestriction.value) > 0);

    return Boolean(inputEventIdIsValid && inputRestrictionIsValid && selectCompareMark.selectedCompareMark);
  }
}
