import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { EventResult, ResultApi } from 'common/eventResult/types';
import { EventsService } from 'store/apiClients/events';
import { ResultsService } from 'store/apiClients/results';
import { ResultParams } from 'store/apiModels/results/types';
import { ResultEditApi } from 'store/states/resultEdit/types';
import { Status } from 'constants/status';
import { EventUserStatuses } from 'common/user/enum';
import { getFileNameFromResponseHeader } from 'utils/fileWorkers';
import { saveAs } from 'file-saver';
import { ChangeEvent } from 'react';
import { AttachmentsStore } from 'store/apiModels/attachments';
import { AttachmentsResponseType } from 'store/apiClients/results/types';
import { GetEventResultsParams } from 'store/apiClients/events/types';
import { canUploadResults } from './utils';
import { Pagination } from '../../utilityClasses/Pagination';


export class EventResults {
  eventResults = new Map<number, EventResult>();
  status = Status.Initial;
  lockedUserIds = new Set();
  isFastMode = false;
  pagination: Pagination;

  readonly resultsService = new ResultsService();
  readonly eventsService = new EventsService();
  readonly attachmentsStore = new AttachmentsStore();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.pagination = new Pagination();
  }

  * fetchEventResults(params: GetEventResultsParams): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.eventsService.getEventResults(params)) as AxiosResponse<EventResult[]>;
      this.eventResults.clear();
      this.pagination.setPages(response.headers);
      this.setFromApi(response.data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * fetchExportEventResults(eventId: number): Generator {
    try {
      const { data, headers } = (yield this.resultsService.exportEventResult(eventId)) as AxiosResponse;
      const fileName = getFileNameFromResponseHeader(headers, 'результаты-участников.xlsx');
      saveAs(data, fileName);
    } catch (e) {
      //
    }
  }

  * fetchExportStatement(eventId: number): Generator {
    try {
      const { data, headers } = (yield this.resultsService.exportStatement(eventId)) as AxiosResponse;
      const fileName = getFileNameFromResponseHeader(headers, 'ведомость-с-результатами.xlsx');
      saveAs(data, fileName);
    } catch (e) {
      //
    }
  }

  * fetchGetEventResultAttachments(eventId: number): Generator {
    try {
      const {
        data: { attachments },
      } = (yield this.resultsService.getEventResultAttachments(eventId)) as AxiosResponse<AttachmentsResponseType>;
      this.attachmentsStore.attachments = attachments;
    } catch (e) {
      //
    }
  }

  * fetchDeleteEventResultAttachments(eventId: number): Generator {
    try {
      yield this.resultsService.deleteEventResultAttachments(eventId);
      yield this.fetchGetEventResultAttachments(eventId);
    } catch (e) {
      //
    }
  }

  * fetchSaveEventResultAttachment(event: ChangeEvent<HTMLInputElement>, eventId: number): Generator {
    try {
      if (!canUploadResults(event)) return;
      this.attachmentsStore.clearAttachmentsInStore();
      yield this.attachmentsStore.onUploadFile(event);
      const { attachments } = this.attachmentsStore;
      yield this.resultsService.saveEventResultAttachments({ attachments, eventId });
    } catch (e) {
      //
    }
  }

  onUploadResults(event: ChangeEvent<HTMLInputElement>, eventId: number): void {
    if (!event.target.files?.length) return;

    const file = event.target.files[0];
    const formData = new FormData();
    formData.set('file', file);
    this.fetchUploadResults(formData, eventId);
  }

  * fetchUploadResults(formData: FormData, eventId: number): Generator {
    try {
      yield this.resultsService.importEventResults(formData, eventId);
    } catch (e) {
      //
    }
  }

  * saveResultAbsent({ eventId, userId }: ResultParams): Generator {
    try {
      this.lockedUserIds.add(userId);
      const { status } = (yield this.eventsService.absent(eventId, userId)) as AxiosResponse;
      this.updateAbsentUser(status, userId);
      this.lockedUserIds.delete(userId);
    } catch (e) {
      this.lockedUserIds.delete(userId);
    }
  }

  * saveFastResult(params: ResultEditApi): Generator {
    try {
      this.lockedUserIds.add(params.userId);
      const { status, data } = (yield this.resultsService.saveFastResult(params)) as AxiosResponse<ResultApi>;
      this.updateUserResult(status, params.userId, data);
      this.lockedUserIds.delete(params.userId);
    } catch (e) {
      this.lockedUserIds.delete(params.userId);
    }
  }

  updateAbsentUser(status: number, userId: number): void {
    if (status !== 200) return;

    this.eventResults.forEach((eventResult) => {
      if (eventResult.user.id === userId) {
        eventResult.studentStatus = EventUserStatuses.absent; // eslint-disable-line no-param-reassign
      }
    });
  }

  updateUserResult(status: number, userId: number, data: ResultApi): void {
    if (status !== 200) return;

    this.eventResults.forEach((eventResult) => {
      if (eventResult.user.id === userId) {
        eventResult.studentStatus = EventUserStatuses.results; // eslint-disable-line no-param-reassign
        eventResult.result = { ...eventResult.result, ...data }; // eslint-disable-line no-param-reassign
      }
    });
  }

  toggleFastMode(): void { this.isFastMode = !this.isFastMode; }

  get isLoading(): boolean {
    return this.status === Status.Pending;
  }

  get listEventResultsIds(): number[] {
    return [...this.eventResults.keys()];
  }

  getEventResultById(id: number): EventResult | null {
    return this.eventResults.get(id) || null;
  }

  setFromApi(dataApi: EventResult[]): void {
    dataApi.forEach((eventResult) => this.eventResults.set(eventResult.id, eventResult));
  }
}
