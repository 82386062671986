import axios, { AxiosResponse } from 'axios';
import { landingApi } from 'constants/api';
import { LandingBlock } from './types';


export class LandingService {
  getAll = async (keys: string[] | null = null): Promise<AxiosResponse<LandingBlock[]>> => (
    axios.get(landingApi, { params: { keys } })
  );
  postAll = async (blocks: LandingBlock[]): Promise<AxiosResponse> => (
    axios.post(`${landingApi}/upsert_all`, blocks)
  );
}
