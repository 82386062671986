import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import debounce from 'lodash-es/debounce';
import { globalStore } from 'store/contexts';
import { Status } from 'constants/status';
import {
  UsersAgreementList,
  RejectUserPersonalDataParamsType,
  CertificateTypes,
  CertificateAccessStatus,
} from 'store/apiClients/personalData/types';
import { PersonalDataService } from 'store/apiClients/personalData';
import { FilterInput } from 'store/utilityClasses/FilterInput';
import { Pagination } from 'store/utilityClasses/Pagination';
import { setFiltersToStore } from 'utils/filter';
import { AvailablePersonalDataFilters } from 'store/states/personalData/enums';
import { updateSearchParamInURL } from 'utils/searchParams/utils';
import { AgreementDialog } from './utilityClasses/AgreementDialog';


export class PersonalDataState {
  readonly academicYearStore = globalStore.academicYearStore;
  status = Status.Initial;

  academicYear = new FilterInput(AvailablePersonalDataFilters.academicYear);
  fioOrEmail = new FilterInput(AvailablePersonalDataFilters.fioOrEmail);
  departmentName = new FilterInput(AvailablePersonalDataFilters.departmentName);
  cityRegion = new FilterInput(AvailablePersonalDataFilters.cityRegion);
  schoolOperator = new FilterInput(AvailablePersonalDataFilters.schoolOperator);
  certificateType = new FilterInput<CertificateTypes>(AvailablePersonalDataFilters.certificateType);
  certificateAccessStatus = new FilterInput<CertificateAccessStatus>(
    AvailablePersonalDataFilters.certificateAccessStatus,
  );
  metaEventId = new FilterInput(AvailablePersonalDataFilters.metaEventId); // it doesn`t has ui input actually

  usersAgreementData: UsersAgreementList = [];

  service = new PersonalDataService();
  pagination = new Pagination();
  loadingIds = new Set<number>();

  agreementDialog = new AgreementDialog();
  userIdForModalCertificate: null | number = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    setFiltersToStore<this, AvailablePersonalDataFilters>(
      this,
      Object.values(AvailablePersonalDataFilters),
      this.fetchUsersAgreementDataDebounced,
      this.pagination.resetPage,
    );
    this.init();
  }

  init(): void {
    this.academicYearStore.fetchYears();
  }

  * fetchUsersAgreementData(): Generator {
    try {
      this.status = Status.Pending;
      const params = {
        page: this.pagination.page,
        fioOrEmail: this.fioOrEmail.value,
        departmentName: this.departmentName.value,
        cityRegion: this.cityRegion.value,
        schoolOperator: this.schoolOperator.value,
        certificateAccessStatus: this.certificateAccessStatus.selectedValue,
        certificateType: this.certificateType.selectedValue,
        metaEventId: this.metaEventId.value,
        schoolYear: this.academicYear.selectedNumberValue,
      };
      const response = (yield this.service.getUsersAgreementData(params)) as AxiosResponse<UsersAgreementList>;
      this.pagination.setPages(response.headers);
      this.status = Status.Fulfilled;
      this.usersAgreementData = response.data;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  fetchUsersAgreementDataDebounced = debounce((): void => {
    this.fetchUsersAgreementData();
  }, 800);

  * acceptUserPersonalData(userId: number): Generator {
    try {
      this.loadingIds.add(userId);
      yield this.service.acceptUserPersonalData({ userId });
      yield this.fetchUsersAgreementData();
    } catch (e) {
      //
    } finally {
      this.loadingIds.delete(userId);
    }
  }

  * rejectUserPersonalData(params: RejectUserPersonalDataParamsType): Generator {
    const { userId } = params;

    try {
      this.loadingIds.add(userId);
      yield this.service.rejectUserPersonalData(params);
      yield this.fetchUsersAgreementData();
    } catch (e) {
      //
    } finally {
      this.loadingIds.delete(userId);
    }
  }

  resetFilters(): void {
    this.resetAcademicYearFilter();
    this.fioOrEmail.clearFilter();
    this.departmentName.clearFilter();
    this.cityRegion.clearFilter();
    this.schoolOperator.clearFilter();
    this.certificateAccessStatus.clearFilter();
    this.certificateType.clearFilter();
    this.metaEventId.clearFilter();
    this.pagination.resetPage();
    this.fetchUsersAgreementData();
  }

  get isLoading(): boolean {
    return this.status === Status.Pending;
  }

  setUserIdForModalCertificate(id: number | null): void {
    this.userIdForModalCertificate = id;
  }

  resetAcademicYearFilter(): void {
    const { defaultYear } = this.academicYearStore;
    if (defaultYear) {
      this.academicYear.value = String(defaultYear);
      updateSearchParamInURL(AvailablePersonalDataFilters.academicYear, defaultYear);
    } else {
      this.academicYear.clearFilter();
    }
  }
}
