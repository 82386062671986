import axios, { AxiosResponse } from 'axios';
import { globalEventsApi } from 'constants/api';
import { CombineEventsProps, CreateCombineEventDto, UpdateCombineEventDto } from './types';
import { GlobalEventCardType } from 'store/apiModels/events/types';

export class CombineEventsService {
  getAll = async ({page, per_page = 50}: CombineEventsProps): Promise<AxiosResponse<GlobalEventCardType[]>> => (
    axios.get(globalEventsApi, {
      params: {
        page,
		per_page
	  }
    })
  );
  
  getOne = async (id: number): Promise<AxiosResponse<GlobalEventCardType>> => (
    axios.get(`${globalEventsApi}/${id}`)
  );
  
  create = async (data: CreateCombineEventDto): Promise<AxiosResponse<number | null>> => (
    axios.post(globalEventsApi, data)
  );
  
  update = async (data: UpdateCombineEventDto): Promise<AxiosResponse> => (
    axios.put(globalEventsApi, data)
  );
  
  delete = async (id: number): Promise<AxiosResponse> => (
	axios.delete(`${globalEventsApi}/${id}`)
  );
}
