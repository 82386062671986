import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';

import {
  EventsAcceptParams,
  EventsGetParams,
  EventsService,
  GlobalEventsParams,
  PaginationProps,
  SubscribeGlobalEventParams
} from 'store/apiClients/events';
import { Status } from 'constants/status';
import { CreateEventDataApiType, } from 'store/apiClients/events/types';
import {
  EventCardCollectionType,
  EventCardType,
  SchoolEventResult,
  GlobalEventCardType,
  CategorySchoolEventResult
} from './types';
import { MultiSelect } from '../../utilityClasses/Select/MultiSelect';
import { SingleSelect } from '../../utilityClasses/Select/SingleSelect';
import { FilterInput } from '../../utilityClasses/FilterInput';


export class EventsStore {
  status = Status.Initial;
  globalEvent: GlobalEventCardType | null = null;
  globalEvents: GlobalEventCardType[] = [];
  globalEventsInPairs: Array<GlobalEventCardType[]> = [];
  events: EventCardCollectionType = [];
  event: EventCardType | null = null;
  subscription = {
    status: Status.Initial,
    id: 0,
  };
  eventsService = new EventsService();
  needContinueRegistration = false;
  groupedNeedRegistrationEvents: { [key: string]: EventCardType[] } = {};
  needRegistrationEvents: EventCardType[] = [];
  subscribedGlobalEvent: SubscribeGlobalEventParams | null = null;
  consultations: EventCardType[] = [];
  consultationsByTrend: EventCardType[] = [];
  schools: SchoolEventResult[] = [];
  schoolsByCategories: CategorySchoolEventResult[] = [];
  
  // контролы для модалки регистрации на глоб событие
  selectTrend = new MultiSelect();
  selectLocation = new SingleSelect();
  selectOrganizator = new SingleSelect();
  selectTimeslot = new FilterInput('');

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * fetchEvents(params: EventsGetParams = {}): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.eventsService.get(params)) as AxiosResponse<EventCardCollectionType>;
      this.status = Status.Fulfilled;
      this.events = response.data;
      return response;
    } catch (error) {
      this.status = Status.Rejected;
      return error.response;
    }
  }

  * fetchEvent(id: number): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.eventsService.getById(id)) as AxiosResponse<EventCardType>;
      this.status = Status.Fulfilled;
      this.event = response.data;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }

  * fetchDeleteById(id: number, callback: () => void): Generator {
    try {
      yield this.eventsService.deleteById(id);
      callback();
    } catch (e) {
      //
    }
  }

  * createEvent(data: CreateEventDataApiType, isUpdatable?: boolean): Generator<unknown, number | null> {
    try {
      this.status = Status.Pending;
      const request = isUpdatable ? this.eventsService.update(data) : this.eventsService.create(data);
      const response = (yield request) as AxiosResponse<number>;
      if (response.status === 200) {
        const eventId = isUpdatable && data.id ? data.id : response.data;
        this.status = Status.Fulfilled;
        return eventId;
      }
      return null;
    } catch (e) {
      this.status = Status.Rejected;
      return null;
    }
  }

  * setEvent(id: number, fromApi = true): Generator {
    const event = this.events.find((item) => item.id === id);

    if (event) {
      this.event = event;
    }
    if (fromApi) {
      yield this.fetchEvent(id);
    }
  }

  * fetchSetDistributedById(eventId: number): Generator<unknown, AxiosResponse | null> {
    try {
      return (yield this.eventsService.setDistributed(eventId)) as AxiosResponse;
    } catch (e) {
      return null;
    }
  }

  * acceptEvent(params: EventsAcceptParams): Generator<unknown, AxiosResponse | null> {
    try {
      return (yield this.eventsService.accept(params)) as AxiosResponse;
    } catch (e) {
      return null;
    }
  }

  * cancelEvent(id: number): Generator<unknown, AxiosResponse | null> {
    try {
      return (yield this.eventsService.cancel(id)) as AxiosResponse;
    } catch (e) {
      return null;
    }
  }

  * fetchSchools(): Generator {
    try {
      this.status = Status.Pending;
      const schoolsResponse = (yield this.eventsService.getStatisticTopSchools()) as AxiosResponse<SchoolEventResult[]>;
      const schoolsByCategoriesResponse = (yield this.eventsService.getStatisticTopSchoolsByCategories()) as AxiosResponse<CategorySchoolEventResult[]>;

      this.setSchools(schoolsResponse.data, schoolsByCategoriesResponse.data);
      this.status = Status.Fulfilled;
      return [schoolsResponse.data, schoolsByCategoriesResponse.data];
    } catch (error) {
      this.status = Status.Rejected;
      return error.response;
    }
  }

  setSchools(financeResults: SchoolEventResult[], rightAndQuickResults: CategorySchoolEventResult[]): void {
    this.schools = financeResults;
    this.schoolsByCategories = rightAndQuickResults;
  }

  get schoolsArray(): SchoolEventResult[] {
    return this.schools;
  }

  get schoolsByCategoriesArray(): CategorySchoolEventResult[] {
    return this.schoolsByCategories;
  }

  getEventById(id: number): EventCardType | null {
    return this.events.find((event) => event.id === id) || null;
  }

  * subscribe(eventId: number, locationId: number | null, isSubscribe: boolean, timeslotId: number | null): Generator {
    if (this.subscription.id === eventId) return;

    try {
      this.subscription.id = eventId;
      this.subscription.status = Status.Pending;
      const response = (
        yield isSubscribe
          ? this.eventsService.subscribe(eventId, locationId, timeslotId)
          : this.eventsService.unsubscribe(eventId)
      ) as AxiosResponse<EventCardType>;
      this.subscription.status = Status.Fulfilled;

      if (response.status === 200) {
        yield this.updateEventAfterSubscribe(eventId);
      }
      this.subscription.id = 0;
    } catch (error) {
      this.subscription.status = Status.Rejected;
      this.subscription.id = 0;
    }
  }

  * updateEventAfterSubscribe(id: number): Generator {
    yield this.fetchEvent(id);
    this.updateEvents(id);
  }

  updateEvents(id: number): void {
    const eventIndex = this.events.findIndex((item) => item.id === id);
    if (this.event && eventIndex !== -1) {
      this.events[eventIndex] = this.event;
    }
  }

  get isEventLoaded(): boolean {
    const { event, status } = this;
    return status === Status.Fulfilled && Boolean(event);
  }

  get isLoading(): boolean {
    return this.status === Status.Pending;
  }

  resetEvents(): void {
    this.events = [];
  }
  
  getGlobalEventsInPairs(events: GlobalEventCardType[]): Array<GlobalEventCardType[]> {
    const result: Array<GlobalEventCardType[]> = [];
    let groupedEvents: GlobalEventCardType[] = [];
    
    events.forEach((event: GlobalEventCardType, idx: number) => {
      groupedEvents.push(event);
      
      if (groupedEvents.length === 2 || idx === events.length - 1) {
        result.push(groupedEvents);
        groupedEvents = [];
      }
    });
    
    return result;
  }
  
  * fetchSubscribeToEvent(eventId: number, locationId: number | null, teamId: number): Generator {
    try {
      this.subscription.id = eventId;
      this.status = Status.Pending;
      
      const response = yield this.eventsService.subscribeToTeamEvent(eventId, locationId, teamId) as AxiosResponse;
      this.status = Status.Fulfilled;
      
      if (response.status === 200) {
        yield this.updateEventAfterSubscribe(eventId);
      }
      
      this.subscription.id = 0;
    } catch (err) {
      this.status = Status.Rejected;
      this.subscription.id = 0;
      console.error(err);
    }
  }
  
  * fetchUnsubscribeFromEvent(eventId: number): Generator {
    try {
      this.status = Status.Pending;
      
      const response = yield this.eventsService.unsubscribe(eventId) as AxiosResponse;
      this.status = Status.Fulfilled;

      if (response.status === 200) {
        yield this.updateEventAfterSubscribe(eventId)
      }
    } catch (err) {
      this.status = Status.Rejected;
      console.error(err);
    }
  }
  
  * fetchGlobalEvents(params: GlobalEventsParams): Generator {
    try {
      this.status = Status.Pending;
      const response = yield this.eventsService.getGlobalEvents(params) as AxiosResponse<GlobalEventCardType[]>;
      this.globalEvents = response.data;
      this.globalEventsInPairs = this.getGlobalEventsInPairs(this.globalEvents);
	  this.status = Status.Fulfilled;

      return response;
    } catch (err) {
      this.status = Status.Rejected;
      return err.response
    }
  }
  
  * fetchSubscribeInfoByGlobalEvent(globalEventId: number): Generator {
    try {
	  this.status = Status.Pending;
	  const response = yield this.eventsService.getSubscribeInfoByGlobalEvent(globalEventId) as AxiosResponse;
	  this.subscribedGlobalEvent = response.data;
	  this.selectTrend.setValue(this.subscribedGlobalEvent?.trendIds);
	  
	  this.status = Status.Fulfilled;
    } catch (err) {
	  this.status = Status.Rejected;
      console.error(err);
    }
  }
  
  * subscribeOnGlobalEvent(params: SubscribeGlobalEventParams): Generator {
    try {
      this.status = Status.Pending;
      const response = yield this.eventsService.subscribeToGlobalEvent(params) as AxiosResponse;
      
      this.status = Status.Fulfilled;
      return response;
    } catch (err) {
      this.status = Status.Rejected;
      return err.response
    }
  }
  
  setCurrentGlobalEvent(id: number): void {
    this.globalEvent = this.globalEvents.find((event: GlobalEventCardType) => event.id === id) || null;
  }
  
  * subscribeToEventOnGlobalEvent(eventId: number): Generator {
	if (this.subscription.id === eventId) return;
	
	try {
	  this.subscription.id = eventId;
	  this.subscription.status = Status.Pending;
	  
	  const locationId = this.selectLocation.selectId || null;
	  const timeslotId = this.selectTimeslot.selectedNumberValue || null;
	  yield this.eventsService.subscribe(eventId, locationId, timeslotId) as AxiosResponse<EventCardType>;
      
      this.selectLocation.reset();
      this.selectTimeslot.reset();
      this.selectOrganizator.reset();
      
      this.subscription.status = Status.Fulfilled;
      this.subscription.id = 0;
	} catch (err) {
	  this.subscription.status = Status.Rejected;
	  this.subscription.id = 0;
	  return err.response;
	}
  }
  
  * changeSubscriptionParameters(
    eventId: number | string,
    locationId: number | null,
    timeslotId: number | null
  ): Generator {
    try {
      this.status = Status.Pending;
      yield this.eventsService.changeSubscriptionParameters(eventId, locationId, timeslotId);
      this.status = Status.Fulfilled;
    } catch (err) {
      this.status = Status.Rejected;
      console.error(err);
    }
  }
  
  * checkNeedContinueRegistrationEvents(): Generator {
    try {
      this.status = Status.Pending;
      const response = yield this.eventsService.checkNeedContinueRegistrationEvents() as AxiosResponse<boolean>;
      this.needContinueRegistration = response.data;
      this.status = Status.Fulfilled;
    } catch (err) {
      this.status = Status.Rejected;
      return err.response
    }
  }
  
  * fetchMyEvents(params: PaginationProps = {}): Generator {
    try {
      this.status = Status.Pending;
      const response = yield this.eventsService.getOnlySubscribedForActualGlobalEvents(params) as AxiosResponse;
      this.status = Status.Fulfilled;
      this.events = response.data;
      return response;
    } catch (err) {
      this.status = Status.Rejected;
      return err.response;
    }
  }

  * fetchNeedRegistrationEvents(): Generator {
    try {
      this.status = Status.Pending;
      const response = yield this.eventsService.getEventsWithoutRegistrationForGlobalEvent() as AxiosResponse;
      this.needRegistrationEvents = response.data;
      this.groupedNeedRegistrationEvents = response.data.length
		? (response.data as EventCardType[]).reduce((acc: any, event) => {
			const key = `${event.eventType}_${event.trends[0].id}`;
			if (acc[key]) {
			  acc[key].push(event);
            } else {
			  acc[key] = [event];
            }
			return acc;
		  }, {})
		: {};
	  this.status = Status.Fulfilled;
    } catch (err) {
      this.status = Status.Rejected;
      return err.response;
    }
  }
  
  * fetchUnsubscribeFromGlobalEvent(eventId: number): Generator {
    try {
      this.status = Status.Pending;
    
      const response = yield this.eventsService.unsubscribeFromGlobalEvent(eventId) as AxiosResponse;
      this.status = Status.Fulfilled;
    
      if (response.status === 200) {
        this.subscribedGlobalEvent = null;
        
        yield this.fetchGlobalEvents({ onlyActual: true });
        yield this.fetchMyEvents();
      }
    } catch (err) {
      this.status = Status.Rejected;
      console.error(err);
    }
  }
  
  * fetchMyConsultations(params: PaginationProps = {}): Generator {
    try {
      this.status = Status.Pending;
      const response = yield this.eventsService.fetchMyConsultations(params) as AxiosResponse<EventCardType[]>;
      this.consultations = response.data;
      this.status = Status.Fulfilled;
      return response;
    } catch (err) {
      this.status = Status.Rejected;
      console.error(err);
    }
  }
  
  * fetchConsultationsByTrend(params: PaginationProps = {}): Generator {
    try {
      this.status = Status.Pending;
      const response = yield this.eventsService.fetchConsultationsByTrend(params) as AxiosResponse<EventCardType[]>;
      this.consultationsByTrend = response.data;
      this.status = Status.Fulfilled;
      return response;
    } catch (err) {
      this.status = Status.Rejected;
      console.error(err);
    }
  }
  
  resetGlobalEventRegControls(): void {
    this.selectLocation.reset();
    this.selectOrganizator.reset();
    this.selectTimeslot.reset();
    this.selectTrend.reset();
  }
}
