import axios, { AxiosResponse } from 'axios';
import { certificatesApi, userApi } from 'constants/api';
import { PersonalData } from 'store/states/loadCertificate/types';

import {
  UsersAgreementList,
  GetUsersAgreementDataParams,
  AcceptUserPersonalDataParamsType,
  RejectUserPersonalDataParamsType,
} from './types';


export class PersonalDataService {
  get = async (userId: number): Promise<AxiosResponse<PersonalData>> => (
    axios.get(`${userApi}/getUserPredprofPersonalData`, { params: { userId } })
  );

  save = async (data: PersonalData): Promise<AxiosResponse<PersonalData>> => (
    axios.post(`${userApi}/savePredprofPersonalData`, data)
  );

  getUsersAgreementData = async ({
    page = 1, perPage = 100, fioOrEmail, departmentName, cityRegion, schoolOperator,
    certificateType, certificateAccessStatus, metaEventId, schoolYear,
  }: GetUsersAgreementDataParams = {}): Promise<AxiosResponse<UsersAgreementList>> => (
    axios.get(`${certificatesApi}/predprof`, {
      params: {
        page,
        perPage,
        fioOrEmail: fioOrEmail || null,
        cityRegion: cityRegion || null,
        metaEventId: metaEventId || null,
        departmentName: departmentName || null,
        schoolOperator: schoolOperator || null,
        certificateType: certificateType?.toUpperCase() || null,
        certificateAccessStatus: certificateAccessStatus?.toUpperCase() || null,
        school_year: schoolYear,
      },
    })
  );

  acceptUserPersonalData = async ({ userId }: AcceptUserPersonalDataParamsType): Promise<AxiosResponse> => (
    axios.put(`${userApi}/acceptPredprofPersonalData`, null, { params: { userId } })
  );

  rejectUserPersonalData = async ({
    userId, rejectReason = null,
  }: RejectUserPersonalDataParamsType): Promise<AxiosResponse> => (
    axios.put(`${userApi}/rejectPredprofPersonalData`, null, {
      params: {
        userId,
        ...rejectReason && { comment: rejectReason },
      },
    })
  );
}
