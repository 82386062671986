import React, { ReactElement, ReactNode } from 'react';
import ruLocale from 'date-fns/locale/ru';
import DateFnsUtils from '@date-io/date-fns';
import ThemeProvider from 'materialUI/ThemeProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';


interface Props {
  children: ReactNode,
}

export const AppProviders = ({ children }: Props): ReactElement => (
  <ThemeProvider>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      {children}
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);
