import axios, { AxiosResponse } from 'axios';
import { ID_COUNTRY_RUSSIA } from 'common/constants';


export type CityModel = {
  area: string
  id: number
  important: boolean
  region: string
  title: string
};

export type CityResponseApi = {
  count: number
  items: CityModel[]
};

export interface GetCityParams {
  regionId?: number
  q?: string
}

export class CitiesService {
  get = async ({ regionId, q }: GetCityParams = {}): Promise<AxiosResponse<CityResponseApi>> => (
    axios.get('/proxy/vk_proxy/cities', {
      params: {
        limit: 100,
        country_id: ID_COUNTRY_RUSSIA,
        region_id: regionId,
        offset: 0,
        q,
      },
    })
  );
}
