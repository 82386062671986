import { ThemeProvider as MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import React, { ReactElement, ReactNode } from 'react';
import './styles.scss';


interface IProps {
    children: ReactNode;
}

const ThemeProvider = ({ children }: IProps): ReactElement => (
    <StylesProvider injectFirst>
        <MuiThemeProvider theme={createMuiTheme()}>{children}</MuiThemeProvider>
    </StylesProvider>
);

export default ThemeProvider;
