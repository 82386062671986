import axios, { AxiosResponse } from 'axios';
import { tagsApi } from 'constants/api';
import { TagsApiCollection } from './types';


export class TagsService {
  getAll = async (): Promise<AxiosResponse<TagsApiCollection>> => (
    axios.get(`${tagsApi}/all`)
  );
  create = async (name: string): Promise<AxiosResponse<number>> => (
    axios.post(tagsApi, { name })
  );
}
