import { AxiosResponse } from 'axios';
import { DateFormats } from 'constants/dateFormats';
import { Status } from 'constants/status';
import { format, differenceInYears } from 'date-fns';
import { makeAutoObservable } from 'mobx';
import { ChangeEvent } from 'react';
import { PersonalDataService } from 'store/apiClients/personalData';
import { AttachmentsStore } from 'store/apiModels/attachments';
import { globalStore } from 'store/contexts';
import { Input } from 'store/utilityClasses/Input/Input';
import { SingleSelect } from 'store/utilityClasses/Select/SingleSelect';
import { PersonalData } from './types';


export class LoadCertificate {
  status = Status.Initial;

  birthDate: null | Date = null;
  name = new Input();
  middleName = new Input();
  lastName = new Input();
  vkRegionId = new SingleSelect();
  organizationName = new Input();
  organizationAddress = new Input();

  readonly regionsStore = globalStore.regionsStore;
  readonly attachmentsStore = new AttachmentsStore();
  readonly service = new PersonalDataService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * fetchPersonalData(userId: number): Generator {
    try {
      this.status = Status.Pending;
      const { data } = (yield this.service.get(userId)) as AxiosResponse<PersonalData>;
      this.spreadPersonalData(data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * postPersonalData(userId: number, callback: () => void): Generator {
    const data = this.collectPersonalData(userId);
    try {
      this.status = Status.Pending;
      yield this.service.save(data);
      callback();
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * fetchUploadForm(event: ChangeEvent<HTMLInputElement>): Generator {
    try {
      this.attachmentsStore.clearAttachmentsInStore();
      yield this.attachmentsStore.onUploadFile(event);
    } catch (e) {
      //
    }
  }

  collectPersonalData(userId: number): PersonalData {
    const vkRegionName = this.regionsStore.getNameRegionById(this.vkRegionId.selectId);

    return {
      user: { id: userId },
      birthDate: this.birthDate ? format(this.birthDate, DateFormats.isoDate) : undefined,
      name: this.name.value,
      middleName: this.middleName.value,
      lastName: this.lastName.value,
      vkRegionId: this.vkRegionId.selectId || undefined,
      vkRegionName,
      organizationName: this.organizationName.value,
      organizationAddress: this.organizationAddress.value,
      attachment: this.attachmentsStore.attachments[0],
    };
  }

  spreadPersonalData(data: PersonalData): void {
    const {
      birthDate, name, middleName, lastName, vkRegionId, organizationName, organizationAddress, attachment,
    } = data;
    this.name.setValue(name);
    this.middleName.setValue(middleName);
    this.lastName.setValue(lastName);
    this.organizationName.setValue(organizationName || '');
    this.organizationAddress.setValue(organizationAddress || '');
    this.vkRegionId.setId(vkRegionId || null);
    if (birthDate) this.setBirthDate(new Date(birthDate));
    if (attachment) this.attachmentsStore.pushAttachmentFromApi(attachment);
  }

  setBirthDate(birthDate: Date): void {
    this.birthDate = birthDate;
  }

  resetBirthDate(): void {
    this.birthDate = null;
  }

  get fullYears(): number {
    return this.birthDate ? differenceInYears(new Date('2022-05-31T00:00:00'), this.birthDate) : 0;
  }

  get isAdult(): boolean {
    return this.fullYears >= 18;
  }

  get isNotFilledFields(): boolean {
    return !(this.birthDate
      && this.name.value.trim()
      && this.middleName.value.trim()
      && this.lastName.value.trim()
      && this.vkRegionId.selectId
      && this.organizationName.value.trim()
      && this.organizationAddress.value.trim()
      && this.attachmentsStore.attachments.length);
  }

  get isAttachmentsUploaded(): boolean {
    return Boolean(this.attachmentsStore.attachments.length);
  }

  get isLoading(): boolean { return this.status === Status.Pending || this.attachmentsStore.isLoading; }
}
