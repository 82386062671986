import { ChangeEvent } from 'react';
import { makeAutoObservable } from 'mobx';
import { REGEX_RULES } from 'constants/regexRules';


export class InputNumeric<T extends ChangeEvent<HTMLInputElement>> {
  value = '';

  constructor(defaultValue = '') {
    makeAutoObservable(this);
    this.value = defaultValue;
  }

  setValue = (value: string): void => {
    this.value = value;
  };

  onChange = (e: T, regexRule = REGEX_RULES.nonNumeric): void => {
    this.value = e.target.value.replace(regexRule, '');
  };

  onChangeNumberLimit = (e: T, maxValue: number | null): void => {
    const numericValue = Number(e.target.value.replace(REGEX_RULES.nonNumeric, ''));
    this.value = String(Math.min(maxValue || numericValue, numericValue));
  };

  onChangeFloat = (e: T): void => {
    const inputValue = e.target.value.trim();
    const numericValue = Number(inputValue);

    if (numericValue >= 0 || inputValue === '') {
      this.value = inputValue;
    }
  };
  
  onChangeFractionalNumberLimit = (e: T, maxValue: number | null, fractionDigits: number = 2): void => {
    const inputValue: string = e.target.value.trim();
    const numericValue = Number(inputValue);

    if (numericValue >= 0 || inputValue === '') {
      if (!inputValue.match(REGEX_RULES.fractionalNumeric)) {
        this.value = String(Math.min(maxValue || numericValue, numericValue))
      } else {
        const inputValueParts = inputValue.split('.');
        
        if (inputValueParts[1].length > fractionDigits) {
          return;
        }
        
        this.value = Boolean(inputValueParts[1])
          ? numericValue.toFixed(inputValueParts[1].length).toString()
          : inputValue;
      }
    }
  };

  clear = (): void => {
    this.value = '';
  };

  get numericValue(): number | null {
    const numericValue = Number(this.value);
    return Number.isNaN(numericValue) ? null : numericValue;
  }
}
