import { makeAutoObservable } from 'mobx';
import { getInitialParam, updateSearchParamInURL } from 'utils/searchParams/utils';


export class Pagination {
  page = getInitialParam('page') || 1;
  pages = 1;
  fetchData: () => void;

  constructor(fetchData?: () => void) {
    this.fetchData = fetchData || (() => {});
    makeAutoObservable(this, { fetchData: false }, { autoBind: true });
  }

  setPage(event: unknown, page: number): void {
    if (page <= this.pages) {
      this.page = page;
      updateSearchParamInURL('page', page === 1 ? '' : page);
      window.scrollTo({ top: 0 });
      this.fetchData();
    }
  }

  setPages(headers: Record<'pages', string>): void {
    const pages = Number(headers.pages);
    if (Number.isNaN(pages)) return;
    this.pages = pages;
  }

  resetPage(): void {
    this.page = 1;
    updateSearchParamInURL('page', '');
    window.scrollTo({ top: 0 });
  }

  get isDisplayPagination(): boolean {
    return this.pages > 1;
  }
}
