import { makeAutoObservable } from 'mobx';


export class Switch {
  checked = false;

  constructor(defaultValue = false) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.checked = defaultValue;
  }

  toggleSwitch(): void {
    this.checked = !this.checked;
  }

  setChecked(isChecked: boolean): void {
    this.checked = isChecked;
  }
}
