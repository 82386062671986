export interface FooterListItem {
  caption: string;
  href: string;
}

export const footer = {
  mail: 'help@mcko.ru',
  phone: '8 (499) 444-57-21',
  copyright: '© МЦКО. Все права защищены.',
  restrictions: '0+',
  blockAgreements: {
    title: 'Соглашения',
    list: [
      {
        caption: 'Пользовательское соглашение',
        href: '',
      },
      {
        caption: 'Обработка персональных данных',
        href: '',
      },
      {
        caption: 'Предупреждение об использовании cookie',
        href: '',
      },
    ],
  },
  blockResources: {
    title: 'Дополнительные ресурсы',
    list: [
      {
        caption: 'Департамент образования и науки города Москвы',
        href: 'https://www.mos.ru/donm/',
      },
      {
        caption: 'Московский центр качества образования',
        href: 'https://mcko.ru/',
      },
      {
        caption: 'Предпрофессиональное образование',
        href: 'http://profil.mos.ru/',
      },
    ],
  },
};
