import { makeAutoObservable } from 'mobx';
import { Pagination } from 'store/utilityClasses/Pagination';
import { Status } from 'constants/status';
import { AxiosResponse } from 'axios';
import { LocationApi, UpdateLocationApi } from 'store/apiClients/locations/types';
import { LocationsService } from 'store/apiClients/locations';
import { FilterInput } from 'store/utilityClasses/FilterInput';
import debounce from 'lodash-es/debounce';
import { DepartmentTypesId } from 'common/departmens/enums';
import { DepartmentTypeSelect } from 'store/utilityClasses/DepartmentTypeSelect';
import { deleteSearchParamInURL, getInitialParam, setSearchParamsToURL } from 'utils/searchParams/utils';
import { AvailableLocationsFilters } from './enums';


export class DirectoriesState {
  status = Status.Initial;

  pagination = new Pagination();

  locations: LocationApi[] = [];

  locationAddress = new FilterInput(AvailableLocationsFilters.locationAddress);
  departmentId: number | null = getInitialParam(AvailableLocationsFilters.orgId);

  departmentTypeSelect = new DepartmentTypeSelect();

  readonly service = new LocationsService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.locationAddress = new FilterInput(
      AvailableLocationsFilters.locationAddress, this.fetchLocationsDebounced, this.pagination.resetPage,
    );
    this.departmentTypeSelect.setSelectId(DepartmentTypesId.jurisdiction);
  }

  * fetchLocations(): Generator {
    try {
      this.status = Status.Pending;
      const params = {
        page: this.pagination.page,
        locationAddress: this.locationAddress.value,
        orgId: this.departmentId,
      };

      const { headers, data } = (yield this.service.get(params)) as AxiosResponse<LocationApi[]>;
      this.pagination.setPages(headers);
      this.locations = data;
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  fetchLocationsDebounced = debounce((): void => {
    this.fetchLocations();
  }, 800);

  * fetchDeleteLocation(id: number): Generator {
    try {
      yield this.service.delete(id) as Promise<AxiosResponse>;
      this.deleteLocationApi(id);
    } catch (e) {
      //
    }
  }

  * fetchUpdateLocation(data: UpdateLocationApi): Generator {
    try {
      (yield this.service.update(data)) as AxiosResponse;
      this.updateLocations(data);
    } catch (e) {
      //
    }
  }

  setDepartmentTypeField(id: DepartmentTypesId): void {
    this.departmentId = null;
    this.departmentTypeSelect.setSelectId(id);
  }

  setDepartmentFilter(id: number | null): void {
    this.departmentId = id;
    id ? setSearchParamsToURL({ orgId: String(id) })
      : deleteSearchParamInURL(AvailableLocationsFilters.orgId);
    this.pagination.setPage(null, 1);
    this.fetchLocationsDebounced();
  }

  updateLocations(data: UpdateLocationApi): void {
    this.locations.forEach((location) => {
      if (location.id === data.id) {
        location.locationName = data.locationName; // eslint-disable-line no-param-reassign
        location.locationAddress = data.locationAddress; // eslint-disable-line no-param-reassign
      }
    });
  }

  deleteLocationApi(id: number): void {
    this.locations = this.locations.filter((location) => location.id !== id);
  }

  resetFilters(): void {
    this.departmentId = null;
    deleteSearchParamInURL(AvailableLocationsFilters.orgId);
    this.pagination.setPage(null, 1);
    this.locationAddress.clearFilter();
    this.fetchLocations();
  }

  get isLoading(): boolean {
    return this.status === Status.Pending;
  }
}
