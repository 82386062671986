import { StudentStatus } from './types';


export const withoutDateKey = 'without-date';


export const recordStudentStatus: Record<StudentStatus, string> = {
  [StudentStatus.absent]: 'неявка',
  [StudentStatus.accepted]: 'подтверждено',
  [StudentStatus.participated]: 'пройдено',
  [StudentStatus.results]: 'пройдено',
  [StudentStatus.subscribed]: 'регистрация завершена',
  [StudentStatus.waiting]: 'ожидает подтверждения',
};
