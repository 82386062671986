import { v4 } from 'uuid';
import { EventOrganization } from 'store/apiModels/events/types';
import { CreateEventOrgsType } from 'store/apiClients/events/types';
import { SingleSelect } from 'store/utilityClasses/Select/SingleSelect';

import { DepartmentTypeSelect } from 'store/utilityClasses/DepartmentTypeSelect';
import { EventLocationsFormsType } from './types';
import { SelectEventLocation } from '../SelectEventLocation';


export const EventLocationParser = {
  toApi: (locationsForms: EventLocationsFormsType): CreateEventOrgsType => Object
    .values(locationsForms)
    .reduce((acc, location) => {
      const { selectDepartment, selectEventLocation } = location;

      const organizationId = selectDepartment.selectId;
      const isHaveCheckedLocations = Boolean(selectEventLocation.checkedLocations.length);

      if (organizationId && isHaveCheckedLocations) {
        acc.push({
          orgId: organizationId,
          locations: selectEventLocation.toApi,
        });
      }

      return acc;
    }, [] as CreateEventOrgsType),

  fromApi: (rawEventOrganizations: EventOrganization[]): EventLocationsFormsType => rawEventOrganizations
    .reduce((acc, rawEventOrganization) => ({
      ...acc,
      [v4()]: {
        selectDepartment: new SingleSelect(rawEventOrganization.orgId),
        selectEventLocation: new SelectEventLocation(rawEventOrganization.locations),
        selectDirectionType: new DepartmentTypeSelect(rawEventOrganization.orgTypeId),
      },
    }), {} as EventLocationsFormsType),
};
