import { NavLinkType } from 'constants/routes';
import { KimUrl } from 'constants/kimUrl';


export const getLinkCertificate = (id?: number): NavLinkType => ({
  to: `/profile/${id || ''}?tab=certificate`, title: 'Сертификаты',
});

export const isProd = (): boolean => window.location.hostname === 'my.mcko.ru';

export const getKimLink = (): NavLinkType => ({
  to: isProd() ? KimUrl.PROD : KimUrl.DEV,
  title: 'КИМ',
  isExternal: true,
});
