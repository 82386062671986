import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';
import { globalStore } from 'store/contexts';
import { FilterInput } from 'store/utilityClasses/FilterInput';
import { setFiltersToStore } from 'utils/filter';
import debounce from 'lodash-es/debounce';
import { AvailableEventResultFilters } from './enums';
import { EventResult } from 'common/eventResult/types';
import { GetEventResultsParams } from 'store/apiClients/events/types';


type FiltersWithCallbacks = AvailableEventResultFilters.fio_email;

export class EventResultFiltersState {
  eventId: number;
  fio_email = new FilterInput(AvailableEventResultFilters.fio_email);


  readonly eventResultsStore = globalStore.eventResultStore;

  constructor(eventId: number) {
    makeAutoObservable(this, { }, { autoBind: true });

    this.eventId = eventId;

    setFiltersToStore<this, FiltersWithCallbacks>(
      this,
      [AvailableEventResultFilters.fio_email],
      this.fetchEventResultsDebounced,
      this.eventResultsStore.pagination.resetPage,
    );
  }

  * fetchEventResults(): Generator {
    const params: GetEventResultsParams = { event_id: this.eventId };

    if (this.fio_email.value) {
      params.fio_email = this.fio_email.value;
    }

    yield this.eventResultsStore.fetchEventResults(params) as AxiosResponse<EventResult[]>;
  }

  fetchEventResultsDebounced = debounce((): void => {
    this.fetchEventResults();
  }, 800);

  resetFilters(): void {
    this.fio_email.clearFilter();
    this.eventResultsStore.pagination.resetPage();
    this.fetchEventResults();
  }
}
