import { makeAutoObservable } from 'mobx';
import { LocationsState } from 'store/states/locations';
import { EventOrganizationLocation } from 'store/apiModels/events/types';


export class SelectEventLocation {
  locationsState = new LocationsState();

  checkedLocations: number[] = [];

  constructor(rawEventLocationData?: EventOrganizationLocation[]) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.fromApi(rawEventLocationData);
  }

  setChecked(checkedEventLocations: (string | number)[]): void {
    this.checkedLocations = checkedEventLocations.map(Number);
  }

  get toApi(): Array<{ id: number }> {
    return this.checkedLocations.map((locationId) => ({ id: locationId }));
  }

  fromApi(rawEventLocationData?: EventOrganizationLocation[]): void {
    if (!rawEventLocationData) return;
    this.checkedLocations = rawEventLocationData.map(({ id }) => id);
  }

  resetEventLocations(): void {
    this.checkedLocations = [];
  }
}
