import { makeAutoObservable } from 'mobx';
import { DepartmentTypesId } from 'common/departmens/enums';


export class DepartmentTypeSelect {
  selectedId: DepartmentTypesId | null = null;

  constructor(departmentTypeId: DepartmentTypesId | null = null) {
    makeAutoObservable(this, {}, { autoBind: true });
    if (departmentTypeId) this.setSelectId(departmentTypeId);
  }

  setSelectId(value: DepartmentTypesId): void {
    this.selectedId = value;
  }
}
