import { eventUsersApi } from 'constants/api';
import axios, { AxiosResponse } from 'axios';
import { EventUsersServiceGetParams } from 'store/apiClients/eventUsers/types';
import { EventUsersApiModel } from 'store/apiModels/eventUsers/types';


export class EventUsersService {
  exportToXlsx = async (eventId: number): Promise<AxiosResponse> => (
    axios.get(`${eventUsersApi}/xlsx`, { params: { event_id: eventId }, responseType: 'blob' })
  );

  get = async ({
    eventId, page = 1, perPage = 100, email = null, school = null, city = null, status = null, timeslotId = null,
  }: EventUsersServiceGetParams): Promise<AxiosResponse<EventUsersApiModel[]>> => axios.get(
    eventUsersApi, {
      params: {
        event_id: eventId,
        page,
        school,
        city,
        status,
        per_page: perPage,
        fio_email: email || null,
        timeslot_id: timeslotId,
      },
    },
  );
}
