import React, { ReactElement } from 'react';
import { footer } from 'components/pages/LandingPage/constants/footer';
import { REGEX_RULES } from 'constants/regexRules';
import { Card } from './Card';
import styles from './styles.scss';


export const Footer = (): ReactElement => {
  const { mail, phone, copyright, blockResources, restrictions } = footer;
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.root}>
      <div>
        <a href={`mailto:${mail}`} className={styles.mail}>
          {mail}
        </a>
        <a
          className={styles.phone}
          href={`tel:${phone.replace(REGEX_RULES.nonNumericValueWithPlusPrefix, '')}`}
        >
          {phone}
        </a>
        <p className={styles.copyright}>
          {copyright} {currentYear}
        </p>
      </div>
      <Card
        title={blockResources.title}
        list={blockResources.list}
      />
      <span className={styles.restrictions}>{restrictions}</span>
    </footer>
  );
};
