import { makeAutoObservable } from 'mobx';
import { ChangeEvent } from 'react';


export class Input<T extends ChangeEvent<HTMLInputElement | HTMLTextAreaElement>> {
  value = '';

  constructor(defaultValue = '') {
    makeAutoObservable(this);
    this.value = defaultValue;
  }

  onChange = (e: T): void => {
    this.value = e.target.value;
  };

  setValue = (value: string): void => {
    this.value = value;
  };

  clear = (): void => {
    this.value = '';
  };
}
