import React, { ReactElement, useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Routes } from 'common/routes/enums';
import {
  ColumnEventNextContext,
  ResultEditContext,
  CreateEventContext,
  CreateGroupsContext,
  DatePickerContext,
  EventsFiltersContext,
  ManageGroupsContext,
  OurStudentsContext,
  ProfileContext,
  RegisteredMembersContext,
  RegistrationContext,
  EventResultsContext,
  LoadCertificateContext,
  CertificateContext,
  PersonalDataContext,
  DirectoriesContext,
  LocationsContext,
  CreateLocationContext,
  CreateGlobalEventContext,
  CertificateConstructorContext,
  GlobalEventsFiltersContext,
  EventRegistrationButtonContext,
  StudentsPhotosContext,
  MyEventsContext,
  CombineEventsContext,
  CreateCombineEventContext
} from 'store/contexts/local';
import { RegistrationState } from 'store/states/registration';
import { RegisteredMembersState } from 'store/states/registeredMembers';
import { RegisteredMembersFiltersState } from 'store/states/registeredMembersFilters';
import { ProfileState } from 'store/states/profile';
import { OurStudentsState } from 'store/states/ourStudents';
import { EventsFiltersState } from 'store/states/eventsFilters';
import { EventManageGroupsState } from 'store/states/eventManageGroups';
import { EventCreateGroupsState } from 'store/states/eventCreateGroups';
import { DatePickerState } from 'store/states/datePicker';
import { CreateEventState } from 'store/states/createEvent';
import { EventsNextState } from 'store/states/eventsNext';
import { ResultEditState } from 'store/states/resultEdit';
import { LoadCertificate } from 'store/states/loadCertificate';
import { CertificatesState } from 'store/states/certificates';
import { EventResults } from 'store/apiModels/eventResults';
import { PersonalDataState } from 'store/states/personalData';
import { DirectoriesState } from 'store/states/directories';
import { LocationsState } from 'store/states/locations';
import { CreateLocationState } from 'store/states/createLocation';
import { CreateGlobalEventState } from 'store/states/createGlobalEvent';
import { CertificateConstructorState } from 'store/states/certificateConstructor';
import { GlobalEventsFiltersState } from 'store/states/globalEventsFilters';
import { EventRegistrationButtonState } from 'store/states/eventRegistrationButton';
import { EventResultFiltersState } from '../states/eventResultFilters';
import { StudentsPhotosState } from 'store/states/studentsPhotos';
import { MyEventsState } from 'store/states/myEvents';
import { CombineEventsState } from '../states/combineEvents';
import { CreateCombineEventState } from '../states/createCombineEvent';


interface Props {
  children: ReactElement
}

export const RegistrationProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ registrationState: new RegistrationState() }), []);
  return (<RegistrationContext.Provider value={localStore}>{children}</RegistrationContext.Provider>);
};

export const RegisteredMembersProvider = ({ children }: Props): ReactElement => {
  const { id = 0 } = useParams<{ id: string }>();
  const eventId = Number(id);
  const localStore = useMemo(() => ({
    registeredMembersState: new RegisteredMembersState(),
    registeredMembersFiltersState: new RegisteredMembersFiltersState(eventId),
  }), []);
  return (<RegisteredMembersContext.Provider value={localStore}>{children}</RegisteredMembersContext.Provider>);
};

export const ProfileProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ profileState: new ProfileState() }), []);
  return (<ProfileContext.Provider value={localStore}>{children}</ProfileContext.Provider>);
};

export const OurStudentsProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({
    ourStudentsState: new OurStudentsState(),
  }), []);
  return (<OurStudentsContext.Provider value={localStore}>{children}</OurStudentsContext.Provider>);
};

export const StudentsPhotosProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({
    studentsPhotosState: new StudentsPhotosState()
  }), []);
  return (<StudentsPhotosContext.Provider value={localStore}>{children}</StudentsPhotosContext.Provider>);
};

export const MyEventsProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({
    myEventsState: new MyEventsState()
  }), []);
  return (<MyEventsContext.Provider value={localStore}>{children}</MyEventsContext.Provider>);
};

export const EventsFiltersProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ eventsFiltersState: new EventsFiltersState() }), []);
  return (<EventsFiltersContext.Provider value={localStore}>{children}</EventsFiltersContext.Provider>);
};

export const EventManageGroupsProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ eventManageGroupsState: new EventManageGroupsState() }), []);
  return (<ManageGroupsContext.Provider value={localStore}>{children}</ManageGroupsContext.Provider>);
};

export const EventCreateGroupsProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ eventCreateGroupsState: new EventCreateGroupsState() }), []);
  return (<CreateGroupsContext.Provider value={localStore}>{children}</CreateGroupsContext.Provider>);
};

export const DatePickerProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ datePickerState: new DatePickerState() }), []);
  return (<DatePickerContext.Provider value={localStore}>{children}</DatePickerContext.Provider>);
};

export const CreateEventPageProvider = ({ children }: Props): ReactElement => {
  const isEditable = Boolean(useRouteMatch(Routes.editEvent));
  const localStore = useMemo(() => ({ createEventState: new CreateEventState(isEditable) }), []);
  return (<CreateEventContext.Provider value={localStore}>{children}</CreateEventContext.Provider>);
};

export const ColumnEventNextProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ eventsNextState: new EventsNextState() }), []);
  return (<ColumnEventNextContext.Provider value={localStore}>{children}</ColumnEventNextContext.Provider>);
};

export const EventResultsProvider = ({ children }: Props): ReactElement => {
  const { eventId = 0 } = useParams<{ eventId: string }>();
  const $eventId = Number(eventId);
  const localStore = useMemo(() => ({
    eventResults: new EventResults(),
    eventResultFilters: new EventResultFiltersState($eventId)
  }), []);
  return (<EventResultsContext.Provider value={localStore}>{children}</EventResultsContext.Provider>);
};

export const ResultEditProvider = ({ children }: Props): ReactElement => {
  const localStores = useMemo(() => ({ resultEditState: new ResultEditState() }), []);
  return (<ResultEditContext.Provider value={localStores}>{children}</ResultEditContext.Provider>);
};

export const LoadCertificateProvider = ({ children }: Props): ReactElement => {
  const localStores = useMemo(() => ({ loadCertificateState: new LoadCertificate() }), []);
  return <LoadCertificateContext.Provider value={localStores}>{children}</LoadCertificateContext.Provider>;
};

export const CertificateProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ certificatesState: new CertificatesState() }), []);
  return (<CertificateContext.Provider value={localStore}>{children}</CertificateContext.Provider>);
};

export const PersonalDataProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ personalDataState: new PersonalDataState() }), []);
  return (<PersonalDataContext.Provider value={localStore}>{children}</PersonalDataContext.Provider>);
};

export const DirectoriesProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ directoriesState: new DirectoriesState() }), []);
  return (<DirectoriesContext.Provider value={localStore}>{children}</DirectoriesContext.Provider>);
};

export const CombineEventsProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ combineEventsState: new CombineEventsState() }), []);
  return (<CombineEventsContext.Provider value={localStore}>{children}</CombineEventsContext.Provider>);
};

export const CreateCombineEventProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ createCombineEventState: new CreateCombineEventState() }), []);
  return (<CreateCombineEventContext.Provider value={localStore}>{children}</CreateCombineEventContext.Provider>);
};

export const LocationsProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ locationsState: new LocationsState() }), []);
  return (<LocationsContext.Provider value={localStore}>{children}</LocationsContext.Provider>);
};

export const CreateLocationProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ createLocationState: new CreateLocationState() }), []);
  return (<CreateLocationContext.Provider value={localStore}>{children}</CreateLocationContext.Provider>);
};

export const CreateGlobalEventProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ createGlobalEventState: new CreateGlobalEventState() }), []);
  return (<CreateGlobalEventContext.Provider value={localStore}>{children}</CreateGlobalEventContext.Provider>);
};

export const CertificateConstructorProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ certificateConstructorState: new CertificateConstructorState() }), []);
  return (
    <CertificateConstructorContext.Provider value={localStore}>
      {children}
    </CertificateConstructorContext.Provider>
  );
};

export const GlobalEventsFiltersProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ globalEventsFiltersState: new GlobalEventsFiltersState() }), []);
  return (<GlobalEventsFiltersContext.Provider value={localStore}>{children}</GlobalEventsFiltersContext.Provider>);
};

export const EventRegistrationButtonProvider = ({ children }: Props): ReactElement => {
  const localStore = useMemo(() => ({ eventRegistrationButtonState: new EventRegistrationButtonState() }), []);
  return (
    <EventRegistrationButtonContext.Provider value={localStore}>
      {children}
    </EventRegistrationButtonContext.Provider>
  );
};
