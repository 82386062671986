import axios, { AxiosResponse } from 'axios';
import { groupUsersApi } from 'constants/api';
import { GroupUserModel } from './types';


export interface GetParams {
  groupId: number
  page?: number,
  perPage?: number,
}


export class GroupUsersService {
  get = async ({ groupId, page = 1, perPage = 100 }: GetParams): Promise<AxiosResponse<GroupUserModel[]>> => (
    axios.get(groupUsersApi, {
      params: {
        group_id: groupId,
        page,
        per_page: perPage,
      },
    })
  );
  
  getParticipantsOnDateReport = async (date: string): Promise<any> => (
    axios.get(`${groupUsersApi}/xlsx/participants_on_date`, {
      params: {
        on_date: date
      },
      responseType: 'blob'
    })
  )
}
