import axios, { AxiosResponse } from 'axios';
import { eventGroupsApi, eventsApi } from 'constants/api';
import {
  CreateGroupsParams, EventGroupModelApi,
  UpdateLinkForGroupListParams, EventGroupStatApi,
  UpdateGroupParams, UpdateLocationForGroupListParams,
} from './types';


export class EventGroupsService {
  get = async (eventId?: number): Promise<AxiosResponse<EventGroupModelApi[]>> => (
    axios.get(eventGroupsApi, {
      params: {
        event_id: eventId,
      },
    })
  );

  getById = async (groupId: number): Promise<AxiosResponse<EventGroupModelApi>> => (
    axios.get(`${eventGroupsApi}/${groupId}`)
  );

  getGroupStat = async (eventId: number): Promise<AxiosResponse<EventGroupStatApi>> => (
    axios.get(`${eventsApi}/count_statistic/${eventId}`)
  );

  createForEvent = async (eventId: number): Promise<AxiosResponse<EventGroupModelApi>> => (
    axios.post(`${eventGroupsApi}/create_for_event`, null, {
      params: {
        event_id: eventId,
      },
    })
  );

  deleteById = async (groupId: number): Promise<AxiosResponse> => (
    axios.delete(`${eventGroupsApi}/${groupId}`)
  );

  createByGroup = async ({ id, count, reserved }: CreateGroupsParams): Promise<AxiosResponse> => (
    axios.post(`${eventGroupsApi}/generate_by_group_count`, null, {
      params: {
        event_id: id,
        group_count: count,
        reserved_groups: reserved,
      },
    })
  );

  createByUser = async ({ id, count, reserved }: CreateGroupsParams): Promise<AxiosResponse> => (
    axios.post(`${eventGroupsApi}/generate_by_user_count`, null, {
      params: {
        event_id: id,
        user_count: count,
        reserved_places: reserved,
      },
    })
  );

  createBySchool = async ({ id }: CreateGroupsParams): Promise<AxiosResponse> => (
    axios.post(`${eventGroupsApi}/generate_by_schools`, null, {
      params: {
        event_id: id,
      },
    })
  );

  createByLocations = async ({ id }: CreateGroupsParams): Promise<AxiosResponse> => (
    axios.post(`${eventGroupsApi}/generate_by_locations`, null, {
      params: {
        event_id: id,
      },
    })
  );

  updateGroup = async (group: UpdateGroupParams): Promise<AxiosResponse<EventGroupModelApi>> => (
    axios.put(`${eventGroupsApi}/save_timeslot`, group)
  );

  updateLinkForGroupList = async (
    { groupIds, link }: UpdateLinkForGroupListParams,
  ): Promise<AxiosResponse<EventGroupModelApi[]>> => (
    axios.put(`${eventGroupsApi}/update_link_for_group_list`, {
      groupIds,
      link,
    })
  );

  updateLocationForGroupList = async (
    { groupIds, locationId }: UpdateLocationForGroupListParams,
  ): Promise<AxiosResponse<EventGroupModelApi[]>> => (
    axios.put(`${eventGroupsApi}/update_location_for_group_list`, {
      groupIds,
      locationId,
    })
  );

  deleteForEvent = async (eventId: number): Promise<AxiosResponse> => (
    axios.delete(`${eventGroupsApi}/delete_for_event`, { params: { event_id: eventId } })
  );
}
