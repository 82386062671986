import { makeAutoObservable } from 'mobx';
import { TagsApi } from 'store/apiClients/tags/types';
import { globalStore } from 'store/contexts';
import { AutocompleteInputChangeReason } from '@material-ui/lab';
import { isNumber } from 'utils/isNumber';

import { MAX_LENGTH_TAG_NAME } from './constants';


export class SelectTags {
  private readonly tagsStore = globalStore.tagsStore;
  tags: TagsApi[] = [];

  tagName = '';
  tagId: number | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get tagIds(): number[] {
    return this.tags.map(({ id }) => id);
  }

  setSelectedTagsFromApi(tags: TagsApi[]): void {
    this.tags = tags;
  }

  pushTagById(id: number): void {
    const tag = this.tagsStore.getTagById(id);
    if (tag) this.tags.push(tag);
  }

  removeTagById(id: number): void {
    this.tags = this.tags.filter((tag) => tag.id !== id);
  }

  get filteredTagIds(): number[] {
    const { tagIds: localTagIds, tagsStore } = this;
    return tagsStore.tagIds.filter((id) => !localTagIds.includes(id));
  }

  onChangeTagName(value: unknown, reason: AutocompleteInputChangeReason): void {
    if (typeof value !== 'string') return;
    if (reason !== 'input') this.tagName = value;
    if (value.length <= MAX_LENGTH_TAG_NAME) this.tagName = value;
  }

  onChangeTagId(value: unknown): void {
    if (isNumber(value)) this.tagId = value;
  }

  resetTagNameAndId(): void {
    this.tagName = '';
    this.tagId = null;
  }

  onApplyTag(): void {
    const { trimmedTagName, tagId } = this;

    if (tagId || trimmedTagName) {
      this.applyTag(trimmedTagName, tagId);
      this.resetTagNameAndId();
    }
  }

  get trimmedTagName(): string {
    return this.tagName.trim();
  }

  get isApplyButtonDisabled(): boolean {
    return !(this.tagId || this.trimmedTagName);
  }

  * applyTag(tagName: string, tagId: number | null): Generator {
    if (tagId) {
      this.pushTagById(tagId);
    } else if (tagName) {
      const newTagId = yield this.tagsStore.createTag(tagName);
      if (newTagId) {
        yield this.tagsStore.fetchTags();
        this.pushTagById(newTagId as number);
      }
    }
  }
}
