import { DepartmentsService, GetByCityIdParams, GetByTypeParams } from 'store/apiClients/departments';
import { makeAutoObservable } from 'mobx';
import { DepartmentApi, SchoolsVkResponse, SchoolVkModelApi } from 'store/apiClients/departments/types';
import { AxiosResponse } from 'axios';
import { DepartmentTypesId } from 'common/departmens/enums';
import { Status } from 'constants/status';
import { RECORD_DEPARTMENT_LATIN_NAMES } from 'constants/departments';
import { DepartmentType } from './types';


export class DepartmentsStore {
  private departmentsService = new DepartmentsService();

  status = Status.Initial;

  school = new Map<number, DepartmentType>();
  university = new Map<number, DepartmentType>();
  jurisdiction = new Map<number, DepartmentType>();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  getById = (id: number, type: DepartmentTypesId): DepartmentType | null => (
    this[RECORD_DEPARTMENT_LATIN_NAMES[type]].get(id) || null
  );

  getShortNameById = (
    id: number | null,
    type: DepartmentTypesId,
  ): string => this.getById(Number(id), type)?.shortName || '';

  getIdsByType(type: DepartmentTypesId): number[] {
    return [...this[RECORD_DEPARTMENT_LATIN_NAMES[type]].keys()];
  }

  get departments(): Map<number, DepartmentType> {
    return new Map([...this.school, ...this.university, ...this.jurisdiction]);
  }

  get departmentsIds(): Array<number> {
    return [...this.departments.keys()];
  }

  * fetchDepartments(): Generator {
    try {
      this.status = Status.Pending;
      const { data } = (yield this.departmentsService.get()) as AxiosResponse<DepartmentApi[]>;
      this.setDepartmentsFromApi(data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * fetchDepartmentsByType(params: GetByTypeParams): Generator {
    try {
      this.status = Status.Pending;
      const { data } = (yield this.departmentsService.getByType(params)) as AxiosResponse<DepartmentApi[]>;
      this.setDepartmentsFromApi(data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * fetchSchoolsByCityId(params: GetByCityIdParams): Generator {
    try {
      this.status = Status.Pending;
      const { data } = (yield this.departmentsService.getSchoolsByCityId(params)) as AxiosResponse<SchoolsVkResponse>;
      this.clearSchools();
      this.setDepartmentsFromVkApi(data.items);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  setDepartmentsFromVkApi(apiData: SchoolVkModelApi[]): void {
    apiData.forEach((api) => {
      const department = {
        id: api.id,
        name: api.title,
        shortName: api.title,
        isMoscow: false,
      };
      this.school.set(api.id, department);
    });
  }

  setDepartmentsFromApi(dataApi: DepartmentApi[]): void {
    dataApi.forEach((api) => {
      const department = {
        id: api.id,
        isMoscow: api.school ? api.school.isMoscow : true,
        name: api.name,
        shortName: api.shortname,
      };
      this[RECORD_DEPARTMENT_LATIN_NAMES[api.type.id]].set(api.id, department);
    });
  }

  isNeedLoadDepartmentByType(type: DepartmentTypesId): boolean {
    return !this[RECORD_DEPARTMENT_LATIN_NAMES[type]].size;
  }

  clearSchools(): void {
    this.school.clear();
  }
}
