export enum RegConditionTypes {
  BY_RESULTS = 'BY_RESULTS',
  BY_PARALLELS = 'BY_PARALLELS',
  BY_PROFILE_TYPE = 'BY_PROFILE_TYPE',
  BY_PARTICIPATION_IN_EVENT = 'BY_PARTICIPATION_IN_EVENT',
  BY_NOT_PARTICIPATION_IN_EVENT = 'BY_NOT_PARTICIPATION_IN_EVENT',
}

export enum AttachmentTypes {
  audio = 'audio',
  image = 'image',
  document = 'document',
  documentLink = 'document_link',
}

export enum EventTypes {
  THEORY = 'theory',
  PRACTICE = 'practice',
  CONSULTATION = 'consultation',
}
