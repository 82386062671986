import { makeAutoObservable } from 'mobx';
import { ChangeEvent } from 'react';

export class Checkbox<T extends ChangeEvent<HTMLInputElement>> {
  value: boolean = false;

  constructor(defaultValue: boolean = false) {
    makeAutoObservable(this);
    this.value = defaultValue;
  }

  onChange = (e: T): void => {
    this.value = e.target.checked;
  };

  setValue = (value: boolean): void => {
    this.value = value;
  }
}
