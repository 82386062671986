import { updateHistoryState } from './historyState';


export const getInitialParam = (paramName: string): number | null => Number(
  new URLSearchParams(window.location.search).get(paramName),
) || null;

export const getInitialParamStr = (
  paramName: string,
): string => decodeURIComponent(
  new URLSearchParams(window.location.search).get(paramName) || '',
);

export const getInitialArrayParam = (
  paramName: string,
): number[] => (new URLSearchParams(window.location.search).get(paramName) || '')
  .split(',')
  .filter((item) => item)
  .map((item) => Number(item));

export const setSearchParamsToURL = (filters: Record<string, string>): void => {
  const filtersSearchParams = new URLSearchParams();
  Object.keys(filters).forEach((key) => {
    filtersSearchParams.set(key, filters[key]);
  });
  updateHistoryState(filtersSearchParams.toString());
};

export const updateSearchParamInURL = (name: string, value: string | number): void => {
  const searchParams = new URLSearchParams(window.location.search);
  value === '' ? searchParams.delete(name) : searchParams.set(name, String(value));
  updateHistoryState(searchParams.toString());
};

export const deleteSearchParamInURL = (name: string): void => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(name);
  updateHistoryState(searchParams.toString());
};
