import { makeAutoObservable } from 'mobx';


type NumberOrNull = number | null;

export class SingleSelect {
  readonly defaultId: NumberOrNull;
  selectId: NumberOrNull = null;

  constructor(defaultId: NumberOrNull = null) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.defaultId = defaultId;
    this.setId(defaultId);
  }

  setId(id: NumberOrNull): void {
    this.selectId = id;
  }

  reset(): void {
    this.selectId = this.defaultId;
  }

  clear(): void {
    this.selectId = null;
  }
}

