import { makeAutoObservable } from 'mobx';

import { EventType } from './enums';


export class SelectEventType {
  selectedEventType: EventType | null = null;

  constructor(type: EventType | null = null) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.setEventType(type);
  }

  setEventType(type: EventType | null = null): void {
    this.selectedEventType = type;
  }

  reset(): void {
    this.selectedEventType = null;
  }

  get isFullTime(): boolean {
    return this.selectedEventType === EventType.fullTime;
  }

  get isDistance(): boolean {
    return this.selectedEventType === EventType.distance;
  }
}
