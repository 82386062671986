import axios, { AxiosResponse } from 'axios';
import { trendsApi } from 'constants/api';
import { GetTrendsParams, TrendsApi } from './types';


export class TrendsService {
  get = async (params: GetTrendsParams): Promise<AxiosResponse<TrendsApi[]>> => {
    const {
      page = 1,
      perPage = 1000,
      directionId,
    } = params;
    return (
      axios.get(trendsApi, {
        params: {
          page,
          per_page: perPage,
          direction_id: directionId,
        },
      })
    );
  };
}
