import { makeAutoObservable } from 'mobx';


export class CheckboxGroup {
  selectedIds = new Set<number>();

  constructor() { makeAutoObservable(this, {}, { autoBind: true }); }

  get listIds(): number[] { return [...this.selectedIds.values()]; }

  has(id: number): boolean { return this.selectedIds.has(id); }

  add(id: number): void { this.selectedIds.add(id); }

  set(ids: number[]): void { this.selectedIds = new Set(ids); }

  toggle(id: number): void {
    const action = this.has(id) ? this.delete : this.add;
    action(id);
  }

  delete(id: number): void { this.selectedIds.delete(id); }

  clear(): void { this.selectedIds.clear(); }
}
