import { AxiosResponse } from 'axios';
import { DepartmentTypesId } from 'common/departmens/enums';
import { Status } from 'constants/status';
import { makeAutoObservable } from 'mobx';
import { EventsAcceptParams } from 'store/apiClients/events';
import { EventsNextService } from 'store/apiClients/eventsNext';
import { globalStore } from 'store/contexts';
import { getISODatePlus3Hours } from 'utils/date/formatters';
import { withoutDateKey } from './constants';
import {
  EventNextType,
  EventNextWaiting,
  EventOrgNotificationType,
  EventsNextDatesObjType,
  FetchEventsNextAxiosResponse,
  StudentStatus,
} from './types';
import { withoutDateComparator } from './utils';


export class EventsNextState {
  status = Status.Initial;

  eventsWaiting: EventNextWaiting[] = [];
  eventsMySkills: EventNextType[] = [];
  eventsNext: EventNextType[] = [];
  noticeForOrganizer: EventOrgNotificationType[] = [];
  noticeForSchool: EventOrgNotificationType[] = [];

  readonly eventsNextService = new EventsNextService();

  readonly eventsStore = globalStore.eventsStore;
  readonly profileTypeStore = globalStore.profileTypeStore;
  readonly departmentsStore = globalStore.departmentsStore;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * fetchEventsNext(): Generator {
    try {
      this.status = Status.Pending;
      const [responseNext, responseWaiting, responseMySkills] = (yield Promise.all([
        this.eventsNextService.getNext(),
        this.eventsNextService.getWaiting(),
        this.eventsNextService.getMySkills(),
        this.departmentsStore.fetchDepartmentsByType({ type: DepartmentTypesId.school, isExamSchool: true }),
      ])) as FetchEventsNextAxiosResponse;
      this.status = Status.Fulfilled;
      this.eventsNext = responseNext.data;
      this.eventsWaiting = responseWaiting.data;
      this.eventsMySkills = responseMySkills.data;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }

  * fetchNotification(): Generator {
    try {
      this.status = Status.Pending;
      const promises = this.profileTypeStore.isSchool
        ? [this.eventsNextService.getNotificationForOrganizer(), this.eventsNextService.getNotificationForSchool()]
        : [this.eventsNextService.getNotificationForOrganizer()];

      const [responseOrganizer, responseSchool = { data: [] }] = (
        yield Promise.all(promises)
      ) as AxiosResponse<EventOrgNotificationType[]>[];

      this.status = Status.Fulfilled;
      this.noticeForOrganizer = responseOrganizer.data;
      this.noticeForSchool = responseSchool.data;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }

  * acceptEvent(params: EventsAcceptParams): Generator {
    yield this.eventsStore.acceptEvent(params);
    yield this.fetchEventsNext();
  }

  * cancelEvent(id: number): Generator {
    yield this.eventsStore.cancelEvent(id);
    yield this.fetchEventsNext();
  }

  get eventsNextDatesObj(): EventsNextDatesObjType {
    return this.eventsNext.reduce((acc, event) => {
      const key = event.eventDate && (StudentStatus.subscribed !== event.studentStatus || (event.giveChooseTimeslot && event.studentStatus === StudentStatus.subscribed))
        ? getISODatePlus3Hours(event.eventDate)
        : withoutDateKey;
      return { ...acc, [key]: acc[key] ? [...acc[key], event] : [event] };
    }, {} as EventsNextDatesObjType);
  }

  get eventsNextDatesArr(): string[] {
    return Object.keys(this.eventsNextDatesObj).sort(withoutDateComparator);
  }
}
