import React, { ReactElement } from 'react';
// import { Header } from './Header';
// import { Directions } from './Directions';
// import { ConferencesEntrances } from './Conferences/ConferencesEntrances';
// import { Statistics } from './Statistics';
// import { Events } from './Events';
// import { TimeLine } from './TimeLine';
// import { Documents } from './Documents';
import { Footer } from './Footer';
import styles from './styles.scss';
import { TopFinance } from './TopFinance';
import { RedMessage } from './RedMessage';
import { SchoolResultsStats } from './SchoolResultsStatistics';


export const LandingPage = (): ReactElement => (
  <>
    <div className={styles.fakeBlock} />
    <RedMessage />
    <TopFinance />
    <div className={styles.bottomIndent} />
    {/*<SchoolResultsStats />*/}
    {/* <Header/> */}
    {/* <main className={styles.main}> */}
    {/* <Directions /> */}
    {/* <ConferencesEntrances /> */}
    {/* <Statistics /> */}
    {/* <Events /> */}
    {/* <TimeLine /> */}
    {/* <Documents /> */}
    {/* </main> */}
    <Footer />
  </>
);
