import { makeAutoObservable } from 'mobx';
import { DateInterval } from 'store/utilityClasses/DateInterval/DateInterval';
import { Input } from 'store/utilityClasses/Input/Input';
import { DepartmentTypesId } from 'common/departmens/enums';
import { DepartmentTypeSelect } from 'store/utilityClasses/DepartmentTypeSelect';
import { SingleSelect } from 'store/utilityClasses/Select/SingleSelect';
import { Checkbox } from 'store/utilityClasses/Checkbox/Checkbox';
import { GlobalEventService } from 'store/apiClients/globalEvent';
import { AxiosResponse } from 'axios';
import {
  StageApi,
  OrganizerApi,
  SubscriberApi,
  MetaEventType,
  CertificateApi,
  MetaEventCodeType,
  CreateMetaEventType,
} from 'store/apiClients/globalEvent/types';
import { Status } from 'constants/status';
import { Routes } from 'common/routes/enums';
import { generatePath } from 'react-router-dom';

import { Phases } from './UtilityClasses/Phases';
import { Signers } from './UtilityClasses/Signers';
import { Certificates } from './UtilityClasses/Certificates';
import { parseISODateFormat } from './utils';
import { InformationType } from './enums';



export class CreateGlobalEventState {
  status = Status.Initial;
  
  withCheckProfileCheckbox = new Checkbox();
  allStagesCheckbox = new Checkbox();
  inputGlobalEventName = new Input();
  dateIntervalActivePhase = new DateInterval();

  selectCompetitionType = new SingleSelect();
  selectDirectionType = new SingleSelect();
  selectGlobalEventType = new SingleSelect();
  informationStatus = Status.Initial;

  departmentSelect = new SingleSelect();
  departmentTypeSelect = new DepartmentTypeSelect();

  phases = new Phases();
  signers = new Signers();
  certificates = new Certificates();

  directions: MetaEventCodeType[] = [];
  competitions: MetaEventCodeType[] = [];
  globalEventTypes: MetaEventCodeType[] = [];

  redirectUrl: string | null = null;

  readonly service = new GlobalEventService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * fetchMetaEventInformation(): Generator {
    try {
      this.informationStatus = Status.Pending;

      const [responseGlobalEventsType, responseDirectionsType] = (yield Promise.all([
        this.service.getGlobalEventsTypes(),
        this.service.getDirections(),
      ])) as [AxiosResponse<MetaEventCodeType[]>, AxiosResponse<MetaEventCodeType[]>];

      this.informationStatus = Status.Fulfilled;

      this.globalEventTypes = responseGlobalEventsType.data;
      this.directions = responseDirectionsType.data;
    } catch {
      this.informationStatus = Status.Rejected;
    }
  }

  * fetchCompetitionsByGlobalEventType(): Generator {
    try {
      const eventType = this.selectGlobalEventType.selectId;
      if (eventType) {
        const { data } = (
          yield this.service.getCompetitionsByGlobalEventType(eventType)
        ) as AxiosResponse<MetaEventCodeType[]>;
        this.competitions = data;
      }
    } catch (e) {
      //
    }
  }

  * fetchLoadGlobalEventById(id: number): Generator {
    try {
      this.status = Status.Pending;

      const { data } = (yield this.service.getGlobalEventById(id)) as AxiosResponse<MetaEventType>;
      this.setGlobalEventFromApi(data);

      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * fetchCreateGlobalEvent(id?: number): Generator {
    try {
      (yield this.service.createGlobalEvent(this.createdData(id))) as AxiosResponse<MetaEventType[]>;
      this.redirectUrl = generatePath(Routes.globalEvents);
      window.scrollTo({ top: 0 });
    } catch (e) {
      //
    }
  }

  setGlobalEventFromApi(data: MetaEventType): void {
    const {
      activePhaseEnd, activePhaseStart, allStages, certificates, competition, direction,
      globalEvent, name, organizer, stages, subscribers, withCheckProfile
    } = data;

    this.dateIntervalActivePhase.startDate = new Date(activePhaseStart);
    this.dateIntervalActivePhase.endDate = new Date(activePhaseEnd);
    this.allStagesCheckbox.value = allStages;
    this.withCheckProfileCheckbox.value = withCheckProfile;
    this.inputGlobalEventName.value = name;
    this.departmentSelect.selectId = organizer.id;
    this.departmentTypeSelect.selectedId = organizer.type.id;
    this.selectCompetitionType.selectId = competition.id;
    this.selectDirectionType.selectId = direction.id;
    this.selectGlobalEventType.selectId = globalEvent.id;
    stages.forEach((stage) => {
      this.phases.createPhase(undefined, stage);
    });
    subscribers.forEach((subscriber) => {
      this.signers.createSigner(undefined, subscriber);
    });
    certificates.forEach((certificate) => {
      this.certificates.createCertificateCard(undefined, certificate);
    });
  }

  getNameById(id: number, type: InformationType): string {
    return this[type].find((item) => item.id === id)?.name || '';
  }

  getIds(type: InformationType): number[] {
    return this[type].map((item) => item.id);
  }

  get stagesDataApi(): StageApi[] {
    return this.phases.phases.map((phase) => ({
      name: phase.inputPhase.value,
      formula: phase.inputFormula.value,
    }));
  }

  get certificatesDataApi(): CertificateApi[] {
    return this.certificates.certificates.reduce((filtered: CertificateApi[], certificate) => {
      const { selectCertificateType, inputNumericMinCondition, inputNumericMaxCondition, templateId } = certificate;

      if (selectCertificateType.selectedId) {
        const newValue = {
          startScore: Number(inputNumericMinCondition.value),
          endScore: Number(inputNumericMaxCondition.value),
          type: selectCertificateType.selectedId,
          templateId,
        };
        filtered.push(newValue);
      }
      return filtered;
    }, []);
  }

  get subscribersDataApi(): SubscriberApi[] {
    return this.signers.signers.map((signer) => ({
      fio: signer.inputName.value,
      post: signer.inputPosition.value,
    }));
  }

  get isGlobalEventLoaded(): boolean {
    return this.status === Status.Fulfilled;
  }

  get organizerApi(): OrganizerApi | null {
    const departmentId = this.departmentSelect.selectId;
    const departmentType = this.departmentTypeSelect.selectedId;

    if (departmentId && departmentType) {
      return {
        id: departmentId,
        type: { id: departmentType },
      };
    }
    return null;
  }

  createdData(id?: number): CreateMetaEventType | null {
    const {
      inputGlobalEventName, dateIntervalActivePhase: { startDate, endDate },
      selectGlobalEventType, selectCompetitionType, selectDirectionType,
      subscribersDataApi, certificatesDataApi, stagesDataApi, organizerApi, allStagesCheckbox, withCheckProfileCheckbox
    } = this;

    if (startDate && endDate && selectGlobalEventType.selectId && organizerApi
      && selectCompetitionType.selectId && selectDirectionType.selectId) {
      return {
        id,
        name: inputGlobalEventName.value,
        activePhaseEnd: parseISODateFormat(endDate),
        activePhaseStart: parseISODateFormat(startDate),
        allStages: allStagesCheckbox.value,
        globalEvent: { id: selectGlobalEventType.selectId },
        competition: { id: selectCompetitionType.selectId },
        direction: { id: selectDirectionType.selectId },
        subscribers: subscribersDataApi,
        certificates: certificatesDataApi,
        stages: stagesDataApi,
        organizer: organizerApi,
        withCheckProfile: withCheckProfileCheckbox.value
      };
    }
    return null;
  }

  get isValidForm(): boolean {
    return Boolean(this.inputGlobalEventName.value.trim() && this.createdData());
  }

  setDepartmentTypeField(id: DepartmentTypesId): void {
    this.departmentSelect.reset();
    this.departmentTypeSelect.setSelectId(id);
  }

  setGlobalEventType(id: number): void {
    this.selectCompetitionType.reset();
    this.selectGlobalEventType.setId(id);
  }
}
