import axios, { AxiosResponse } from 'axios';
import { certificatesApi, userApi } from 'constants/api';
import { CertificatesCollectionType } from 'store/states/certificates/types';
import { CertificateFeedbackForm } from 'store/apiModels/profileType/types';


export class CertificatesService {
  getCertificatesByUserId = async (userId: number): Promise<AxiosResponse<CertificatesCollectionType>> => (
    axios.get(`${certificatesApi}/getByUserId`, { params: { userId } })
  );

  getRejectComment = async (userId: number): Promise<AxiosResponse<string | null>> => (
    axios.get(`${userApi}/getRejectComment`, { params: { userId } })
  );
  
  createFeedback = async (certificateId: number, feedback: CertificateFeedbackForm): Promise<AxiosResponse<any>> => {
    axios.post(`${certificatesApi}/${certificateId}/feedback`, feedback)
  }
}
