import { makeAutoObservable } from 'mobx';
import { DirectionsService } from 'store/apiClients/directions';
import { AxiosResponse } from 'axios';
import { DirectionAndTrendsApi, DirectionsApi, TrendApiExtended } from 'store/apiClients/directions/types';
import { Status } from 'constants/status';
import { Direction, DirectionsType } from './types';


export class DirectionsStore {
  status = Status.Initial;
  directions: DirectionsType = new Map<number, Direction>();
  dirAndTrends: DirectionAndTrendsApi[] = [];
  allTrends: TrendApiExtended[] = [];
  readonly service: DirectionsService;

  get directionsIds(): Array<number> {
    return [...this.directions.keys()];
  }

  get trendIds(): number[] {
    return this.allTrends.map((t) => t.id);
  }

  constructor() {
    makeAutoObservable(this);
    this.service = new DirectionsService();
  }

  getDirectionNameById = (id: number): string => this.directions.get(id)?.name || '';

  groupTrendsByDirName = (trendId: number): string => this.findTrendById(trendId)?.directionName || '';

  getTrendLabel = (trendId: number): string => this.findTrendById(trendId)?.name || '';

  * fetchDirections(): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.service.getAll()) as AxiosResponse<DirectionsApi[]>;
      this.setDirectionsFromApi(response.data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * fetchDirectionAndTrends(directionIds: number[] = []): Generator {
    try {
      this.status = Status.Pending;
      const response = (
        yield this.service.getDirectionAndTrends()
      ) as AxiosResponse<DirectionAndTrendsApi[]>;
      this.dirAndTrends = DirectionsStore.mapDirAndTrends(response.data);
      
      if (directionIds.length) {
        this.dirAndTrends = this.dirAndTrends.filter((dirAndTrend: DirectionAndTrendsApi) => directionIds.includes(dirAndTrend.id));
      }
      
      this.allTrends = this.getAllTrends();
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  setDirectionsFromApi = (data: DirectionsApi[]): void => {
    data.forEach((direction) => {
      this.directions.set(direction.id, {
        name: direction.name,
      });
    });
  };

  filterAllTrends = (trendIds: number[]): void => {
    this.dirAndTrends.forEach((dirAndTrend: DirectionAndTrendsApi) => {
      dirAndTrend.trends = dirAndTrend.trends.filter((trend: TrendApiExtended) => !trendIds.includes(trend.id))
    });
    this.allTrends = this.getAllTrends();
  };
  
  private static mapDirAndTrends(dirs: DirectionAndTrendsApi[]): DirectionAndTrendsApi[] {
    return dirs.map((d) => {
      const trends = d.trends.map((t) => ({
        ...t,
        directionId: d.id,
        directionName: d.name,
      }));

      return {
        ...d,
        trends,
      };
    });
  }

  private getAllTrends(): TrendApiExtended[] {
    return this.dirAndTrends
      .map((dir) => dir.trends)
      .reduce((acc, tt) => acc.concat(tt), []);
  }

  private findTrendById(trendId: number): TrendApiExtended | null {
    return this.allTrends.find((t) => t.id === trendId) || null;
  }
}
