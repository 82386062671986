// импортировать из 'utils/index', иначе тесты падают

import { CollectionType } from 'store/testHelpers/types';


export const Tuple = <T extends unknown[]>(...args: T): Readonly<T> => args;

export function getUniqueItems<T>(array: T[]): T[] {
  return [...new Set(array)];
}

export const safeMockUseState = (init: unknown, mockUseStateValues: [unknown, () => void]): [unknown, () => void] => {
  const className = (init as { constructor: { name: string } })?.constructor?.name;
  if (className === 'ObjectToBeRetainedByReact') return [init, () => {}];
  return mockUseStateValues;
};

export function extractIds<I>(entities: Array<{ id: I }>): I[] {
  return entities.map(({ id }) => id);
}


export const truncate = (str: string, maxLength: number): string => (
  (str.length > maxLength) ? `${str.substr(0, maxLength)}...` : str
);

export const sizeCollection = (collection: CollectionType): number => {
  if (Array.isArray(collection)) return collection.length;
  return collection.size;
};
