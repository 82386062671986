import React, { ReactElement, Suspense, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
// import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { ROUTES_BY_ROLE, ROUTES_COMPONENTS, WITHOUT_NAVBAR } from 'common/routes';
import { ErrorNotification } from 'components/blocks/ErrorNotification';
import { MessageBanner } from 'components/blocks/MessageBanner';
import { NotFound404 } from 'components/pages/NotFound404';
import { trackPageView } from 'utils/analytics';
import { AcceptorPersonalData } from 'components/blocks/AcceptorPersonalData';
import { AcceptorCookies } from './components/blocks/AcceptorCookies';
import { InvalidPhotoModal } from './components/blocks/InvalidPhotoModal';
import { IncompleteProfileModal } from './components/blocks/IncompleteProfileModal';
import { AppProviders } from './providers';
import { Loader } from './components/elements/Loader';
// import { ErrorMessage } from './components/elements/ErrorMessage';
import { getKimLink } from './components/blocks/NavBar/utils';
import { NavBar } from './components/blocks/NavBar';
import styles from './styles.scss';


const AppRootComp = (): ReactElement => {
  const { profileTypeStore } = useGlobalStores();
  const history = useHistory();

  useEffect(() => {
    profileTypeStore.fetchProfileType();
  }, [profileTypeStore]);

  useEffect(() => {
    // отправляем экспертов (роль verifier) в тестирующую
    if (profileTypeStore.profile && profileTypeStore.isVerifier) {
      window.open(getKimLink().to, '_self');
    }
  }, [profileTypeStore.profile]);
  
  useEffect(() => {
    trackPageView(); // To track the first page view upon load
    history.listen(trackPageView); // To track the subsequent page views
  }, [history]);

  return (
    <AppProviders>
      <Switch>
        <Route exact path={WITHOUT_NAVBAR} />
        <Route component={NavBar} />
      </Switch>
      <Suspense fallback={<Loader show />}>
        <div className={styles.root}>
          <Switch>
            {ROUTES_BY_ROLE[profileTypeStore.routesRole].map((path) => {
              const { exact, Component, Provider } = ROUTES_COMPONENTS[path];
        
              if (Provider) {
                return (
                  <Route key={path} exact={exact} path={path}>
                    <Provider>
                      <Component />
                    </Provider>
                  </Route>
                );
              }
              return <Route key={path} exact={exact} path={path} component={Component} />;
            })}
            <Route>
              <NotFound404 />
            </Route>
          </Switch>
        </div>
      </Suspense>
      <MessageBanner />
      <ErrorNotification />
      <AcceptorPersonalData />
      <AcceptorCookies />
      <InvalidPhotoModal />
      <IncompleteProfileModal />
    </AppProviders>
  );
};

export const AppRoot = observer(AppRootComp);
