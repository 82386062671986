import { AxiosResponse } from 'axios';
import { DateFormats } from 'constants/dateFormats';
import { Status } from 'constants/status';
import { format } from 'date-fns';
import { makeAutoObservable } from 'mobx';
import { EventsGetParams, EventsService } from 'store/apiClients/events';
import { EventCardCollectionType } from 'store/apiModels/events/types';
import { StudentStatus } from '../eventsNext/types';
import { CellHighlightStyleNames, CellVariantStyleNames } from './types';
import { addMonth, getCellHighlightStyle, getCellVariantStyle, getGridDates } from './utils';


export class DatePickerState {
  status = Status.Initial;
  dateCurrent = format(new Date(), DateFormats.isoDate);
  dateSelected = '';
  monthDisplayed = this.dateCurrent;
  dates: string[] = getGridDates(this.monthDisplayed);
  datesHighlighted = new Map<string, StudentStatus[]>();
  events: EventCardCollectionType = [];

  private eventsService;

  constructor() {
    makeAutoObservable(this);
    this.eventsService = new EventsService();
  }

  * fetchEvents(params: EventsGetParams = {}): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.eventsService.get(params)) as AxiosResponse<EventCardCollectionType>;
      this.status = Status.Fulfilled;
      this.events = response.data;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }

  setHighlightedDates = (dates: Map<string, StudentStatus[]>): void => {
    this.datesHighlighted.clear();
    this.datesHighlighted = dates;
  };

  getDayStatuses = (cellDate: string): StudentStatus[] => (
    this.datesHighlighted.get(cellDate) || []
  );

  getCellHighlightStyle = (cellDate: string): CellHighlightStyleNames => (
    getCellHighlightStyle(this.datesHighlighted, cellDate)
  );

  getCellVariantStyle = (cellDate: string): CellVariantStyleNames => (
    getCellVariantStyle(this.monthDisplayed, this.dateSelected, cellDate)
  );

  onPrevMonthClick = (): void => {
    this.monthDisplayed = addMonth(this.monthDisplayed, -1);
    this.dates = getGridDates(this.monthDisplayed);
  };
  onNextMonthClick = (): void => {
    this.monthDisplayed = addMonth(this.monthDisplayed, 1);
    this.dates = getGridDates(this.monthDisplayed);
  };

  onDateClick = (date: string): void => {
    const newDateSelected = this.dateSelected === date ? '' : date;
    this.dateSelected = newDateSelected;
  };

  get isLoading(): boolean { return this.status === Status.Pending; }
}
