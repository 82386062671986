export const getPluralString = (
  wordForms: readonly [form1: string, form2to4: string, form5to0: string],
  num: number,
): string => {
  const n = Math.abs(num) % 100;
  const n2 = n % 10;

  if (n > 10 && n < 20) {
    return wordForms[2];
  }
  if (n2 > 1 && n2 < 5) {
    return wordForms[1];
  }
  if (n2 === 1) {
    return wordForms[0];
  }
  return wordForms[2];
};
