import { endOfDay, startOfDay } from 'date-fns';
import { formatDateWithoutTZ } from 'utils/date/formatters';
import { CreateEventDataApiType } from 'store/apiClients/events/types';
import { GetCreateEventParsedFieldsType } from './types';
import { TypeOfEvent } from './utilityClasses/SelectTypeOfEvent/enum';


export const appendHttpIfNeed = (value: string): string => {
  const trimmedValue = value.trim();
  const isHasHttp = trimmedValue.slice(0, 4) === 'http';
  if (!isHasHttp) return `https://${trimmedValue}`;

  return trimmedValue;
};

export const getCreateEventParsedFields = (
  state: GetCreateEventParsedFieldsType,
): CreateEventDataApiType | null => {
  const {
    tagIds, eventId, eventName, isEditable, trendSelectId, attachments, regConditions, donm,
    fullDescription, departmentSelectId, shortDescription, eventEnd, eventStart, regStart, regEnd,
    maxScore, timeslotLink, isOpenRegistrationEvent, eventOrganizations, giveChooseLocation, giveChooseTimeslot, inPresentio,
    eventType, isTeam, teamPartNumber, reserve, reserveStreamNumber
  } = state;

  if (!(trendSelectId && departmentSelectId)) return null;

  const requiredFields: CreateEventDataApiType = {
    donm,
    inPresentio,
    title: eventName,
    giveChooseLocation,
    giveChooseTimeslot,
    fullText: fullDescription,
    shortText: shortDescription,
    eventOrgs: eventOrganizations,
    trends: [{ id: trendSelectId }],
    openReg: isOpenRegistrationEvent,
    partners: [{ id: departmentSelectId }],
    eventEnd: formatDateWithoutTZ(endOfDay(eventEnd)),
    eventStart: formatDateWithoutTZ(startOfDay(eventStart)),
    eventType: eventType as TypeOfEvent,
    isTeam,
    reserve
  };

  return {
    ...requiredFields,
    ...maxScore && { maxScore },
    ...regConditions && { regConditions },
    ...attachments?.length && { attachments },
    ...isEditable && eventId && { id: eventId },
    ...timeslotLink && { openRegLink: timeslotLink },
    ...regEnd && { regEnd: formatDateWithoutTZ(endOfDay(regEnd)) },
    ...regStart && { regStart: formatDateWithoutTZ(startOfDay(regStart)) },
    ...tagIds?.length && { tags: tagIds.map((id) => ({ id })) },
    ...teamPartNumber && { teamPartNumber },
    ...reserveStreamNumber && { reserveStreamNumber }
  };
};
