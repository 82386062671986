import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { makeAutoObservable } from 'mobx';
import { ResultsService } from 'store/apiClients/results';
import { ResultApi } from 'store/apiClients/results/types';
import { ResultParams } from './types';


export class ResultsStore {
  status = Status.Initial;

  result: ResultApi | null = null;
  results: ResultApi[] = [];

  resultsService: ResultsService;

  constructor() {
    makeAutoObservable(this);
    this.resultsService = new ResultsService();
  }

  * fetchResult(params: ResultParams): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.resultsService.getResult(params)) as AxiosResponse<ResultApi>;
      this.status = Status.Fulfilled;
      this.result = response.data;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }

  * fetchResults(userId: number): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.resultsService.getResults(userId)) as AxiosResponse<ResultApi[]>;
      this.status = Status.Fulfilled;
      this.results = response.data;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }
}
