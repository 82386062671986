import { Status } from 'constants/status';
import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';
import { EventUsersService } from 'store/apiClients/eventUsers';
import { EventUsersServiceGetParams } from 'store/apiClients/eventUsers/types';
import { saveAs } from 'file-saver';
import { getFileNameFromResponseHeader } from 'utils/fileWorkers';
import { EventUsersApiModel } from './types';


export class EventUsersStore {
  status = Status.Initial;
  eventUsers = new Map<number, EventUsersApiModel>();
  readonly service = new EventUsersService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get listEventUsers(): EventUsersApiModel[] {
    return [...this.eventUsers.values()];
  }

  get eventUsersIds(): number[] {
    return [...this.eventUsers.keys()];
  }

  get listUserIds(): number[] {
    return this.listEventUsers.map(({ user }) => user.id);
  }

  getEventUserById(id: number): EventUsersApiModel | null {
    return this.eventUsers.get(id) || null;
  }

  * fetchEventUsers(params: EventUsersServiceGetParams): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.service.get(params)) as AxiosResponse<EventUsersApiModel[]>;
      this.resetEventUsers();
      this.setEventUsersFromApi(response.data);
      this.status = Status.Fulfilled;
      return response;
    } catch (e) {
      this.status = Status.Rejected;
      return e.response;
    }
  }

  * fetchExportToXlsx(eventId: number): Generator {
    try {
      const { data, headers } = (yield this.service.exportToXlsx(eventId)) as AxiosResponse;
      const fileName = getFileNameFromResponseHeader(headers, 'зарегистрированные-участники.xls');
      saveAs(data, fileName);
    } catch (e) {
      //
    }
  }

  setEventUsersFromApi(data: EventUsersApiModel[]): void {
    data.forEach((eventUser) => {
      this.eventUsers.set(eventUser.id, eventUser);
    });
  }

  resetEventUsers(): void {
    this.eventUsers.clear();
  }

  get isLoading(): boolean {
    return this.status === Status.Pending;
  }
}
