type RegexRuleTypes = 'nonNumericValueWithPlusPrefix'
| 'nonNumeric'
| 'nonNumericValueWithCommas'
| 'fractionalNumeric';

export const REGEX_RULES: Record<RegexRuleTypes, RegExp> = {
  nonNumeric: /\D/g,
  nonNumericValueWithCommas: /[^\d,]/g,
  nonNumericValueWithPlusPrefix: /[^+\d]/g,
  fractionalNumeric: /^\d+(\.\d{0,})$/g
};
