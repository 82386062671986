import { makeAutoObservable } from 'mobx';
import { DistributeValueType, Distribute } from 'store/states/eventCreateGroups/types';
import { EventGroupsStore } from 'store/apiModels/eventGroups';
import { globalStore } from 'store/contexts';


export class EventCreateGroupsState {
  step = 0;
  distribute: Distribute | '' = '';

  private readonly eventGroupsStore: EventGroupsStore = globalStore.eventGroupsStore;

  constructor() {
    makeAutoObservable(this);
  }

  setStep = (step: number): void => {
    this.step = step;
  };

  setDistribute = (distribute: Distribute | ''): void => {
    this.distribute = distribute;
  };

  makeDistribution = (eventId: number, { count, reserved }: DistributeValueType): void => {
    if (this.distribute === '') return;
    this.eventGroupsStore.createGroups(this.distribute, { id: eventId, count, reserved });
  };
}
