import { UserService } from 'store/apiClients/user';
import { ProfileStatuses } from 'common/profile/enums';
import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { UpdateParams, UpdateProfileParams } from 'store/apiClients/user/types';
import { OperatorType, ProfileWithUser, User } from './types';


export class UserStore {
  private userService: UserService;

  operators: OperatorType[] | null = null;
  users = new Map<number, User>();
  statusesById = new Map<number, Status>();

  constructor() {
    this.userService = new UserService();
    makeAutoObservable(this);
  }

  getUserById = (userId: number): User | null => this.users.get(userId) || null;

  * fetchUserById(userId: number): Generator {
    try {
      this.statusesById.set(userId, Status.Pending);
      const { data } = (yield this.userService.getUserById(userId)) as AxiosResponse<User>;
      this.setUsersFromApi([data]);
      this.statusesById.delete(userId);
    } catch (e) {
      this.statusesById.set(userId, Status.Rejected);
    }
  }

  * fetchOperators(status: ProfileStatuses): Generator<unknown, AxiosResponse> {
    try {
      const response = (yield this.userService.getOperators(status)) as AxiosResponse<OperatorType[]>;
      this.operators = response.data;
      return response;
    } catch (e) {
      throw new Error(e);
    }
  }

  * changeStatus(userId: number, status: ProfileStatuses): Generator {
    try {
      yield this.userService.changeStatus(userId, status);
      yield this.fetchOperators(ProfileStatuses.waiting);
    } catch (e) {
      throw new Error(e);
    }
  }

  * fetchUpdateProfile(profile: UpdateProfileParams): Generator<unknown, AxiosResponse<ProfileWithUser> | boolean> {
    try {
      return (yield this.userService.updateProfile(profile)) as AxiosResponse<ProfileWithUser>;
    } catch (e) {
      return false;
    }
  }

  * updateUserData(params: UpdateParams): Generator<unknown, AxiosResponse> {
    try {
      return (yield this.userService.update(params)) as AxiosResponse;
    } catch (e) {
      throw new Error(e);
    }
  }

  private setUsersFromApi(apiData: User[]): void {
    apiData.forEach((user) => {
      this.users.set(user.id, user);
    });
  }
}
