import axios, { AxiosResponse } from 'axios';
import { locationsApi } from 'constants/api';
import { CreateLocationApi, FiltersLocationApi, LocationApi, UpdateLocationApi } from './types';


export class LocationsService {
  getByEvent = async (eventId: number): Promise<AxiosResponse<LocationApi[]>> => (
    axios.get(`${locationsApi}/by_event`, { params: { event_id: eventId } })
  );

  create = async (data: CreateLocationApi): Promise<AxiosResponse<number | null>> => (
    axios.post(locationsApi, data)
  );

  get = async ({ locationAddress, orgId, page = 1 }: FiltersLocationApi): Promise<AxiosResponse<LocationApi[]>> => (
    axios.get(locationsApi, {
      params: {
        address: locationAddress,
        org_id: orgId,
        per_page: 100,
        page,
      },
    })
  );

  delete = async (id: number): Promise<AxiosResponse> => (
    axios.delete(`${locationsApi}/${id}`)
  );

  update = async (data: UpdateLocationApi): Promise<AxiosResponse> => (
    axios.put(locationsApi, data)
  );

  getAllByOrg = async (orgId: number): Promise<AxiosResponse<LocationApi[]>> => (
    axios.get(`${locationsApi}`, { params: { org_id: orgId } })
  );
}
