import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';
import { Pagination } from 'store/utilityClasses/Pagination';
import { Status } from 'constants/status';
import {
  CreateTeamProps,
  Participant,
  ParticipantBase,
  ParticipantsProps,
  Team,
  TeamsListProps,
  TeamsListPropsOptional
} from 'store/apiClients/teams/types';
import { TeamsService } from 'store/apiClients/teams';

export class TeamsStore {
  status = Status.Initial;
  pagination = new Pagination();
  participantsPagination = new Pagination();
  originalParticipants: Participant[] = [];
  participants: ParticipantBase[] = [];
  teams: Team[] = [];
  createdTeamId: number | null = null;

  readonly service = new TeamsService();

  get isLoading(): boolean {
    return this.status === Status.Pending;
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * fetchTeams(props: TeamsListPropsOptional = {}): Generator {
    try {
      this.status = Status.Pending;

      const params: TeamsListProps = {
        page: this.pagination.page,
        per_page: 250
      };

      const { data, headers } = (yield this.service.getList({...params, ...props })) as AxiosResponse<Team[]>;
      this.pagination.setPages(headers);
      this.teams = data;
      this.status = Status.Fulfilled;
    } catch(err) {
      this.status = Status.Rejected;
      console.error(err);
    }
  }

  * fetchCreateTeam(team: CreateTeamProps): Generator {
    try {
      this.status = Status.Pending;
      
      const { data } = yield this.service.create(team) as AxiosResponse;
      this.createdTeamId = data as number;
  
	  this.status = Status.Fulfilled;
    } catch(err) {
      this.status = Status.Rejected;
      console.error(err);
    }
  }

  * fetchUpdateTeam(team: Team): Generator {
    try {
      this.status = Status.Pending;
      
      yield this.service.update(team);
      
      this.status = Status.Fulfilled;
    } catch(err) {
      this.status = Status.Rejected;
      console.error(err);
    }
  }

  * fetchDeleteTeam(teamId: number): Generator {
    try {
      this.status = Status.Pending;
      
      yield this.service.delete(teamId);
      
      this.status = Status.Fulfilled;
    } catch(err) {
      this.status = Status.Rejected;
      console.error(err);
    }
  }
  
  * fetchParticipants(props: ParticipantsProps): Generator {
    try {
      this.status = Status.Pending;
      
      const params: ParticipantsProps = {
        ...props,
        page: this.participantsPagination.page,
        perPage: 100
      };
      
      const { data, headers } = (yield this.service.getParticipants(params)) as AxiosResponse<Participant[]>;
  
      this.participantsPagination.setPages(headers);
      this.originalParticipants = data;
      this.participants = data.map(({id, fio}: Participant) => ({ userId: id, fio}));
      this.status = Status.Fulfilled;
    } catch (err) {
      this.status = Status.Rejected;
      console.error(err);
    }
  }
}
