import { makeAutoObservable } from 'mobx';
import { Switch } from 'store/utilityClasses/Switch';
import { Position } from 'store/states/certificateConstructor/types';
import { DEFAULT_QR_CODE_POSITION } from 'store/states/certificateConstructor/constants';


export class QRCodeConstructor {
  switch = new Switch(true);
  position: Position = DEFAULT_QR_CODE_POSITION;

  constructor(defaultPosition: Partial<Position> = DEFAULT_QR_CODE_POSITION) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.setPosition(defaultPosition);
  }

  setPosition(position: Partial<Position>): void {
    this.position = { ...this.position, ...position };
  }

  isValidPosition(imgRelHeight: number): boolean {
    return (this.position.y + this.position.height) <= imgRelHeight;
  }
}
