import { makeAutoObservable } from 'mobx';
import { Counter } from 'store/utilityClasses/Counter/Counter';


export class Time {
  hours: Counter;
  minutes: Counter;

  constructor() {
    this.hours = new Counter({ maxValue: 23, minValue: 0 });
    this.minutes = new Counter({ maxValue: 59, minValue: 0 });
    makeAutoObservable(this);
  }

  reset(): void {
    this.hours.onChange(0);
    this.minutes.onChange(0);
  }
}
