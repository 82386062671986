import { makeAutoObservable } from 'mobx';
import { BannerService } from 'store/apiClients/banner';
import { AxiosResponse } from 'axios';


export class BannerStore {
  message = '';

  readonly bannerService = new BannerService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setMessage(message: string): void {
    this.message = message;
  }

  * fetchMessage(): Generator {
    try {
      const response = (yield this.bannerService.get()) as AxiosResponse<string>;
      this.message = response.data;
    } catch (e) {
      //
    }
  }
}
