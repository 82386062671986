import { makeAutoObservable } from 'mobx';
import { RegConditionCompareMarks } from './enums';


export class SelectCompareMark {
  selectedCompareMark: RegConditionCompareMarks | null = null;

  constructor(mark: RegConditionCompareMarks | null = null) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.setSelectCompareMark(mark);
  }

  setSelectCompareMark(type: RegConditionCompareMarks | null): void {
    this.selectedCompareMark = type;
  }

  reset(): void {
    this.selectedCompareMark = null;
  }
}
