import { makeAutoObservable } from 'mobx';


export class SelectParallels {
  selectedParallels: Set<number> = new Set<number>();

  constructor(ids: Set<number> = new Set<number>()) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.setSelectedParallels(ids);
  }

  private setSelectedParallels(ids: Set<number>): void {
    this.selectedParallels = ids;
  }

  updateSelectParallels(id: number): void {
    const isExist = this.isParallelExistById(id);

    if (isExist) {
      this.selectedParallels.delete(id);
    } else {
      this.selectedParallels.add(id);
    }
  }

  isParallelExistById(id: number): boolean {
    return this.selectedParallels.has(id);
  }

  get parallelsAsArray(): number[] {
    return [...this.selectedParallels.values()];
  }

  reset(): void {
    this.selectedParallels.clear();
  }

  get stringifyParallels(): string {
    return this.parallelsAsArray.toString();
  }
}
