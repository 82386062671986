import { makeAutoObservable } from 'mobx';
import { isNumberInRange } from 'utils/isNumberInRange';


interface LimitsType {
  minValue: number
  maxValue: number
}

type SetLimitProps = LimitsType;

export type CounterConstructorProps = LimitsType;

export class Counter {
  value = 0;
  maxValue = 0;
  minValue = 0;

  constructor({ minValue, maxValue }: CounterConstructorProps) {
    this.setLimit({ minValue, maxValue });
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onChange(value: number): void {
    if (isNumberInRange({ number: value, startRange: this.minValue, endRange: this.maxValue })) {
      this.value = value;
    }
  }

  increment(): void {
    const newValue = this.value + 1;
    if (newValue <= this.maxValue) this.value = newValue;
  }

  decrement(): void {
    const newValue = this.value - 1;
    if (newValue >= this.minValue) this.value = newValue;
  }

  setLimit({ minValue, maxValue }: SetLimitProps): void {
    this.setMinValue(minValue);
    this.setMaxValue(maxValue);
  }

  private setMaxValue(maxValue: number): void {
    this.maxValue = maxValue;
    if (maxValue < this.value) this.value = maxValue;
  }
  private setMinValue(minValue: number): void {
    this.minValue = minValue;
    if (minValue > this.value) this.value = minValue;
  }
}
