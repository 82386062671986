import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { Loader } from 'components/elements/Loader';
import { Status } from 'constants/status';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { getRedirectForRegistration } from './utils';


const RegComponent = (): ReactElement => {
  const { profileTypeStore } = useGlobalStores();

  if (profileTypeStore.status === Status.Fulfilled && profileTypeStore.profile) {
    return (
      <Redirect
        to={getRedirectForRegistration(
          profileTypeStore.isRegistered,
          Boolean(profileTypeStore.email),
        )}
      />
    );
  }

  return <Loader show />;
};

export default observer(RegComponent);
export const Reg = observer(RegComponent);
