import { makeAutoObservable } from 'mobx';
import { GradesService } from 'store/apiClients/grades';
import { GradeModelApi } from 'store/apiClients/grades/types';
import { Status } from 'constants/status';
import { AxiosResponse } from 'axios';

import { getParallelByGradeName } from './utils';
import { GradesArrayWithParallelType } from './types';


export class GradesStore {
  private readonly gradesService = new GradesService();
  private readonly additionalGrages: GradeModelApi[] = [
    { id: 12, name: '1 курс' },
    { id: 13, name: '2 курс' },
    { id: 14, name: '3 курс' },
    { id: 15, name: '4 курс' },
    { id: 16, name: '5 курс' },
  ];
  
  status = Status.Initial;

  grades = new Map<number, GradeModelApi>();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get gradesIds(): number[] {
	return [...this.grades.keys()];
  }

  get allGradesIds(): number[] {
    return [...this.gradesIds, ...this.additionalGrages.map(({id}: GradeModelApi) => id)]
  }

  getGradeByIdFromAllGrades(id: number) {
    const foundGrade = [...this.gradesArray, ...this.additionalGrages].find((grade: GradeModelApi) => grade.id === id);

  	return foundGrade ? foundGrade.name : '';
  }
  
  getGradeById(id: number): GradeModelApi | null {
    return this.grades.get(id) || null;
  }

  getGradeNameById(id: number): string {
    return this.getGradeById(id)?.name || '';
  }

  get gradesArray(): GradeModelApi[] {
    return [...this.grades.values()];
  }

  get gradesArrayWithParallel(): GradesArrayWithParallelType[] {
    return this.gradesArray.map(({ id, name }) => ({
      id,
      name,
      parallel: getParallelByGradeName(name),
    }));
  }

  get allGradesArray(): GradesArrayWithParallelType[] {
    return [...this.gradesArray, ...this.additionalGrages].map(({id, name}) => ({
      id,
      name,
      parallel: id < 12 ? getParallelByGradeName(name) : name
    }));
  }
  
  * fetchGrades(): Generator {
    try {
      this.status = Status.Pending;
      const { data } = (yield this.gradesService.get()) as AxiosResponse<GradeModelApi[]>;
      this.setGradesFromApi(data);
      this.status = Status.Fulfilled;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }

  setGradesFromApi(apiData: GradeModelApi[]): void {
    apiData.forEach((grade) => {
      this.grades.set(grade.id, grade);
    });
  }
}
