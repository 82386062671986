import { createContext } from 'react';
import { EventsFiltersState } from 'store/states/eventsFilters';
import { RegistrationOfOperatorState } from 'store/states/registrationOfOperator';
import { OurStudentsState } from 'store/states/ourStudents';
import { RegistrationState } from 'store/states/registration';
import { RegisteredMembersState } from 'store/states/registeredMembers';
import { RegisteredMembersFiltersState } from 'store/states/registeredMembersFilters';
import { ProfileState } from 'store/states/profile';
import { EventManageGroupsState } from 'store/states/eventManageGroups';
import { EventCreateGroupsState } from 'store/states/eventCreateGroups';
import { DatePickerState } from 'store/states/datePicker';
import { CreateEventState } from 'store/states/createEvent';
import { EventsNextState } from 'store/states/eventsNext';
import { ResultEditState } from 'store/states/resultEdit';
import { EventResults } from 'store/apiModels/eventResults';
import { CertificatesState } from 'store/states/certificates';
import { LoadCertificate } from 'store/states/loadCertificate';
import { PersonalDataState } from 'store/states/personalData';
import { DirectoriesState } from 'store/states/directories';
import { LocationsState } from 'store/states/locations';
import { CreateLocationState } from 'store/states/createLocation';
import { CreateGlobalEventState } from 'store/states/createGlobalEvent';
import { CertificateConstructorState } from 'store/states/certificateConstructor';
import { GlobalEventsFiltersState } from 'store/states/globalEventsFilters';
import { EventRegistrationButtonState } from 'store/states/eventRegistrationButton';
import { EventResultFiltersState } from 'store/states/eventResultFilters';
import { StudentsPhotosState } from 'store/states/studentsPhotos';
import { MyEventsState } from 'store/states/myEvents';
import { CombineEventsState } from 'store/states/combineEvents';
import { CreateCombineEventState } from 'store/states/createCombineEvent';


export interface EventsFiltersStateType { eventsFiltersState: EventsFiltersState }
export const EventsFiltersContext = createContext<EventsFiltersStateType | null>(null);

export interface RegistrationOfOperatorStoreType { registrationOfOperatorState: RegistrationOfOperatorState }
export const RegistrationOfOperatorContext = createContext<RegistrationOfOperatorStoreType | null>(null);

export interface OurStudentsStateType {
  ourStudentsState: OurStudentsState,
}
export const OurStudentsContext = createContext<OurStudentsStateType | null>(null);

export interface StudentsPhotosStateType {
  studentsPhotosState: StudentsPhotosState
}
export const StudentsPhotosContext = createContext<StudentsPhotosStateType | null>(null);

export interface MyEventsStateType { myEventsState: MyEventsState };
export const MyEventsContext = createContext<MyEventsStateType | null>(null);

export interface RegistrationStoreType { registrationState: RegistrationState }
export const RegistrationContext = createContext<RegistrationStoreType | null>(null);

export interface RegisteredMembersStoreType {
  registeredMembersState: RegisteredMembersState,
  registeredMembersFiltersState: RegisteredMembersFiltersState,
}
export const RegisteredMembersContext = createContext<RegisteredMembersStoreType | null>(null);

export interface ProfileStore { profileState: ProfileState }
export const ProfileContext = createContext<ProfileStore | null>(null);

export interface EventManageGroupsStoreType { eventManageGroupsState: EventManageGroupsState }
export const ManageGroupsContext = createContext<EventManageGroupsStoreType | null>(null);

export interface EventCreateGroupsStoreType { eventCreateGroupsState: EventCreateGroupsState }
export const CreateGroupsContext = createContext<EventCreateGroupsStoreType | null>(null);

export interface DatePickerStoreType { datePickerState: DatePickerState }
export const DatePickerContext = createContext<DatePickerStoreType | null>(null);

export interface CreateEventStoreType { createEventState: CreateEventState }
export const CreateEventContext = createContext<CreateEventStoreType | null>(null);

export interface ColumnEventNextType { eventsNextState: EventsNextState }
export const ColumnEventNextContext = createContext<ColumnEventNextType | null>(null);

export interface EventResultsStoreType {
  eventResults: EventResults,
  eventResultFilters: EventResultFiltersState
}
export const EventResultsContext = createContext<EventResultsStoreType | null>(null);

export interface ResultEditStoreType { resultEditState: ResultEditState }
export const ResultEditContext = createContext<ResultEditStoreType | null>(null);

export interface LoadCertificateType { loadCertificateState: LoadCertificate }
export const LoadCertificateContext = createContext<LoadCertificateType | null>(null);

export interface CertificateStoreType { certificatesState: CertificatesState }
export const CertificateContext = createContext<CertificateStoreType | null>(null);

export interface PersonalDataStoreType { personalDataState: PersonalDataState }
export const PersonalDataContext = createContext<PersonalDataStoreType | null>(null);

export interface DirectoriesStoreType { directoriesState: DirectoriesState }
export const DirectoriesContext = createContext<DirectoriesStoreType | null>(null);

export interface CombineEventsStoreType { combineEventsState: CombineEventsState }
export const CombineEventsContext = createContext<CombineEventsStoreType | null>(null);

export interface CreateCombineEventStoreType { createCombineEventState: CreateCombineEventState }
export const CreateCombineEventContext = createContext<CreateCombineEventStoreType | null>(null);

export interface LocationsStoreType { locationsState: LocationsState }
export const LocationsContext = createContext<LocationsStoreType | null>(null);

export interface CreateLocationStoreType { createLocationState: CreateLocationState }
export const CreateLocationContext = createContext<CreateLocationStoreType | null>(null);

export interface CreateGlobalEventStoreType { createGlobalEventState: CreateGlobalEventState }
export const CreateGlobalEventContext = createContext<CreateGlobalEventStoreType | null>(null);

export interface CertificateConstructorStoreType { certificateConstructorState: CertificateConstructorState }
export const CertificateConstructorContext = createContext<CertificateConstructorStoreType | null>(null);

export interface GlobalEventsFiltersStoreType { globalEventsFiltersState: GlobalEventsFiltersState }
export const GlobalEventsFiltersContext = createContext<GlobalEventsFiltersStoreType | null>(null);

export interface EventRegistrationButtonStoreType { eventRegistrationButtonState: EventRegistrationButtonState }
export const EventRegistrationButtonContext = createContext<EventRegistrationButtonStoreType | null>(null);

