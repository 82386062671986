import { makeAutoObservable } from 'mobx';
import { RegConditionProfileTypes } from 'store/states/createEvent/utilityClasses/SelectProfileType/types';


export class SelectProfileType {
  selectedType: RegConditionProfileTypes | null = null;

  constructor(type: RegConditionProfileTypes | null = null) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.setSelectType(type);
  }

  setSelectType(type: RegConditionProfileTypes | null): void {
    this.selectedType = type;
  }

  reset(): void {
    this.selectedType = null;
  }
}
