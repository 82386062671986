import { makeAutoObservable } from 'mobx';
import { Status } from 'constants/status';
import { Pagination } from 'store/utilityClasses/Pagination';

export class MyEventsState {
  status = Status.Initial;
  pagination: Pagination;

  get isLoading(): boolean {
    return this.status === Status.Pending;
  }

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true});
  }
}