import { AxiosError } from 'axios';
import { globalStore } from 'store/contexts';


type ErrorResponse = {
  code: number,
  message: string,
  errorText: string,
};

export const errorInterceptor = (error: AxiosError): Promise<AxiosError<ErrorResponse>> => {
  const errorMessage = error.response?.data.message || error.response?.data.errorText;
  if (errorMessage) {
    globalStore.errorStore.setError(errorMessage);
  }
  return Promise.reject(error);
};
