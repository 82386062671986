import { DepartmentTypesId } from 'common/departmens/enums';


export const RECORD_DEPARTMENT_CYRILLIC_NAMES: Record<DepartmentTypesId, string> = {
  [DepartmentTypesId.school]: 'Школа',
  [DepartmentTypesId.university]: 'ВУЗ',
  [DepartmentTypesId.jurisdiction]: 'ДОНМ/ЦГУ',
};

export const RECORD_DEPARTMENT_LATIN_NAMES: Record<DepartmentTypesId, keyof typeof DepartmentTypesId> = {
  [DepartmentTypesId.school]: 'school',
  [DepartmentTypesId.university]: 'university',
  [DepartmentTypesId.jurisdiction]: 'jurisdiction',
};
