import { isBefore } from 'date-fns';
import { ChangeEvent } from 'react';
import { makeAutoObservable } from 'mobx';
import { Status } from 'constants/status';
import { globalStore } from 'store/contexts';
import { Routes } from 'common/routes/enums';
import { generatePath } from 'react-router-dom';
import { Switch } from 'store/utilityClasses/Switch';
import { Input } from 'store/utilityClasses/Input/Input';
import { DepartmentTypesId } from 'common/departmens/enums';
import { AttachmentsStore } from 'store/apiModels/attachments';
import { InputNumeric } from 'store/utilityClasses/InputNumeric';
import { normalizeToTwoDigits } from 'utils/normalizeToTwoDigits';
import { SingleSelect } from 'store/utilityClasses/Select/SingleSelect';
import { DateInterval } from 'store/utilityClasses/DateInterval/DateInterval';

import { DepartmentTypeSelect } from 'store/utilityClasses/DepartmentTypeSelect';
import { CreateEventFormInputFields } from './enums';
import { SelectTags } from './utilityClasses/SelectTags';
import { appendHttpIfNeed, getCreateEventParsedFields } from './utils';
import { RegistrationConditions } from './utilityClasses/RegistrationConditions';
import { SelectEventType } from './utilityClasses/SelectEventType';
import { EventLocation } from './utilityClasses/EventLocation';
import { EventType } from './utilityClasses/SelectEventType/enums';
import { SelectTypeOfEventClass } from './utilityClasses/SelectTypeOfEvent';
import { TypeOfEvent } from './utilityClasses/SelectTypeOfEvent/enum';
import { SubscribeStatus } from 'store/apiModels/events/types';


export class CreateEventState {
  isEditable: boolean;

  currentStep = 0;
  readonly stepsCount = 3;

  // first step
  departmentSelect = new SingleSelect();
  departmentTypeSelect = new DepartmentTypeSelect();
  readonly departmentsStore = globalStore.departmentsStore;
  // тип события (не путать с форматом проведения)
  typeOfEventSelect = new SelectTypeOfEventClass();

  // second step
  trendSelect = new SingleSelect();
  directionSelect = new SingleSelect();
  readonly trendsStore = globalStore.trendsStore;

  // third step
  readonly tagsStore = globalStore.tagsStore;
  readonly gradesStore = globalStore.gradesStore;
  readonly eventsStore = globalStore.eventsStore;
  readonly attachmentsStore = new AttachmentsStore();

  eventName = '';
  fullDescription = '';
  shortDescription = '';

  dateIntervalEvent = new DateInterval();
  dateIntervalRegistration = new DateInterval();

  selectTags = new SelectTags();
  inputAttachLink = new Input();
  inputTimeslotLink = new Input();
  inputMaxScore = new InputNumeric();
  switchDonm = new Switch();
  regConditions = new RegistrationConditions();

  // формат проведения
  selectEventType = new SelectEventType();
  eventLocation = new EventLocation();

  switchOpenRegistrationEvent = new Switch();
  switchTeamEvent = new Switch();
  switchReserveEvent = new Switch();
  teamPartNumber = new InputNumeric();
  reserveStreamNumber = new InputNumeric();

  isShowSelectTagsMode = false;
  isShowAttachLinkMode = false;

  redirectUrl = '';

  subscribeStatus: SubscribeStatus | null = null;

  constructor(isEditable = false) {
    this.isEditable = isEditable;
    makeAutoObservable(this, {}, { autoBind: true });
    this.tagsStore.fetchTags();
    this.gradesStore.fetchGrades();
  }

  * loadEventById(id: number): Generator {
    try {
      yield this.eventsStore.setEvent(id);
      this.setEventFromApi();
    } catch (e) {
      // do nothing
    }
  }

  setEventFromApi(): void {
    const { event } = this.eventsStore;
    if (!event) return;

    const {
      eventStart, eventEnd, regStart, regEnd, trends, partners, direction,
      title, fullText, shortText, maxScore, regConditions, tags, attachments,
      openReg, openRegLink, eventOrgs, inPresentio, giveChooseLocation, giveChooseTimeslot, donm,
      eventType, isTeam, teamPartNumber, subscribeStatus, reserve, reserveStreamNumber
    } = event;

    const trend = trends[0];
    const partner = partners[0];

    this.trendSelect.setId(trend.id);
    this.departmentSelect.setId(partner.id);
    this.typeOfEventSelect.setType(eventType);
    this.directionSelect.setId(direction.id);
    this.departmentTypeSelect.setSelectId(partner.partnerTypeId);
    this.eventName = title;
    this.fullDescription = fullText;
    this.shortDescription = shortText;
    this.dateIntervalEvent.onChange({ startDate: new Date(eventStart), endDate: new Date(eventEnd) });
    this.dateIntervalRegistration.onChange({ startDate: new Date(regStart), endDate: new Date(regEnd) });
    this.switchOpenRegistrationEvent.setChecked(openReg);
    attachments?.forEach((attachment) => (
      this.attachmentsStore.pushAttachmentFromApi(attachment)
    ));
    this.selectEventType.setEventType(inPresentio ? EventType.fullTime : EventType.distance);
    this.eventLocation.fromApi(giveChooseLocation, giveChooseTimeslot, eventOrgs);
    this.switchDonm.setChecked(donm);
    this.switchTeamEvent.setChecked(isTeam);
    this.teamPartNumber.setValue(String(teamPartNumber));
    this.switchReserveEvent.setChecked(reserve);
    this.reserveStreamNumber.setValue(String(reserveStreamNumber));

    if (regConditions) this.regConditions.setFormsFromApi(regConditions);
    if (tags.length) this.selectTags.setSelectedTagsFromApi(tags);
    if (maxScore) this.inputMaxScore.setValue(String(maxScore));
    if (openRegLink) this.inputTimeslotLink.setValue(openRegLink);
    if (subscribeStatus) this.subscribeStatus = subscribeStatus;
  }

  setStepToNext(): void {
    this.currentStep += 1;
  }

  setDepartmentTypeField(id: DepartmentTypesId): void {
    this.departmentSelect.reset();
    this.departmentTypeSelect.setSelectId(id);
  }

  setDirection(id: number): void {
    this.trendSelect.reset();
    this.directionSelect.setId(id);
  }

  get isShowTrendSelect(): boolean {
    const { directionSelect: { selectId }, trendsStore: { isTrendsWasLoaded } } = this;
    return Boolean(selectId) && isTrendsWasLoaded;
  }

  get isShowDepartmentSelect(): boolean {
    const { departmentTypeSelect: { selectedId }, departmentsStore } = this;
    return Boolean(selectedId) && departmentsStore.status === Status.Fulfilled;
  }

  get isFirstStepValid(): boolean {
    const { departmentTypeSelect, departmentSelect, typeOfEventSelect } = this;
    return Boolean(departmentTypeSelect.selectedId && departmentSelect.selectId && typeOfEventSelect.type);
  }

  get isSecondStepValid(): boolean {
    const { directionSelect, trendSelect } = this;
    return Boolean(directionSelect.selectId && trendSelect.selectId);
  }

  get isThirdStepValid(): boolean {
    const {
      isRequiredInputsHaveValues, isThirdStepWithOpenRegValid, isAttachmentsLoading,
      isValidDate, switchOpenRegistrationEvent, isEventLocationsValid,
    } = this;
    const isOpenRegistrationEventMode = switchOpenRegistrationEvent.checked;

    return isOpenRegistrationEventMode
      ? isThirdStepWithOpenRegValid
      : (isRequiredInputsHaveValues && !isAttachmentsLoading && isValidDate && isEventLocationsValid);
  }

  get isAttachmentsLoading(): boolean {
    return this.attachmentsStore.isLoading;
  }

  get isThirdStepWithOpenRegValid(): boolean {
    const {
      inputTimeslotLink,
      isAttachmentsLoading,
      isEventPeriodDateValid,
      isRequiredInputsHaveValues,
    } = this;
    const timeslotLinkHasValue = Boolean(inputTimeslotLink.value.trim());

    return isRequiredInputsHaveValues && timeslotLinkHasValue && !isAttachmentsLoading && isEventPeriodDateValid;
  }

  get isEventLocationsValid(): boolean {
    const { eventLocation, selectEventType } = this;
    const { isFullTime, isDistance } = selectEventType;
    return isDistance || (isFullTime && eventLocation.isValid);
  }

  get isRequiredInputsHaveValues(): boolean {
    const { eventName, shortDescription, fullDescription } = this;
    return Boolean(eventName.trim() && shortDescription.trim() && fullDescription.trim());
  }

  get isValidDate(): boolean {
    const { dateIntervalRegistration, dateIntervalEvent } = this;
    if (!(dateIntervalRegistration.endDate && dateIntervalEvent.startDate)) return false;

    return isBefore(dateIntervalRegistration.endDate, dateIntervalEvent.startDate);
  }

  get isEventPeriodDateValid(): boolean {
    const { dateIntervalEvent: { startDate, endDate } } = this;
    return Boolean(startDate && endDate);
  }

  get isAllFormsWasFilled(): boolean {
    const { isFirstStepValid, isSecondStepValid, isThirdStepValid } = this;
    return Boolean(isFirstStepValid && isSecondStepValid && isThirdStepValid);
  }

  changeShowSelectTagsMode(): void {
    this.isShowSelectTagsMode = !this.isShowSelectTagsMode;
  }

  setShowAttachLinkMode(): void {
    this.isShowAttachLinkMode = true;
  }

  setFieldValueFromEvent = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const { value, name } = event.target;
    this[name as CreateEventFormInputFields] = value;
  };

  setEventTypeAndResetLocationsForms(eventType: EventType | null = null): void {
    this.selectEventType.setEventType(eventType);
    this.eventLocation.reset();
  }

  uploadAttachmentLink(): void {
    const { attachmentsStore, inputAttachLink } = this;
    if (!inputAttachLink.value.trim()) return;

    attachmentsStore.uploadAttachment(appendHttpIfNeed(inputAttachLink.value));
    inputAttachLink.clear();
  }

  deleteAttachment(id: number): void {
    this.attachmentsStore.deleteAttachmentFromStore(id);
  }

  onChangeMaxScore(e: ChangeEvent<HTMLTextAreaElement>): void {
    const { value } = e.target;
    const normalizedDigitValue = normalizeToTwoDigits(value, 99);

    this.inputMaxScore.setValue(normalizedDigitValue ? String(normalizedDigitValue) : '');
  }

  onToggleSwitchOpenReg(): void {
    const { switchOpenRegistrationEvent, inputTimeslotLink, dateIntervalRegistration } = this;
    inputTimeslotLink.clear();
    dateIntervalRegistration.endDate = null;
    dateIntervalRegistration.startDate = null;
    switchOpenRegistrationEvent.toggleSwitch();
  }

  onToggleTeamEventType(): void {
    const { switchTeamEvent, teamPartNumber } = this;
    teamPartNumber.clear();
    switchTeamEvent.toggleSwitch();
  }
  
  onToggleReserveEvent(): void {
    const { switchReserveEvent, reserveStreamNumber } = this;
    reserveStreamNumber.clear();
    switchReserveEvent.toggleSwitch();
  }

  onChangeTeamPartNumber(e: ChangeEvent<HTMLInputElement>): void {
    const { value } = e.target;
    const normalizedDigitValue = normalizeToTwoDigits(value, 99);
  
    this.teamPartNumber.setValue(normalizedDigitValue ? String(normalizedDigitValue) : '');
  }
  
  onChangeReserveStreamNumber(e: ChangeEvent<HTMLInputElement>): void {
    const { value } = e.target;
    const normalizedDigitValue = normalizeToTwoDigits(value, 99);
    
    this.reserveStreamNumber.setValue(normalizedDigitValue ? String(normalizedDigitValue) : '');
  }

  * createEvent(): Generator {
    const {
      eventName, isEditable, selectTags, trendSelect, eventsStore, regConditions, inputMaxScore,
      attachmentsStore, departmentSelect, isAllFormsWasFilled, fullDescription, shortDescription, inputTimeslotLink,
      switchOpenRegistrationEvent, dateIntervalEvent: { startDate: eventStartDate, endDate: eventEndDate },
      dateIntervalRegistration: { startDate: eventRegStartDate, endDate: eventRegEndDate }, eventLocation,
      selectEventType, switchDonm, typeOfEventSelect, switchTeamEvent, switchReserveEvent, teamPartNumber,
      reserveStreamNumber
    } = this;

    if (!(isAllFormsWasFilled && eventStartDate && eventEndDate)) return;

    const fields = getCreateEventParsedFields({
      eventName,
      isEditable,
      fullDescription,
      shortDescription,
      eventEnd: eventEndDate,
      regEnd: eventRegEndDate,
      donm: switchDonm.checked,
      tagIds: selectTags.tagIds,
      eventStart: eventStartDate,
      regStart: eventRegStartDate,
      eventId: eventsStore.event?.id,
      trendSelectId: trendSelect.selectId,
      maxScore: inputMaxScore.numericValue,
      timeslotLink: inputTimeslotLink.value,
      inPresentio: selectEventType.isFullTime,
      eventOrganizations: eventLocation.toApi,
      attachments: attachmentsStore.attachments,
      departmentSelectId: departmentSelect.selectId,
      regConditions: regConditions.parsedRegConditions,
      isOpenRegistrationEvent: switchOpenRegistrationEvent.checked,
      giveChooseLocation: eventLocation.switchAllowChooseLocation.checked,
      giveChooseTimeslot: eventLocation.switchAllowChooseTimeslot.checked,
      eventType: typeOfEventSelect.type as TypeOfEvent,
      isTeam: switchTeamEvent.checked,
      teamPartNumber: teamPartNumber.numericValue,
      reserve: switchReserveEvent.checked,
      reserveStreamNumber: reserveStreamNumber.numericValue
    });

    if (!fields) return;

    const eventId = (yield eventsStore.createEvent(fields, isEditable)) as number | null;
    if (eventId) this.redirectUrl = generatePath(Routes.event, { id: eventId });
  }
}
