import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';
import { CheckPairEventInfoForUser, CheckPairEventInfoParams } from 'store/apiClients/events/types';
import { globalStore } from 'store/contexts';
import { Status } from 'constants/status';
import { EventsGetParams, EventsService } from 'store/apiClients/events';
import { EventCardCollectionType, EventCardType } from 'store/apiModels/events/types';
import { pairEventText } from 'components/elements/Buttons/ButtonRegistration/constants';


export class EventRegistrationButtonState {
  readonly eventStore = globalStore.eventsStore;
  readonly eventsService = new EventsService();

  status = Status.Initial;
  pairEventStatus: CheckPairEventInfoForUser = {
    userSubscribed: false,
    eventHasPair: false,
  };
  pairEvents: EventCardType[] = [];
  targetEvent: EventCardType | null = null;

  get titleText(): string {
    if (!this.targetEvent) {
      return '';
    }

    return `Регистрация на ${pairEventText[this.targetEvent?.eventType]} события`;
  }

  get contentText(): string {
    if (!this.targetEvent) {
      return '';
    }

    return `Вы также можете зарегистрироваться на ${pairEventText[this.targetEvent?.eventType]} события`;
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * fetchPairEvents(params: EventsGetParams = {}): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.eventsService.get(params)) as AxiosResponse<EventCardCollectionType>;
      this.status = Status.Fulfilled;
      this.pairEvents = response.data;
      return response;
    } catch (error) {
      this.status = Status.Rejected;
      return error.response;
    }
  }

  * checkPairEvent(params: CheckPairEventInfoParams): Generator {
    try {
      this.status = Status.Pending;
      const response = (
        yield this.eventsService.checkPairEventInfoForUser(params)
      ) as AxiosResponse<CheckPairEventInfoForUser>;
      this.status = Status.Fulfilled;
      this.pairEventStatus = response.data;
    } catch (e) {
      this.status = Status.Rejected;
      this.pairEventStatus = {
        userSubscribed: false,
        eventHasPair: false,
      };
    }
  }

  setTargetEvent(event: EventCardType): void {
    this.targetEvent = event;
  }

  updateEvent(eventId: number): void {
    const { event } = this.eventStore;
    const index = this.pairEvents.findIndex((e) => e.id === eventId);
    if (event && index !== -1) {
      this.pairEvents[index] = event;
    }
  }

  clear(): void {
    this.pairEvents = [];
    this.pairEventStatus = {
      userSubscribed: false,
      eventHasPair: false,
    };
  }
}
