import { makeAutoObservable } from 'mobx';
import { sanitize } from 'dompurify';
import { Status } from 'constants/status';
import { LandingService } from 'store/apiClients/landing';
import { LandingBlock } from 'store/apiClients/landing/types';
import { AxiosResponse } from 'axios';
import { EditableBlock } from './EditableBlock';
import { BlockKeys, Block } from './types';
import { getKeys } from './utils';


export class LandingStore {
  status = Status.Initial;
  blocks = new Map<string, string>();

  edited = new EditableBlock();

  readonly service = new LandingService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.fetchAll();
  }

  getBlock(blockKey: BlockKeys): Block {
    const [keyTitle, keyContent] = getKeys(blockKey);

    return {
      title: this.blocks.get(keyTitle) || '',
      content: this.blocks.get(keyContent) || '',
    };
  }

  onEditMode(blockKey: BlockKeys): void {
    const block = this.getBlock(blockKey);
    this.edited.setAll(blockKey, block);
  }

  onSave(): void {
    this.postAll();
    this.edited.clearAll();
  }

  onCancel(): void {
    this.edited.clearAll();
  }

  * fetchAll(): Generator {
    try {
      this.status = Status.Pending;
      const { data } = (yield this.service.getAll()) as AxiosResponse<LandingBlock[]>;
      this.fromApi(data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * postAll(): Generator {
    const blocks = this.toApi();

    try {
      this.status = Status.Pending;
      const { data } = (yield this.service.postAll(blocks)) as AxiosResponse<LandingBlock[]>;
      this.fromApi(data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  toApi(): LandingBlock[] {
    const [keyTitle, keyContent] = getKeys(this.edited.blockKey.value);

    const title = {
      blockData: this.edited.title.value,
      blockKey: keyTitle,
      blockVisible: true,
    };
    const content = {
      blockData: sanitize(this.edited.content.value),
      blockKey: keyContent,
      blockVisible: true,
    };

    return [title, content];
  }

  fromApi(data: LandingBlock[]): void {
    data.forEach((block) => {
      this.blocks.set(block.blockKey, block.blockData);
    });
  }

  isEditing(blockKey: BlockKeys): boolean {
    return this.edited.blockKey.value === blockKey;
  }

  isUploading(blockKey: BlockKeys): boolean {
    return this.isLoading && this.edited.blockKeyLast.value === blockKey;
  }

  get isLoading(): boolean {
    return this.status === Status.Pending;
  }
}
