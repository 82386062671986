import { v4 } from 'uuid';
import { makeAutoObservable } from 'mobx';
import { globalStore } from 'store/contexts';
import { Switch } from 'store/utilityClasses/Switch';
import { EventOrganization } from 'store/apiModels/events/types';
import { CreateEventOrgsType } from 'store/apiClients/events/types';
import { SingleSelect } from 'store/utilityClasses/Select/SingleSelect';
import { DepartmentTypesId } from 'common/departmens/enums';

import { DepartmentTypeSelect } from 'store/utilityClasses/DepartmentTypeSelect';
import { EventLocationParser } from './utils';
import { SelectEventLocation } from '../SelectEventLocation';
import { EventLocationsFormsType, EventLocationsFormType } from './types';


export class EventLocation {
  switchAllowChooseLocation = new Switch();
  switchAllowChooseTimeslot = new Switch();
  locationsForms: EventLocationsFormsType = {};

  profileTypeStore = globalStore.profileTypeStore;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get locationFormsKeys(): string[] {
    return Object.keys(this.locationsForms);
  }

  generateLocationForm(): void {
    this.locationsForms = {
      ...this.locationsForms,
      [v4()]: {
        selectDepartment: new SingleSelect(),
        selectDirectionType: new DepartmentTypeSelect(),
        selectEventLocation: new SelectEventLocation(),
      },
    };
  }

  reset(): void {
    this.locationsForms = {};
    this.switchAllowChooseLocation.setChecked(false);
    this.switchAllowChooseTimeslot.setChecked(false);
  }

  getLocationFormById(id: string): EventLocationsFormType {
    return this.locationsForms[id];
  }

  selectedDirectionType(formId: string): DepartmentTypesId | null {
    const { profileTypeStore, getLocationFormById } = this;
    const { departmentUnitTypeId, isSchoolOrUniversity } = profileTypeStore;
    const { selectDirectionType } = getLocationFormById(formId);
    if (isSchoolOrUniversity) selectDirectionType.setSelectId(departmentUnitTypeId as DepartmentTypesId);

    return selectDirectionType.selectedId;
  }

  selectedOrganization(formId: string): number | null {
    const { profileTypeStore, getLocationFormById } = this;
    const { departmentUnitId, isSchoolOrUniversity } = profileTypeStore;
    const { selectDepartment } = getLocationFormById(formId);
    if (isSchoolOrUniversity) selectDepartment.setId(departmentUnitId);

    return selectDepartment.selectId;
  }

  setOrganizationTypeByFormId(formId: string, departmentTypeId: DepartmentTypesId): void {
    const { selectDirectionType, selectDepartment, selectEventLocation } = this.getLocationFormById(formId);
    selectDepartment.clear();
    selectEventLocation.resetEventLocations();
    selectDirectionType.setSelectId(departmentTypeId);
  }

  setOrganizationByFormId(formId: string, departmentId: number | null): void {
    const { selectDepartment, selectEventLocation } = this.getLocationFormById(formId);
    selectEventLocation.resetEventLocations();
    selectDepartment.setId(departmentId);
  }

  isOrganizationValidByFormId(formId: string): boolean {
    const { selectedDirectionType, selectedOrganization } = this;
    return Boolean(selectedOrganization(formId) && selectedDirectionType(formId));
  }

  get toApi(): CreateEventOrgsType {
    return EventLocationParser.toApi(this.locationsForms);
  }

  fromApi(isAllowChooseLocation: boolean, isAllowChooseTimeslot: boolean, rawEventOrgs: EventOrganization[]): void {
    this.switchAllowChooseLocation.setChecked(isAllowChooseLocation);
    this.switchAllowChooseTimeslot.setChecked(isAllowChooseTimeslot);
    this.locationsForms = EventLocationParser.fromApi(rawEventOrgs);
  }

  get isAvailableAddForm(): boolean {
    const { profileTypeStore, locationFormsKeys } = this;
    const { isOperator, isSchoolOrUniversity } = profileTypeStore;

    return isOperator || (isSchoolOrUniversity && locationFormsKeys.length < 1);
  }

  get isValid(): boolean {
    const { locationsForms } = this;
    const locationsFormsValues = Object.values(locationsForms);
    const firstForm = locationsFormsValues.length >= 1 && locationsFormsValues[0];

    if (!firstForm) return false;
    const { selectDepartment, selectEventLocation } = firstForm;
    return Boolean(selectDepartment.selectId && selectEventLocation.checkedLocations.length);
  }
}
