import { Position } from './types';


export const WIDTH_AREA = 550;

export const DEFAULT_FONT_SIZE = 16;

export const DEFAULT_INPUT_POSITION: Position = {
  x: 0,
  y: 0,
  height: 50,
  width: WIDTH_AREA,
};

export const DEFAULT_QR_CODE_POSITION: Position = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
};

export const QR_CODE_MIN_SIZE = 50;
