export enum AvailableEventsFilters {
  academicYearId = 'academicYearId',
  partnerId = 'partnerId',
  departmentId = 'departmentId',
  directionId = 'directionId',
  trendId = 'trendId',
  tags = 'tags',
  donm = 'donm',
  eventTypeId = 'eventTypeId',
}

export enum DonmFilter {
  donm = 'donm',
  other = 'other',
}
