import axios, { AxiosResponse } from 'axios';
import { timeslotsApi } from 'constants/api';
import { TimeslotApiModel } from 'common/timeslot/types';
import { TimeslotsServiceAppointUsersParams, TimeslotsServiceGetParams } from './types';


export class TimeslotsService {
  get = async ({
    eventId, page, perPage = 500,
  }: TimeslotsServiceGetParams): Promise<AxiosResponse<TimeslotApiModel[]>> => (
    axios.get(`${timeslotsApi}/extended_by_event`, {
      params: {
        page,
        per_page: perPage,
        event_id: eventId,
      },
    })
  );

  appointUsers = async (params: TimeslotsServiceAppointUsersParams): Promise<AxiosResponse> => (
    axios.post(`${timeslotsApi}/appoint_users`, params)
  );

  distributed = async (timeslotId: number): Promise<AxiosResponse> => (
    axios.put(`${timeslotsApi}/distributed/${timeslotId}`)
  );

  notDistributeWithFreePlacesByEvent = async (eventId: number, locationId: number | null): Promise<AxiosResponse> => (
    axios.get(`${timeslotsApi}/not_distributed_with_free_places_by_event`, {
      params: { event_id: eventId, location_id: locationId }
    })
  );
}
