import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { Avatar, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Flex } from 'components/elements/Utilities/Flex';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { Link, generatePath } from 'react-router-dom';
import { Routes } from 'common/routes/enums';
import { getAvatarPlaceholderByFullName } from './utils';
import styles from './styles.scss';


const ProfileInfoComponent = (): ReactElement | null => {
  const { profileTypeStore } = useGlobalStores();
  const { profile, isOperator, isRegistered } = profileTypeStore;

  if (!profile || !isRegistered) return null;

  const { fio, id: userId } = profile.user;
  const departmentName = profile.departmentUnit?.name;
  const profileAdditionalInfo = isOperator ? `Оператор ${departmentName}` : departmentName;

  return (
    <Flex spacing={1.5} align="center">
      <Avatar className={styles.avatar}>
        {getAvatarPlaceholderByFullName(fio)}
      </Avatar>
      <Link to={generatePath(Routes.profile, { id: userId })} className={styles.linkWrapper}>
        <Typography
          noWrap
          title={fio}
          variant="subtitle1"
          className={styles.textName}
        >
          {fio}
        </Typography>
        {departmentName && (
          <Typography
            noWrap
            title={profileAdditionalInfo}
            variant="subtitle2"
            className={clsx(styles.textProfileAdditionalInfo, {
              [styles.textUnderline]: !isOperator,
            })}
          >
            {profileAdditionalInfo}
          </Typography>
        )}
      </Link>
    </Flex>
  );
};

export const ProfileInfo = observer(ProfileInfoComponent);
