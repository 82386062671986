import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { TimeslotApiModel } from 'common/timeslot/types';
import { TimeslotsService } from 'store/apiClients/timeslots';
import { TimeslotsServiceAppointUsersParams, TimeslotsServiceGetParams } from 'store/apiClients/timeslots/types';
import { extractIds, Tuple } from 'utils/index';
import { getPluralString } from 'utils/getPluralString';
import { TimeslotsType } from './types';


export class TimeslotsStore {
  status = Status.Initial;
  readonly service: TimeslotsService;
  timeslots: TimeslotsType = new Map<number, TimeslotApiModel>();
  statusesById = new Map<number, Status>();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.service = new TimeslotsService();
  }


  get listTimeslots(): TimeslotApiModel[] {
    return [...this.timeslots.values()];
  }

  get timeslotWithFreePlaces(): TimeslotApiModel[] {
    return this.listTimeslots.filter((timeslot) => Boolean(timeslot.freePlaces));
  }

  get timeslotWithFreePlacesIds(): number[] {
    return extractIds(this.timeslotWithFreePlaces);
  }

  * fetchTimeslots(params: TimeslotsServiceGetParams): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.service.get(params)) as AxiosResponse<TimeslotApiModel[]>;
      this.resetTimeslots();
      this.setTimeslotsFromApi(response.data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * fetchTimeslotDistributed(timeslotId: number): Generator<unknown, boolean> {
    try {
      this.statusesById.set(timeslotId, Status.Pending);
      yield this.service.distributed(timeslotId);
      this.statusesById.delete(timeslotId);
      return true;
    } catch (e) {
      this.statusesById.set(timeslotId, Status.Rejected);
      return false;
    }
  }

  * fetchTimeslotsNotDistributeWithFreePlacesByEvent(eventId: number, locationId: number | null): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.service.notDistributeWithFreePlacesByEvent(eventId, locationId)) as AxiosResponse<TimeslotApiModel[]>;
      this.resetTimeslots();
      this.setTimeslotsFromApi(response.data);
      this.status = Status.Fulfilled;
    } catch (err) {
      this.status = Status.Rejected;
    }
  }

  * appointUsersToTimeslot(params: TimeslotsServiceAppointUsersParams): Generator<unknown, boolean> {
    try {
      this.status = Status.Pending;
      const response = (yield this.service.appointUsers(params)) as AxiosResponse;
      if (response.status === 200) {
        this.status = Status.Fulfilled;
        return true;
      }
      if (response.status === 409) {
        // eslint-disable-next-line no-console
        console.log('There is not enough free space to add to the group');
      }
      this.status = Status.Rejected;
      return false;
    } catch (e) {
      this.status = Status.Rejected;
      return false;
    }
  }

  private setTimeslotsFromApi(data: TimeslotApiModel[]): void {
    data.forEach((timeslot) => {
      this.timeslots.set(timeslot.id, timeslot);
    });
  }

  get timeslotsIds(): number[] {
    return [...this.timeslots.keys()];
  }

  getTimeslotById(id: number): TimeslotApiModel | null {
    return this.timeslots.get(id) || null;
  }

  getTimeslotFormattedDateWithFreePlacesById(id: number): string {
    const timeslot = this.getTimeslotById(id);
    if (!timeslot) return '';

    const { timeInterval, freePlaces } = timeslot;

    const placesStrings = Tuple('место', 'места', 'мест');
    const freePlacesString = getPluralString(placesStrings, freePlaces);

    return `${timeInterval.replace('-', '–')} — ${freePlaces} ${freePlacesString}`;
  }

  resetTimeslots(): void {
    this.timeslots.clear();
  }

  get isTimeslotsLoaded(): boolean {
    return Boolean(this.status === Status.Fulfilled && this.timeslots.size);
  }

  getTimeslotLocation(id: number): string {
    const timeslot = this.getTimeslotById(id);
    if (timeslot && timeslot.location) {
      const { locationName, locationAddress } = timeslot.location;

      return `${locationName} (${locationAddress})`;
    }

    return '';
  }

  filterTimeSlots(optionIds: number[] = [], inputValue: string): number[] {
    if (!inputValue) {
      return optionIds;
    }

    return optionIds.filter((id) => {
      try {
        const lowerInput = inputValue.toLowerCase();
        const timeslotDate = this.getTimeslotFormattedDateWithFreePlacesById(id);
        const indexOfDate = timeslotDate.toLowerCase().indexOf(lowerInput);

        const locationStr = this.getTimeslotLocation(id);
        const indexOfLocation = locationStr.toLowerCase().indexOf(lowerInput);

        return indexOfDate > -1 || indexOfLocation > -1;
      } catch (e) {
        return true;
      }
    });
  }
}
