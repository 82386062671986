import { makeAutoObservable } from 'mobx';
import { StageApi } from 'store/apiClients/globalEvent/types';
import { Input } from 'store/utilityClasses/Input/Input';
import { PhasesType } from './types';


export class Phases {
  phases: PhasesType[] = [];
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get phasesIds(): Array<number> {
    return [...this.phases.keys()];
  }

  createPhase(event?: MouseEvent, phase?: StageApi): void {
    const inputPhase = new Input(phase?.name || '');
    const inputFormula = new Input(phase?.formula || '');

    this.phases.push({ inputPhase, inputFormula });
  }

  deletePhaseById(id: number): void {
    this.phases.splice(id, 1);
  }
}
