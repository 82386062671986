import React from 'react';
import { CitiesStore } from 'store/apiModels/cities';
import { EventsStore } from 'store/apiModels/events';
import { DepartmentsStore } from 'store/apiModels/departments';
import { ProfileTypeStore } from 'store/apiModels/profileType';
import { UserStore } from 'store/apiModels/user';
import { TrendsStore } from 'store/apiModels/trends';
import { DirectionsStore } from 'store/apiModels/directions';
import { RegionsStore } from 'store/apiModels/regions';
import { GradesStore } from 'store/apiModels/grades';
import { EventGroupsStore } from 'store/apiModels/eventGroups';
import { TagsStore } from 'store/apiModels/tags';
import { EventsCalendarStore } from 'store/apiModels/eventsCalendar';
import { GroupUsersStore } from 'store/apiModels/groupUsers';
import { EventUsersStore } from 'store/apiModels/eventUsers';
import { TimeslotsStore } from 'store/apiModels/timeslots';
import { ErrorStore } from 'store/apiModels/errors';
import { ResultsStore } from 'store/apiModels/results';
import { BannerStore } from 'store/apiModels/banner';
import { LandingStore } from 'store/apiModels/landing';
import { GlobalEventsStore } from 'store/apiModels/globalEvents';
import { AcademicYearStore } from 'store/apiModels/academicYear';
import { EventResults } from 'store/apiModels/eventResults';
import { TeamsStore } from 'store/apiModels/teams';


export const globalStore = {
  citiesStore: new CitiesStore(),
  departmentsStore: new DepartmentsStore(),
  trendsStore: new TrendsStore(),
  eventsStore: new EventsStore(),
  eventResultStore: new EventResults(),
  eventsCalendarStore: new EventsCalendarStore(),
  directionsStore: new DirectionsStore(),
  profileTypeStore: new ProfileTypeStore(),
  userStore: new UserStore(),
  regionsStore: new RegionsStore(),
  gradesStore: new GradesStore(),
  eventGroupsStore: new EventGroupsStore(),
  groupUsersStore: new GroupUsersStore(),
  landingStore: new LandingStore(),
  eventUsersStore: new EventUsersStore(),
  tagsStore: new TagsStore(),
  timeslotsStore: new TimeslotsStore(),
  errorStore: new ErrorStore(),
  resultsStore: new ResultsStore(),
  bannerStore: new BannerStore(),
  globalEventsStore: new GlobalEventsStore(),
  academicYearStore: new AcademicYearStore(),
  teamsStore: new TeamsStore()
};

export type GlobalStoreType = typeof globalStore;

export const globalStoresContext = React.createContext(globalStore);
