import { makeAutoObservable } from 'mobx';


type DateOrNull = Date | null;

interface OnChangeParams {
  startDate: DateOrNull
  endDate: DateOrNull
}


export class DateInterval {
  startDate: DateOrNull = null;
  endDate: DateOrNull = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onChange({ startDate, endDate }: OnChangeParams): void {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
