import { makeAutoObservable } from 'mobx';
import { InputNumeric } from 'store/utilityClasses/InputNumeric';
import { CertificateTypes } from 'store/apiClients/personalData/types';
import { CertificateApi, CertificateTemplatesGetApi } from 'store/apiClients/globalEvent/types';
import { MouseEvent } from 'react';
import { CertificatesType } from './types';
import { SelectCertificateType } from '../SelectCertificateType';


export class Certificates {
  certificates: CertificatesType[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get certificatesIds(): Array<number> {
    return [...this.certificates.keys()];
  }

  createCertificateCard(event?: MouseEvent, certificate?: CertificateApi): void {
    const selectCertificateType = new SelectCertificateType();
    selectCertificateType.selectedId = certificate ? certificate.type : CertificateTypes.PARTICIPANT;

    const inputNumericMinCondition = new InputNumeric(String(certificate?.startScore ?? ''));
    const inputNumericMaxCondition = new InputNumeric(String(certificate?.endScore ?? ''));

    this.certificates.push({
      selectCertificateType,
      inputNumericMinCondition,
      inputNumericMaxCondition,
      templateId: certificate?.templateId,
    });
  }

  setTemplatesDataById(certificateId: number, data?: CertificateTemplatesGetApi): void {
    this.certificates[certificateId].templateId = data?.id;
    this.certificates[certificateId].cover = data?.cover;
  }

  deleteCertificateById(id: number): void {
    this.certificates.splice(id, 1);
  }

  isValidFormById(id: number): boolean {
    const certificate = this.certificates[id];
    return Boolean(certificate.inputNumericMinCondition.value.trim()
      && certificate.inputNumericMaxCondition.value.trim()
      && certificate.selectCertificateType.selectedId);
  }
}
