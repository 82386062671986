import axios, { AxiosResponse } from 'axios';
import { ID_COUNTRY_RUSSIA } from 'common/constants';


export interface RegionApiModel {
  id: number,
  title: string,
}

export interface RegionResponseApi {
  count: number,
  items: RegionApiModel[],
}

export class RegionsService {
  get = async (): Promise<AxiosResponse<RegionResponseApi>> => (
    axios.get('/proxy/vk_proxy/regions', {
      params: {
        country_id: ID_COUNTRY_RUSSIA,
        limit: 100,
      },
    })
  );
}

