import { ChangeEvent } from 'react';
import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { makeAutoObservable } from 'mobx';
import { EventsAcceptParams } from 'store/apiClients/events';
import { OurStudentsService } from 'store/apiClients/ourStudents';
import { globalStore } from 'store/contexts';
import { saveAs } from 'file-saver';
import { getFileNameFromResponseHeader } from 'utils/fileWorkers';
import { Pagination } from 'store/utilityClasses/Pagination';
import { Checkbox } from 'store/utilityClasses/Checkbox/Checkbox';
import { FilterInput } from 'store/utilityClasses/FilterInput';
import { SingleSelect } from 'store/utilityClasses/Select/SingleSelect';
import { ExportToXlsxParams, GetAllStudentsParams } from 'store/apiClients/ourStudents/types';
import { updateSearchParamInURL } from 'utils/searchParams/utils';
import { OurStudents, AllStudents, FetchStudentsParams, AfterUpdateLocation } from './types';
import { AvailableOurStudentsFilters } from './enum';


export class OurStudentsState {
  status = Status.Initial;
  students: OurStudents[] = [];
  allStudents: AllStudents[] = [];
  pagination: Pagination;
  allStudentsPagination: Pagination;
  academicYear = new FilterInput<number>(AvailableOurStudentsFilters.academicYear);
  trendSelect = new SingleSelect();
  directionSelect = new SingleSelect();
  withArchive = new Checkbox();

  readonly ourStudentsService = new OurStudentsService();
  readonly eventsStore = globalStore.eventsStore;
  readonly academicYearStore = globalStore.academicYearStore;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.pagination = new Pagination(this.fetchStudents);
    this.allStudentsPagination = new Pagination(this.fetchAllStudents);
    // this.academicYear = new FilterInput(
    //   AvailableOurStudentsFilters.academicYear,
    //   this.fetchStudents,
    //   this.pagination.resetPage,
    // );
    this.init();
  }

  init(): void {
    this.academicYearStore.fetchYears();
  }

  get isLoading(): boolean {
    return this.status === Status.Pending;
  }

  * fetchAllStudents(params?: GetAllStudentsParams): Generator {
    try {
      this.status = Status.Pending;

      const fetchParams = {
        page: this.allStudentsPagination.page,
        onlyArchived: this.withArchive.value
      };

      const response = (yield this.ourStudentsService.getAllStudents(fetchParams)) as AxiosResponse<AllStudents[]>;
      this.allStudents = response.data;
      this.allStudentsPagination.setPages(response.headers);
      this.status = Status.Fulfilled;
    } catch (err) {
      console.error(err);
      this.status = Status.Rejected;
    }
  }

  * fetchStudents(params?: FetchStudentsParams): Generator {
    try {
      this.status = Status.Pending;
      const fetchParams = {
        ...params,
        page: this.pagination.page,
        directionId: this.directionSelect.selectId,
        trendId: this.trendSelect.selectId
      };
      const response = (yield this.ourStudentsService.getStudents(fetchParams)) as AxiosResponse<OurStudents[]>;
      this.students = response.data;
      this.pagination.setPages(response.headers);
      this.status = Status.Fulfilled;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }

  * fetchExportToXlsx(): Generator {
    try {
      const params: ExportToXlsxParams = {
        schoolYear: this.academicYear.selectedNumberValue,
      };
      const { data, headers } = (yield this.ourStudentsService.exportToXlsx(params)) as AxiosResponse;
      const fileName = getFileNameFromResponseHeader(headers, 'учащиеся.xls');
      saveAs(data, fileName);
    } catch (e) {
      //
    }
  }

  * fetchExportToXlsxForAllYears(): Generator {
    try {
      const { data, headers } = (yield this.ourStudentsService.exportToXlsx()) as AxiosResponse;
      const fileName = getFileNameFromResponseHeader(headers, 'учащиеся_за_все_года.xls');
      saveAs(data, fileName);
    } catch (e) {
      //
    }
  }

  * fetchExportFromOthersSchoolsToXlsx(): Generator {
    try {
      const { data, headers } = (yield this.ourStudentsService.exportStudentFromOthersSchoolsToXlsx({
        schoolYear: this.academicYear.selectedNumberValue
      })) as AxiosResponse;
      const fileName = getFileNameFromResponseHeader(headers, 'учащиеся_других_школ.xls');
      saveAs(data, fileName);
    } catch (e) {

    }
  }

  * accept(params: EventsAcceptParams): Generator {
    yield this.eventsStore.acceptEvent(params);
    yield this.fetchStudents();
  }

  * updateDesiredLocation(eventId: number, userId: number, locationId: number): Generator {
    try {
      this.status = Status.Pending;
      const { data, status } = (yield this.ourStudentsService.updateDesiredLocation(
        eventId,
        userId,
        locationId,
      )) as AxiosResponse;
      this.status = Status.Fulfilled;
      if (status === 200) {
        this.updateLocationAfterUpdate(userId, eventId, data);
      }
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  setDirection(id: number): void {
    this.trendSelect.reset();
    this.directionSelect.setId(id);
  }

  setTrend(id: number): void {
    this.trendSelect.setId(id);
    this.pagination.resetPage();
    this.fetchStudents();
  }

  changeWithArchiveValue(e: ChangeEvent<HTMLInputElement>): void {
    this.withArchive.value = e.target.checked;
    this.allStudentsPagination.resetPage();
    this.fetchAllStudents();
  }
  
  resetFilters(isAllStudents: boolean = false): void {
    if (isAllStudents) {
      this.allStudentsPagination.resetPage();
      this.withArchive.value = false;
      this.fetchAllStudents();
    } else {
      this.pagination.resetPage();
      this.directionSelect.reset();
      this.trendSelect.reset();
      this.fetchStudents();
    }
  }

  resetAcademicYearFilter(): void {
    const { defaultYear } = this.academicYearStore;
    if (defaultYear) {
      this.academicYear.value = defaultYear;
      updateSearchParamInURL(AvailableOurStudentsFilters.academicYear, defaultYear);
    } else {
      this.academicYear.clearFilter();
    }
  }

  private updateLocationAfterUpdate(userId: number, eventId: number, data: AfterUpdateLocation) {
    const { id, locationAddress, locationName } = data;

    const studentIndex = this.students.findIndex((s) => s.userId === userId);
    if (studentIndex < 0) {
      return;
    }

    const eventIndex = this.students[studentIndex].studentEvents.findIndex((e) => e.eventId === eventId);
    if (eventIndex < 0) {
      return;
    }

    const targetStudent = this.students[studentIndex];
    const targetEvent = targetStudent.studentEvents[eventIndex];

    this.students[studentIndex].studentEvents[eventIndex] = {
      ...targetEvent,
      desiredLocation: {
        id,
        locationAddress,
        locationName,
      },
    };
  }
}
