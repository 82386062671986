import { ResultCheckType } from 'store/apiClients/results/types';
import { ResultStatusCheck } from './enums';


export const recordResultStatusCheck2State: Record<ResultStatusCheck, boolean | null> = {
  [ResultStatusCheck.notChecked]: null,
  [ResultStatusCheck.passed]: true,
  [ResultStatusCheck.notPassed]: false,
};

export const mapResultStatusCheckWithName = new Map<ResultCheckType, ResultStatusCheck>([
  [null, ResultStatusCheck.notChecked],
  [true, ResultStatusCheck.passed],
  [false, ResultStatusCheck.notPassed],
]);
