import { AttachmentEntityType } from 'store/apiClients/events/types';
import { PagesParams } from 'common/types';


export interface GetUsersAgreementDataParams extends PagesParams {
  fioOrEmail?: string,
  departmentName?: string,
  cityRegion?: string,
  schoolOperator?: string,
  certificateType?: CertificateTypes | null,
  certificateAccessStatus?: CertificateAccessStatus | null,
  metaEventId?: string,
  schoolYear?: number | null,
}

export enum CertificateTypes {
  WINNER = 'winner',
  AWARDEE = 'awardee',
  PARTICIPANT = 'participant',
}

export enum CertificateAccessStatus {
  EMPTY = 'empty',
  WAITING = 'waiting',
  ACCEPTED = 'accepted',
  CANCELED = 'canceled',
}

export interface UserAgreement {
  fio: string,
  email: string,
  userId: number,
  cityRegion: string,
  departmentName: string,
  editable: boolean | null,
  departmentOperators?: string,
  certificateType: CertificateTypes,
  organizationAddress: string | null,
  departmentOperatorsEmails?: string,
  attachment?: AttachmentEntityType | null,
  certificateAccessStatusDescription: string,
  certificateAccessStatus: CertificateAccessStatus,
  link: string
}

export type UsersAgreementList = Array<UserAgreement>;

export interface AcceptUserPersonalDataParamsType {
  userId: number,
}

export interface RejectUserPersonalDataParamsType {
  userId: number,
  rejectReason?: string | null,
}
