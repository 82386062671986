import { InputNumeric } from 'store/utilityClasses/InputNumeric';
import { RegConditionTypes } from 'store/apiClients/events/enums';
import { SelectParallels } from 'store/states/createEvent/utilityClasses/SelectParallels';
import { SelectProfileType } from 'store/states/createEvent/utilityClasses/SelectProfileType';
import { RegConditionByResultForm } from 'store/states/createEvent/utilityClasses/RegConditionByResultForm';
import { RegConditionFormType } from './types';


export const registrationConditionForms: RegConditionFormType = {
  [RegConditionTypes.BY_PARALLELS]: SelectParallels,
  [RegConditionTypes.BY_PROFILE_TYPE]: SelectProfileType,
  [RegConditionTypes.BY_RESULTS]: RegConditionByResultForm,
  [RegConditionTypes.BY_PARTICIPATION_IN_EVENT]: InputNumeric,
  [RegConditionTypes.BY_NOT_PARTICIPATION_IN_EVENT]: InputNumeric,
};

export const recordRegConditionTypeIds2Label: Record<RegConditionTypes, string> = {
  [RegConditionTypes.BY_RESULTS]: 'По результатам',
  [RegConditionTypes.BY_PARALLELS]: 'По параллелям',
  [RegConditionTypes.BY_PROFILE_TYPE]: 'По типу профиля',
  [RegConditionTypes.BY_PARTICIPATION_IN_EVENT]: 'По участию в событии',
  [RegConditionTypes.BY_NOT_PARTICIPATION_IN_EVENT]: 'По НЕучастию в событии',
};
