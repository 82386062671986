import { makeAutoObservable } from 'mobx';
import { EventGroupsService } from 'store/apiClients/eventGroups';
import {
  EventGroupModelApi,
  EventGroupStatApi,
  SetStatusesByEventGroupIdsParams,
  CreateGroupsParams,
  UpdateLinkForGroupListParams,
  UpdateTimeslotParams,
  UpdateLocationForGroupListParams, UpdateUserLimitCount,
} from 'store/apiClients/eventGroups/types';
import { Distribute } from 'store/states/eventCreateGroups/types';
import { Status } from 'constants/status';
import { AxiosResponse } from 'axios';
import { TimeslotApiModel } from 'common/timeslot/types';
import { createNewEventGroup } from './utils';


export class EventGroupsStore {
  private readonly eventGroupsService = new EventGroupsService();
  status = Status.Initial;
  eventGroups = new Map<number, EventGroupModelApi>();
  eventStat = new Map<number, EventGroupStatApi>();
  statusesByGroupId = new Map<number, Status>();
  eventId = 0;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get hasEventGroups(): boolean {
    return Boolean(this.listEventGroups.length);
  }

  get listEventGroups(): EventGroupModelApi[] {
    return [...this.eventGroups.values()];
  }

  get listEventIdsWithTimeslots(): number[] {
    return this.listEventGroups.reduce((acc, eventGroup) => {
      if (eventGroup.timeslots.length) {
        acc.push(eventGroup.id);
      }
      return acc;
    }, [] as Array<number>);
  }

  get hasEventGroupsWithoutTimeslot(): boolean {
    return this.listEventGroups.some(({ timeslots }) => !timeslots.length);
  }

  get listEventGroupsIds(): number[] {
    return Array.from(this.eventGroups.keys());
  }

  get hasDistributedGroups(): boolean {
    return this.listEventGroups.some(({ timeslots }) => timeslots.length && timeslots[0].distributed);
  }

  isEventGroupByIdDistributed(groupId: number): boolean {
    return Boolean(this.getTimeslotByGroupId(groupId)?.distributed);
  }

  getTimeslotByGroupId(groupId: number): TimeslotApiModel | null {
    const group = this.eventGroups.get(groupId);
    return group?.timeslots.length ? group.timeslots[0] : null;
  }

  getEventGroupById = (id: number): EventGroupModelApi | null => this.eventGroups.get(id) || null;

  getEventStatById = (id: number): EventGroupStatApi | null => this.eventStat.get(id) || null;

  * fetchUpdateTimeslot({ eventGroupId, startedAt, endedAt }: UpdateTimeslotParams): Generator {
    try {
      this.statusesByGroupId.set(eventGroupId, Status.Pending);
      const timeslot = this.getTimeslotByGroupId(eventGroupId);
      const eventGroup = this.getEventGroupById(eventGroupId);

      if (!eventGroup) throw new Error(`Event group with id ${eventGroupId} not found`);

      const fetchUpdate = timeslot
        ? this.fetchUpdateDateTimeslot
        : this.fetchCreateTimeslot;

      const { data } = (yield fetchUpdate({ eventGroupId, endedAt, startedAt })) as AxiosResponse<EventGroupModelApi>;
      this.setEventGroupsFromApi([data]);
      this.statusesByGroupId.delete(eventGroupId);
    } catch (e) {
      this.statusesByGroupId.set(eventGroupId, Status.Rejected);
    }
  }
  
  * fetchUpdateUserLimit({ eventGroupId, startedAt, endedAt, userLimit }: UpdateUserLimitCount): Generator {
    try {
      this.statusesByGroupId.set(eventGroupId, Status.Pending);
      const eventGroup = this.getEventGroupById(eventGroupId);
  
      if (!eventGroup) throw new Error(`Event group with id ${eventGroupId} not found`);
      
      const { data } = (yield this.fetchUpdateUserLimitCount({
        eventGroupId,
        startedAt,
        endedAt,
        userLimit
      })) as AxiosResponse<EventGroupModelApi>;
      
      this.setEventGroupsFromApi([data]);
      this.statusesByGroupId.delete(eventGroupId);
    } catch (err) {
      this.statusesByGroupId.set(eventGroupId, Status.Rejected);
      console.error(err);
    }
  }
  
  // private
  * fetchCreateTimeslot(
    { eventGroupId, startedAt, endedAt }: UpdateTimeslotParams,
  ): Generator {
    const eventGroup = this.getEventGroupById(eventGroupId);
    if (!eventGroup) throw new Error(`Event group with id ${eventGroupId} not found`);
    const updatedTimeslot = { startedAt, endedAt };
    const updatedGroup = createNewEventGroup({ eventGroup, timeslot: updatedTimeslot });

    return (yield this.eventGroupsService.updateGroup(updatedGroup)) as AxiosResponse<EventGroupModelApi>;
  }

  // private
  * fetchUpdateDateTimeslot(
    { eventGroupId, startedAt, endedAt }: UpdateTimeslotParams,
  ): Generator {
    const eventGroup = this.getEventGroupById(eventGroupId);
    if (!eventGroup) throw new Error(`Event group with id ${eventGroupId} not found`);
    const timeslot = this.getTimeslotByGroupId(eventGroupId);
    if (!timeslot) throw new Error(`in eventGroup with id ${eventGroupId} missing timeslot`);
    const updatedTimeslot: TimeslotApiModel = { ...timeslot, startedAt, endedAt };

    const updatedGroup = createNewEventGroup({ eventGroup, timeslot: updatedTimeslot });
    return (yield this.eventGroupsService.updateGroup(updatedGroup)) as AxiosResponse<EventGroupModelApi>;
  }

  * fetchUpdateUserLimitCount(
    { eventGroupId, startedAt, endedAt, userLimit}: UpdateUserLimitCount
  ): Generator {
    const eventGroup = this.getEventGroupById(eventGroupId);
    if (!eventGroup) throw new Error(`Event group with id ${eventGroupId} not found`);
    
    const timeslot = this.getTimeslotByGroupId(eventGroupId);
    if (!timeslot) throw new Error(`in eventGroup with id ${eventGroupId} missing timeslot`);
    
    const updatedTimeslot: TimeslotApiModel = { ...timeslot, startedAt, endedAt };
    const updatedGroup = createNewEventGroup({ eventGroup, timeslot: updatedTimeslot, userLimit });
  
    return (yield this.eventGroupsService.updateGroup(updatedGroup)) as AxiosResponse<EventGroupModelApi>;
  }
  
  * fetchUpdateLinkForGroupList(params: UpdateLinkForGroupListParams): Generator {
    try {
      this.setStatusesByEventGroupIds({ groupIds: params.groupIds, status: Status.Pending });
      const { data } = (
        yield this.eventGroupsService.updateLinkForGroupList(params)
      ) as AxiosResponse<EventGroupModelApi[]>;
      this.setEventGroupsFromApi(data);
      this.deleteStatusesByEventGroupIds(params.groupIds);
    } catch (e) {
      this.setStatusesByEventGroupIds({ groupIds: params.groupIds, status: Status.Rejected });
    }
  }

  * fetchUpdateLocationForGroupList(params: UpdateLocationForGroupListParams): Generator {
    try {
      this.setStatusesByEventGroupIds({ groupIds: params.groupIds, status: Status.Pending });
      const { data } = (
        yield this.eventGroupsService.updateLocationForGroupList(params)
      ) as AxiosResponse<EventGroupModelApi[]>;
      this.setEventGroupsFromApi(data);
      this.deleteStatusesByEventGroupIds(params.groupIds);
    } catch (e) {
      this.setStatusesByEventGroupIds({ groupIds: params.groupIds, status: Status.Rejected });
    }
  }

  * fetchEventGroups(eventId?: number): Generator<unknown, AxiosResponse<EventGroupModelApi[]> | null> {
    try {
      this.clear();
      this.status = Status.Pending;
      const response = (yield this.eventGroupsService.get(eventId)) as AxiosResponse<EventGroupModelApi[]>;
      this.setEventGroupsFromApi(response.data);
      this.status = Status.Fulfilled;
      return response;
    } catch (error) {
      this.status = Status.Rejected;
      return null;
    }
  }

  * fetchEventGroupById(groupId: number): Generator {
    try {
      this.setStatusesByEventGroupIds({ status: Status.Pending, groupIds: [groupId] });
      const { data } = (yield this.eventGroupsService.getById(groupId)) as AxiosResponse<EventGroupModelApi>;
      this.setEventGroupsFromApi([data]);
      this.deleteStatusesByEventGroupIds([groupId]);
    } catch (e) {
      this.setStatusesByEventGroupIds({ status: Status.Rejected, groupIds: [groupId] });
    }
  }

  * fetchEventGroupsStat(eventId: number): Generator<unknown, AxiosResponse<EventGroupStatApi> | null> {
    try {
      this.status = Status.Pending;
      this.eventId = eventId;
      const response = (yield this.eventGroupsService.getGroupStat(eventId)) as AxiosResponse<EventGroupStatApi>;
      this.setEventGroupsStat(response.data);
      this.status = Status.Fulfilled;
      return response;
    } catch (error) {
      this.status = Status.Rejected;
      return null;
    }
  }

  * fetchDeleteForEvents(eventId: number): Generator<unknown, AxiosResponse | null> {
    try {
      this.status = Status.Pending;
      const response = (yield this.eventGroupsService.deleteForEvent(eventId)) as AxiosResponse;
      this.status = Status.Fulfilled;
      return response;
    } catch (e) {
      this.status = Status.Rejected;
      return null;
    }
  }

  * fetchCreateForEvent(eventId: number): Generator {
    try {
      const { data } = (yield this.eventGroupsService.createForEvent(eventId)) as AxiosResponse<EventGroupModelApi>;
      this.setEventGroupsFromApi([data]);
    } catch (e) {
      throw new Error(e);
    }
  }

  * fetchDeleteById(groupId: number): Generator {
    try {
      this.setStatusesByEventGroupIds({ status: Status.Pending, groupIds: [groupId] });
      const { status } = (yield this.eventGroupsService.deleteById(groupId)) as AxiosResponse;
      if (status === 200) {
        this.eventGroups.delete(groupId);
      }
      this.deleteStatusesByEventGroupIds([groupId]);
    } catch (e) {
      this.setStatusesByEventGroupIds({ status: Status.Rejected, groupIds: [groupId] });
    }
  }

  * createGroups(distribute: Distribute, params: CreateGroupsParams): Generator {
    const actions = {
      [Distribute.groups]: this.eventGroupsService.createByGroup,
      [Distribute.users]: this.eventGroupsService.createByUser,
      [Distribute.school]: this.eventGroupsService.createBySchool,
      [Distribute.locations]: this.eventGroupsService.createByLocations,
    };

    try {
      this.status = Status.Pending;
      const response = (yield actions[distribute](params)) as AxiosResponse;
      this.status = Status.Fulfilled;
      if (response.status === 201) {
        yield this.fetchEventGroups(params.id);
      }
    } catch (error) {
      this.status = Status.Rejected;
    }
  }

  setEventGroupsFromApi(apiData: EventGroupModelApi[]): void {
    apiData.forEach((api) => {
      this.eventGroups.set(api.id, api);
    });
  }

  private setStatusesByEventGroupIds({ groupIds, status }: SetStatusesByEventGroupIdsParams): void {
    groupIds.forEach((groupId) => this.statusesByGroupId.set(groupId, status));
  }

  private deleteStatusesByEventGroupIds(groupIds: number[]): void {
    groupIds.forEach((groupId) => this.statusesByGroupId.delete(groupId));
  }

  private setEventGroupsStat(apiData: EventGroupStatApi): void {
    this.eventStat.set(apiData.eventId, apiData);
  }

  clear(): void {
    this.eventGroups.clear();
  }
}
