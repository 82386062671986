import React, { ReactElement, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useGlobalStores } from 'hooks/useGlobalStores';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import styles from './styles.scss';


const MessageBannerComponent = (): ReactElement => {
  const [visible, setVisibility] = useState(true);
  const { bannerStore, bannerStore: { message }, profileTypeStore: { isRegistered } } = useGlobalStores();

  useEffect(() => {
    if (isRegistered) {
      bannerStore.fetchMessage();
    }
  }, [isRegistered]);

  if (!message) return <></>;

  return (
    <div className={clsx(styles.root, !visible && styles.hide)}>
      <p>{message}</p>
      <IconButton
        title="Закрыть"
        size="small"
        className={styles.closeBtn}
        onClick={() => setVisibility(false)}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export const MessageBanner = observer(MessageBannerComponent);

