import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { TagsService } from 'store/apiClients/tags';
import { TagsApi, TagsApiCollection } from 'store/apiClients/tags/types';
import { Status } from 'constants/status';


export class TagsStore {
  status = Status.Initial;
  tags = new Map<number, TagsApi>();
  readonly tagsService: TagsService;

  constructor() {
    makeAutoObservable(this);
    this.tagsService = new TagsService();
  }

  * fetchTags(): Generator {
    try {
      this.status = Status.Pending;
      const response = (yield this.tagsService.getAll()) as AxiosResponse<TagsApiCollection>;
      this.resetTags();
      this.setTagsFromApi(response.data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  * createTag(name: string): Generator<unknown, number | null> {
    try {
      this.status = Status.Pending;
      const response = (yield this.tagsService.create(name)) as AxiosResponse<number>;
      this.status = Status.Fulfilled;
      return response.data;
    } catch (e) {
      this.status = Status.Rejected;
      return null;
    }
  }

  get tagIds(): Array<number> {
    return [...this.tags.keys()];
  }

  getTagById(id: number): TagsApi | null {
    return this.tags.get(id) || null;
  }

  getTagNameById = (id: number): string => this.getTagById(id)?.name || '';

  setTagsFromApi(data: TagsApiCollection): void {
    data.forEach((tag) => this.tags.set(tag.id, tag));
  }

  resetTags(): void {
    this.tags.clear();
  }
}
