import './axios.config';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import axios, { AxiosResponse } from 'axios';
// import { ErrorMessage } from 'components/elements/ErrorMessage';
import { errorInterceptor } from 'utils/interceptors';
import { AppRoot } from './AppRoot';


// const isProduction = process.env.NODE_ENV === 'production';
// export const getSentryDSN = (isProd: boolean): string => (isProd
//   ? 'https://3bf4c1a9de0c4097a901c6818e5e8a0b@mysentry.mcko.ru/3'
//   : '');
//
// Sentry.init({
//   dsn: getSentryDSN(isProduction),
//   integrations: [new Integrations.BrowserTracing()],
//   // environment: 'production',
//   tracesSampleRate: 1.0,
// });

axios.interceptors.response.use((response: AxiosResponse) => response, errorInterceptor);

const mountNode = document.getElementById('app');

ReactDOM.render(
  <BrowserRouter>
    <AppRoot />
  </BrowserRouter>,
  mountNode,
);
