export enum AvailablePersonalDataFilters {
  academicYear = 'academicYear',
  fioOrEmail = 'fioOrEmail',
  departmentName = 'departmentName',
  cityRegion = 'cityRegion',
  schoolOperator = 'schoolOperator',
  certificateAccessStatus = 'certificateAccessStatus',
  certificateType = 'certificateType',
  metaEventId = 'metaEventId',
}
