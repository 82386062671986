import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';
import { Status } from 'constants/status';
import { useGlobalStores } from 'hooks/useGlobalStores';

import styles from './styles.scss';


const NotFound404Component = (): ReactElement => {
  const { profileTypeStore } = useGlobalStores();

  return (
    <Grid container className={styles.root}>
      {profileTypeStore.status === Status.Fulfilled && <p className={styles.notFound}>Страница не найдена</p>}
    </Grid>
  );
};

export const NotFound404 = observer(NotFound404Component);
