import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';
import { EventUsersApiModel } from 'store/apiModels/eventUsers/types';
import { globalStore } from 'store/contexts';
import { FilterInput } from 'store/utilityClasses/FilterInput';
import { Pagination } from 'store/utilityClasses/Pagination';
import { setFiltersToStore } from 'utils/filter';
import { DepartmentTypesId } from 'common/departmens/enums';
import { EventUserStatuses } from 'common/user/enum';
import debounce from 'lodash-es/debounce';
import { EventsAcceptParams } from 'store/apiClients/events';
import { AvailableEventUsersFilters } from './enums';


type FiltersWithCallbacks = AvailableEventUsersFilters.email | AvailableEventUsersFilters.timeslotId |
AvailableEventUsersFilters.status;

export class RegisteredMembersFiltersState {
  eventId: number;
  email = new FilterInput(AvailableEventUsersFilters.email);
  timeslotId = new FilterInput(AvailableEventUsersFilters.timeslotId);
  status = new FilterInput<EventUserStatuses>(AvailableEventUsersFilters.status);
  pagination: Pagination;
  selectDepartment = new FilterInput(AvailableEventUsersFilters.departmentId);
  selectRegion = new FilterInput(AvailableEventUsersFilters.regionId);
  selectCity = new FilterInput(AvailableEventUsersFilters.cityId);
  isSelectedMoscow = false;
  readonly eventUsersStore = globalStore.eventUsersStore;
  readonly departmentsStore = globalStore.departmentsStore;
  readonly citiesStore = globalStore.citiesStore;
  readonly eventsStore = globalStore.eventsStore;

  constructor(eventId: number) {
    makeAutoObservable(this, { }, { autoBind: true });
    this.eventId = eventId;
    this.pagination = new Pagination(this.fetchEventUsers);
    setFiltersToStore<this, FiltersWithCallbacks>(
      this,
      [AvailableEventUsersFilters.email, AvailableEventUsersFilters.timeslotId, AvailableEventUsersFilters.status],
      this.fetchEventUsersDebounced,
      this.pagination.resetPage,
    );
  }

  get departmentId(): number | null {
    return this.selectDepartment.selectId;
  }

  get regionId(): number | null {
    return this.selectRegion.selectId;
  }

  get cityId(): number | null {
    return this.selectCity.selectId;
  }

  selectAction(isSelectedMoscow = true): void {
    this.isSelectedMoscow = isSelectedMoscow;
    this.pagination.resetPage();
    this.fetchEventUsers();
  }

  * fetchEventUsers(): Generator {
    const response = (yield this.eventUsersStore.fetchEventUsers({
      eventId: this.eventId,
      email: this.email.value,
      school: this.departmentsStore.getShortNameById(this.departmentId, DepartmentTypesId.school) || null,
      city: this.isSelectedMoscow ? 'Москва' : this.citiesStore.getCityTitleById(this.cityId),
      timeslotId: this.timeslotId.selectedNumberValue,
      status: this.status.value,
      page: this.pagination.page,
    })) as AxiosResponse<EventUsersApiModel[]>;
    this.pagination.setPages(response.headers);
  }

  fetchEventUsersDebounced = debounce((): void => {
    this.fetchEventUsers();
  }, 800);

  * acceptEvent(params: Pick<EventsAcceptParams, 'timeslotId' | 'userId'>): Generator {
    yield this.eventsStore.acceptEvent({ eventId: this.eventId, ...params });
    yield this.fetchEventUsers();
  }

  resetFilters(): void {
    this.selectCity.clearFilter();
    this.selectRegion.clearFilter();
    this.selectDepartment.clearFilter();
    this.email.clearFilter();
    this.timeslotId.clearFilter();
    this.status.clearFilter();
    this.pagination.resetPage();
    this.fetchEventUsers();
  }
}
