import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { Button } from '@material-ui/core';
import { Flex } from 'components/elements/Utilities/Flex';
import styles from './styles.scss';


const ButtonsAcceptorComp = (): ReactElement => {
  const { landingStore: { onSave, onCancel } } = useGlobalStores();

  return (
    <Flex justify="flex-end" className={styles.root}>
      <Button className={styles.button} color="primary" variant="contained" onClick={onSave}>Сохранить</Button>
      <Button className={styles.button} onClick={onCancel}>Отменить</Button>
    </Flex>
  );
};

export const ButtonsAcceptor = observer(ButtonsAcceptorComp);
