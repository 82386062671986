import { makeAutoObservable } from 'mobx';
import { SingleSelect } from 'store/utilityClasses/Select/SingleSelect';
import { globalStore } from 'store/contexts';
import { CheckboxGroup } from 'store/utilityClasses/CheckboxGroup';
import { UserData, ApplyTimeslotSelectionParams } from './types';
import { createFormattedTimeslot } from './utils';


export class RegisteredMembersState {
  timeslotSelect = new SingleSelect();
  isUpdateTimeslotsMode = false;
  selectedUsers = new CheckboxGroup();

  readonly timeslotsStore = globalStore.timeslotsStore;
  readonly eventUsersStore = globalStore.eventUsersStore;
  readonly profileTypeStore = globalStore.profileTypeStore;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * onApplyTimeslotSelection({ eventId }: ApplyTimeslotSelectionParams): Generator {
    try {
      if (!(this.timeslotSelect.selectId && this.isTimeslotSelectionFilled)) return;
      const params = { timeslotId: this.timeslotSelect.selectId, userIds: this.selectedUsers.listIds };
      const isUpdated = yield this.timeslotsStore.appointUsersToTimeslot(params);

      if (!isUpdated) return;
      this.resetTimeslotsSelects();
      yield this.eventUsersStore.fetchEventUsers({ eventId });
    } catch (e) {
      //
    }
  }

  changeUpdateTimeslotsMode(): void {
    this.isUpdateTimeslotsMode = !this.isUpdateTimeslotsMode;
  }

  get isTimeslotSelectionFilled(): boolean {
    return Boolean(this.selectedUsers.selectedIds.size && this.timeslotSelect.selectId);
  }

  resetTimeslotsSelects(): void {
    this.timeslotSelect.reset();
    this.selectedUsers.clear();
    this.isUpdateTimeslotsMode = false;
  }

  get userData(): Map<number, UserData> {
    return this.eventUsersStore.eventUsersIds.reduce((acc, userId) => {
      const { profileTypeStore: { isSchool } } = this;

      const eventUser = this.eventUsersStore.getEventUserById(userId);
      if (!eventUser) return acc;

      const { status, timeslot, user, teamName = null } = eventUser;
  
      return acc.set(userId, {
        id: user.id,
        fio: user.fio,
        city: user.city,
        email: user.email,
        locationAddress: timeslot?.location?.locationAddress,
        locationName: timeslot?.location?.locationName,
        departmentName: user.departmentName,
        formattedTimeslot: createFormattedTimeslot({ timeslot, isSchool, status }),
        status,
        teamName
      });
    }, new Map<number, UserData>());
  }

  getUserDataByEventUserId = (userId: number): UserData | null => this.userData.get(userId) || null;

  get isSelectedAllUserIds(): boolean {
    return (
      Boolean(this.eventUsersStore.listUserIds.length)
      && this.selectedUsers.selectedIds.size === this.eventUsersStore.listUserIds.length
    );
  }
}
