/* eslint-disable react/no-danger */
import React, { ReactElement } from 'react';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { observer } from 'mobx-react-lite';
import { DefaultEditor } from 'react-simple-wysiwyg';
import clsx from 'clsx';
import { sanitize } from 'dompurify';
import styles from './styles.scss';


interface Props {
  content: string,
  isEdit: boolean,
  className?: string,
}

const EditorTextComp = ({ content, isEdit, className }: Props): ReactElement => {
  const { landingStore: { edited: { content: { value, onChange } } } } = useGlobalStores();

  const contentSanitized = sanitize(content);

  return isEdit ? (
    <div className={clsx(styles.wysiwyg, className)}>
      <DefaultEditor
        styles={{ dropdown: { display: 'none' }, contentEditable: { padding: '5px 15px' } }}
        value={value}
        onChange={onChange}
      />
    </div>
  ) : (
    <p
      className={clsx(styles.content, className)}
      dangerouslySetInnerHTML={{ __html: contentSanitized }}
    />
  );
};

export const EditorText = observer(EditorTextComp);
