import { ChangeEvent } from 'react';
import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';
import { AttachmentsStore } from 'store/apiModels/attachments';
import { GlobalEventService } from 'store/apiClients/globalEvent';
import { CertificateTemplatesCreateApi, CertificateTemplatesGetApi } from 'store/apiClients/globalEvent/types';

import { BlockPositionApi } from './types';
import { getImgRelHeight, getY } from './utils';
import { DEFAULT_FONT_SIZE, WIDTH_AREA } from './constants';
import { InputConstructor } from './utilityClasses/InputConstructor';
import { QRCodeConstructor } from './utilityClasses/QRCodeConstructor';


type StoreFieldsType = keyof typeof CertificateConstructorState.prototype;
type InputFieldName = Extract<
StoreFieldsType,
'fio' | 'school' | 'city' | 'result' | 'postSigner' | 'fioSigner' | 'date' | 'number'
>;
type QRCodeFieldName = Extract<StoreFieldsType, 'qr'>;

const INPUT_FIELDS: InputFieldName[] = [
  'fio', 'school', 'city', 'result', 'postSigner', 'fioSigner', 'date', 'number',
];

export class CertificateConstructorState {
  fio = new InputConstructor(getY(0));
  school = new InputConstructor(getY(1));
  city = new InputConstructor(getY(2));
  result = new InputConstructor(getY(3));
  postSigner = new InputConstructor(getY(4));
  fioSigner = new InputConstructor(getY(5));
  date = new InputConstructor(getY(6));
  number = new InputConstructor(getY(7));
  qr = new QRCodeConstructor(getY(8));

  imgRelHeight = 0;

  attachmentsStore = new AttachmentsStore();
  globalEventService = new GlobalEventService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * fetchCertificateTemplates(setDataCB: (data: CertificateTemplatesGetApi) => void, id: number): Generator {
    try {
      const { data } = (yield this.globalEventService.getCertificateTemplates(id)
      ) as AxiosResponse<CertificateTemplatesGetApi>;
      this.setDataFromApi(data);
      setDataCB(data);
    } catch (e) {
    //
    }
  }

  * createCertificateTemplates(setDataCB: (data: CertificateTemplatesGetApi) => void): Generator {
    try {
      const dataToApi = this.collectDataToApi;
      const { data } = (yield this.globalEventService.createCertificateTemplates(dataToApi)
      ) as AxiosResponse<CertificateTemplatesGetApi>;
      setDataCB(data);
    } catch (e) {
      //
    }
  }

  onUploadFile(event: ChangeEvent<HTMLInputElement>): void {
    this.attachmentsStore.clearAttachmentsInStore();
    this.attachmentsStore.onUploadFile(event);

    getImgRelHeight(event, this.setImgRelHeight);
  }

  setDataFromApi(data: CertificateTemplatesGetApi): void {
    const blocksPositions = JSON.parse(data.blockPosition) as BlockPositionApi[];

    this.setAllUnchecked();

    blocksPositions.forEach(({
      maxY, type, x, y, width, height, fontSize, text,
    }) => {
      if (INPUT_FIELDS.includes(type as InputFieldName)) {
        this[type as InputFieldName].switch.setChecked(true);
        this[type as InputFieldName].setPosition({ x, y, width, height });
        this[type as InputFieldName].selectFontSize.setId(fontSize || DEFAULT_FONT_SIZE);
        this[type as InputFieldName].input.setValue(text || '');
      }

      if (type === 'qr') {
        this.qr.switch.setChecked(true);
        this.qr.setPosition({ x, y, width, height });
      }

      this.setImgRelHeight(maxY);
    });

    this.attachmentsStore.clearAttachmentsInStore();
    this.attachmentsStore.pushAttachmentFromApi(data.cover);
  }

  get collectDataToApi(): CertificateTemplatesCreateApi {
    const parsedInputFields = INPUT_FIELDS.reduce((acc, fieldName) => (
      this[fieldName].switch.checked
        ? [...acc, {
          ...this[fieldName].position,
          text: this[fieldName].input.value,
          type: fieldName,
          fontSize: this[fieldName].selectFontSize.selectId || DEFAULT_FONT_SIZE,
          maxX: WIDTH_AREA,
          maxY: this.imgRelHeight,
        }]
        : acc
    ), [] as BlockPositionApi[]);
    const parsedQrCodeField: BlockPositionApi = {
      ...this.qr.position,
      type: 'qr',
      maxX: WIDTH_AREA,
      maxY: this.imgRelHeight,
    };
    const parsedBlocksPositions = [...parsedInputFields];
    if (this.qr.switch.checked) parsedBlocksPositions.push(parsedQrCodeField);

    const blockPosition = JSON.stringify(parsedBlocksPositions);
    const cover = this.attachmentsStore.attachments[0];

    return { blockPosition, cover };
  }

  setAllUnchecked(): void {
    INPUT_FIELDS.forEach((fieldName) => this[fieldName].switch.setChecked(false));
    this.qr.switch.setChecked(false);
  }

  setImgRelHeight(height: number): void {
    this.imgRelHeight = height;
  }

  get isPictureUploaded(): boolean {
    return Boolean(this.attachmentsStore.attachments.length);
  }

  get isConstructorValid(): boolean {
    return this.isPictureUploaded && this.isAllPositionsValid;
  }

  get isAllPositionsValid(): boolean {
    return [...INPUT_FIELDS, 'qr' as QRCodeFieldName]
      .filter((fieldName) => this[fieldName].switch.checked)
      .every((fieldName) => this[fieldName].isValidPosition(this.imgRelHeight));
  }

  get isShowErrorPositionText(): boolean {
    return this.isPictureUploaded && !this.isAllPositionsValid;
  }
}
