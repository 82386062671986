import React from 'react';
import { Routes } from 'common/routes/enums';


type LazyType = React.LazyExoticComponent<() => React.ReactElement>;

export type RoutesComponentsType = {
  Main: LazyType,
  Registration: LazyType,
  Events: LazyType,
  OperatorSignIn: LazyType,
  RegistrationOfOperator: LazyType
  WithoutEmail: LazyType
  Event: LazyType,
  EventManageGroups: LazyType,
  EventCreateGroups: LazyType,
  CreateEventPage: LazyType,
  EditEvent: LazyType,
  RegisteredMembers: LazyType,
  StudentsPage: LazyType,
  Profile: LazyType,
  ResultPage: LazyType,
  ResultEditPage: LazyType,
  EventResults: LazyType,
  CertificatesTable: LazyType,
  Check: LazyType,
  CreateLocation: LazyType,
  DirectoriesTable: LazyType,
  CreateGlobalEvent: LazyType,
  EditGlobalEvent: LazyType,
  GlobalEvents: LazyType,
  Teams: LazyType
  StudentsPhotos: LazyType,
  MyEvents: LazyType,
  MyEvent: LazyType
  CombineEvents: LazyType,
  CreateCombineEvent: LazyType
};

export type NavLinkType = {
  readonly title: string,
  readonly children?: NavLinkType[],
  readonly isExternal?: boolean,
  readonly to?: string,
};

const NAV_LINK_AGREEMENT: NavLinkType = { to: Routes.agreement, title: 'Сертификаты' };
const NAV_LINK_TEAMS: NavLinkType = { to: Routes.teams, title: 'Команды' };
const NAV_LINK_LOCATIONS: NavLinkType = { to: Routes.locations, title: 'Площадки' };
const NAV_LINK_COMBINE_EVENTS: NavLinkType = { to: Routes.combineEvents, title: 'Глобальные события' };
const NAV_LINK_DIRECTORIES: NavLinkType = {
  title: 'Справочники',
  children: [
    NAV_LINK_LOCATIONS
  ]
};

export const NAV_LINKS: Array<NavLinkType> = [
  { to: Routes.main, title: 'Главная' },
  { to: Routes.events, title: 'События' },
];

export const NAV_LINKS_STUDENTS: Array<NavLinkType> = [
  ...NAV_LINKS,
  { to: Routes.myEvents, title: 'Мои события' }
];

export const NAV_LINKS_UNIVERSITY: Array<NavLinkType> = [
  ...NAV_LINKS,
  NAV_LINK_DIRECTORIES,
];

export const NAV_LINKS_SCHOOL: Array<NavLinkType> = [
  ...NAV_LINKS,
  { to: Routes.students, title: 'Наши учащиеся' },
  { to: Routes.studentsPhotos, title: 'Фото учащихся' },
  NAV_LINK_AGREEMENT,
  NAV_LINK_DIRECTORIES,
  NAV_LINK_TEAMS,
];

export const NAV_LINKS_OPERATOR: Array<NavLinkType> = [
  ...NAV_LINKS,
  { to: Routes.globalEvents, title: 'Метасобытия' },
  NAV_LINK_AGREEMENT,
  {
    ...NAV_LINK_DIRECTORIES,
    children: [
      ...NAV_LINK_DIRECTORIES?.children,
      NAV_LINK_COMBINE_EVENTS
    ]
  }
];
