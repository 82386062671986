import axios, { AxiosResponse } from 'axios';
import { attachmentsApi } from 'constants/api';
import { AttachmentEntityType } from 'store/apiClients/events/types';


export class AttachmentsService {
  createFile = async (data: FormData): Promise<AxiosResponse<AttachmentEntityType>> => (
    axios.post(`${attachmentsApi}/attach`, data)
  );

  createLink = async (link: string): Promise<AxiosResponse<AttachmentEntityType>> => (
    axios.post(`${attachmentsApi}/attachLink`, null, { params: { link } })
  );

  deleteById = async (id: number): Promise<AxiosResponse> => (
    axios.delete(`${attachmentsApi}/delete`, {
      params: {
        attachmentIds: id,
      },
    })
  );
}
