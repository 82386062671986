import { AxiosResponse } from 'axios';
import { ChangeEvent } from 'react';
import { makeAutoObservable } from 'mobx';
import { globalStore } from 'store/contexts';
import { DepartmentTypesId } from 'common/departmens/enums';
import {
  getInitialArrayParam,
  updateSearchParamInURL,
  deleteSearchParamInURL,
  getInitialParamStr,
} from 'utils/searchParams/utils';
import { Pagination } from 'store/utilityClasses/Pagination';
import { EventCardCollectionType } from 'store/apiModels/events/types';
import { FilterInput } from 'store/utilityClasses/FilterInput';
import { TypeOfEvent } from 'store/states/createEvent/utilityClasses/SelectTypeOfEvent/enum';
import { AvailableEventsFilters, DonmFilter } from './enums';
import { Checkbox } from 'store/utilityClasses/Checkbox/Checkbox';
import { EventsGetParams } from 'store/apiClients/events';


export class EventsFiltersState {
  academicYearId = new FilterInput<number>(AvailableEventsFilters.academicYearId);
  partnerId = new FilterInput(AvailableEventsFilters.partnerId);
  departmentId = new FilterInput(AvailableEventsFilters.departmentId);
  directionId = new FilterInput(AvailableEventsFilters.directionId);
  trendId = new FilterInput(AvailableEventsFilters.trendId);
  eventTypeId: TypeOfEvent | null = getInitialParamStr(AvailableEventsFilters.eventTypeId) as TypeOfEvent;
  donm = new FilterInput<DonmFilter>(AvailableEventsFilters.donm);
  tags: number[] = getInitialArrayParam(AvailableEventsFilters.tags);
  pagination: Pagination;
  onlyActualCheckbox = new Checkbox(true);
  
  readonly eventsStore = globalStore.eventsStore;
  readonly directionsStore = globalStore.directionsStore;
  readonly tagsStore = globalStore.tagsStore;
  readonly departmentsStore = globalStore.departmentsStore;
  readonly trendsStore = globalStore.trendsStore;
  readonly academicYearStore = globalStore.academicYearStore;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.pagination = new Pagination(this.fetchEvents);
    this.academicYearId = new FilterInput(
      AvailableEventsFilters.academicYearId,
      this.fetchEvents,
      this.pagination.resetPage,
    );
    this.partnerId = new FilterInput(
      AvailableEventsFilters.partnerId,
      undefined,
      this.resetDepartmentFilter,
    );
    this.departmentId = new FilterInput(
      AvailableEventsFilters.departmentId,
      this.fetchEvents,
      this.pagination.resetPage,
    );
    this.directionId = new FilterInput(
      AvailableEventsFilters.directionId,
      this.fetchEvents,
      this.resetTrendFilter,
    );
    this.trendId = new FilterInput(
      AvailableEventsFilters.trendId,
      this.fetchEvents,
      this.pagination.resetPage,
    );
    this.donm = new FilterInput(
      AvailableEventsFilters.donm,
      this.fetchEvents,
      this.pagination.resetPage,
    );
    this.initialFetch();
  }

  initialFetch(): void {
    this.directionsStore.fetchDirections();
    this.tagsStore.fetchTags();
    if (this.partnerId.selectedNumberValue) {
      this.departmentsStore.fetchDepartmentsByType({ type: this.partnerId.selectedNumberValue });
    }
    if (this.directionId.selectedNumberValue) {
      this.trendsStore.fetchTrends({ directionId: this.directionId.selectedNumberValue });
    }
  }

  setEventType(type: TypeOfEvent | null, onlySetValue: boolean = false): void {
    this.eventTypeId = type;
    if (onlySetValue) return;
    this.pagination.resetPage();
    updateSearchParamInURL(AvailableEventsFilters.eventTypeId, String(this.eventTypeId));
    this.fetchEvents();
  }

  setTags(ids: number[], onlySetValue: boolean = false): void {
    this.tags = ids;
	if (onlySetValue) return;
    this.pagination.resetPage();
    updateSearchParamInURL(AvailableEventsFilters.tags, String(this.tags));
    this.fetchEvents();
  }

  changeActualCheckbox(e: ChangeEvent<HTMLInputElement>): void {
    this.onlyActualCheckbox.value = e.target.checked;
    
    if (this.onlyActualCheckbox.value) {
      this.academicYearId.clearFilter();
      this.fetchEvents();
    }
  }
  
  * initialFetchEvents(): Generator {
    yield this.academicYearStore.fetchYears();
    updateSearchParamInURL(AvailableEventsFilters.academicYearId, this.academicYearId.value);
    this.fetchEvents();
  }

  * fetchEvents(): Generator {
    const params: EventsGetParams = {
      partnerId: this.departmentId.selectedNumberValue,
      directionId: this.directionId.selectedNumberValue,
      trendId: this.trendId.selectedNumberValue,
      tags: this.tags,
      donmFilter: this.donm.selectedValue,
      page: this.pagination.page,
      eventType: this.eventTypeId,
    };
    
    if (this.onlyActualCheckbox.value) {
      params.onlyActual = true;
    } else {
      params.schoolYear = this.academicYearId.selectedNumberValue
    }
    
    const response = (yield this.eventsStore.fetchEvents(params)) as AxiosResponse<EventCardCollectionType>;
    this.pagination.setPages(response.headers);
  }

  resetFilters(): void {
    this.academicYearId.clearFilter();
    this.partnerId.clearFilter();
    this.departmentId.clearFilter();
    this.directionId.clearFilter();
    this.trendId.clearFilter();
    this.donm.clearFilter();
    this.trendsStore.trends.clear();
    this.eventTypeId = null;
    this.tags = [];
    this.onlyActualCheckbox.value = true;
    deleteSearchParamInURL(AvailableEventsFilters.tags);
    deleteSearchParamInURL(AvailableEventsFilters.eventTypeId);
    this.pagination.resetPage();
    this.fetchEvents();
  }

  resetDepartmentFilter(): void {
    this.pagination.resetPage();

    if (this.departmentId.selectedNumberValue) {
      this.departmentId.clearFilter();
      this.fetchEvents();
    }

    this.departmentsStore.fetchDepartmentsByType({
      type: this.partnerId.selectedNumberValue || DepartmentTypesId.school,
    });
  }

  resetTrendFilter(): void {
    this.pagination.resetPage();
    this.trendId.clearFilter();

    if (this.directionId.selectedNumberValue) {
      this.trendsStore.fetchTrends({ directionId: this.directionId.selectedNumberValue });
    }
  }
}
