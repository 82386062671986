import React, { ReactElement, useState } from 'react';
import {
  DialogActions, Dialog, Button, IconButton, Checkbox, DialogContent, Typography, FormControlLabel,
} from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';

import styles from './styles.scss';


interface Props {
  open: boolean,
  buttonConfirmText?: string,
  buttonCancelText?: string,
  contentText: string | JSX.Element,
  onClose: () => void,
  onConfirm: () => void,
  required?: boolean,
  label?: string
}

export const DialogWithAccepted = ({
  open,
  buttonConfirmText = 'Принять',
  buttonCancelText = 'Отклонить',
  contentText,
  onClose,
  onConfirm,
  label = 'Согласен',
  required,
}: Props): ReactElement => {
  const [isAccepted, setIsAccepted] = useState(false);

  const onClickConfirm = () => {
    onConfirm();
    onClose();
  };

  const onToggleCheckbox = () => {
    setIsAccepted(!isAccepted);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={styles.dialog}
      disableBackdropClick={required}
      disableEscapeKeyDown={required}
    >
      {!required && (
        <IconButton aria-label="закрыть окно" className={styles.buttonClose} onClick={onClose}>
          <IconClose />
        </IconButton>
      )}
      <DialogContent className={styles.dialogContent} dividers>
        <Typography>{contentText}</Typography>
        <FormControlLabel
          label={label}
          control={(
            <Checkbox color="primary" checked={isAccepted} onChange={onToggleCheckbox} name="dialog" />
          )}
        />
      </DialogContent>
      <DialogActions className={styles.buttonActionBox}>
        <Button
          disabled={!isAccepted}
          onClick={onClickConfirm}
          color="primary"
        >
          {buttonConfirmText}
        </Button>
        {!required && <Button onClick={onClose} color="primary">{buttonCancelText}</Button>}
      </DialogActions>
    </Dialog>
  );
};
