import axios, { AxiosResponse } from 'axios';
import { ProfileStatuses } from 'common/profile/enums';
import { userApi } from 'constants/api';
import { Profile, User } from 'store/apiModels/user/types';
import { UpdateParams, UpdateProfileParams } from './types';


export class UserService {
  getOperators = async (status: ProfileStatuses): Promise<AxiosResponse> => axios.get(
    '/auth/user/operators',
    { params: { status: status.toUpperCase() } },
  );

  getUserById = async (userId: number): Promise<AxiosResponse<User>> => (
    axios.get(`${userApi}/getUserById`, {
      params: {
        userId,
      },
    })
  );

  changeStatus = async (userId: number, status: ProfileStatuses): Promise<AxiosResponse> => axios.put(
    `/auth/user/changeStatus?userId=${userId}&status=${status.toUpperCase()}`,
  );

  updateProfile = async (profile: UpdateProfileParams): Promise<AxiosResponse<Profile>> => (
    axios.post(`${userApi}/updateProfile`, profile)
  );

  update = async ({
    departmentId, profileType, status, email, fio, shortname, gradeId, isVkSchool = false,
    vkCityId, vkCityName, vkRegionId, vkRegionName, vkSchoolId, trendIds, birthDate, snils, avatarId
  }: UpdateParams): Promise<AxiosResponse> => {
    const school = isVkSchool ? { vkRegionId, vkRegionName, vkCityId, vkCityName, vkSchoolId } : undefined;

    return axios.put(`${userApi}/fillUserInfo`, {
      departmentUnit: {
        // если школа или вуз из регионов
        ...(departmentId && !isVkSchool && { id: departmentId }),
        shortname,
        school,
      },
      ...(gradeId && { studentProfile: { grade: gradeId } }),
      profileType,
      status,
      user: {
        fio,
        email,
        birthDate,
        snils
      },
      trendIds,
      ...(avatarId && { fotoAttachment: { id: avatarId } })
    });
  };
}
