import React, { ReactElement } from 'react';
import { Create as IconPencil } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { BlockKeys } from 'store/apiModels/landing/types';
import styles from './styles.scss';


interface Props {
  blockKey: BlockKeys,
  className?: string,
}

const ButtonEditComp = ({ blockKey, className }: Props): ReactElement => {
  const {
    landingStore: { onEditMode, isLoading },
    profileTypeStore: { isOperator, isTestOperator },
  } = useGlobalStores();

  if (!isOperator || isTestOperator) return <></>;

  return (
    <IconButton
      disabled={isLoading}
      aria-label="перейти в режим редактирования"
      color="primary"
      className={clsx(styles.root, className)}
      onClick={() => onEditMode(blockKey)}
    >
      <IconPencil />
    </IconButton>
  );
};

export const ButtonEdit = observer(ButtonEditComp);
