/**
 * Тип события: теория, практика, консультация
 * (не путать с форматом проведения (очное/заочное))
 *
 * Перечислению специально дано такое название, чтобы не пересекалось с форматом проведения
 */
export enum TypeOfEvent {
  THEORY = 'theory',
  PRACTICE = 'practice',
  CONSULTATION = 'consultation',
}
