import axios, { AxiosResponse } from 'axios';
import { usersApi, eventsApi } from 'constants/api';
import {OurStudents, AfterUpdateLocation, AllStudents} from 'store/states/ourStudents/types';
import {ExportToXlsxParams, GetAllStudentsParams, GetStudentsParams} from './types';


export class OurStudentsService {
  getAllStudents = async ({
    fio = null, page = 1, perPage = 100, onlyArchived = false
  }: GetAllStudentsParams = {}): Promise<AxiosResponse<AllStudents[]>> => axios.get(
    `${usersApi}/all_students_by_operator_school`,
    {
      params: {
        page,
        per_page: perPage,
        ...onlyArchived && { only_archived: true },
        ...!onlyArchived && { without_archived: true }
      }
    }
  );

  getStudents = async ({
    eventTitle = null, fio = null, page = 1, perPage = 100, status = null, timeslotId = null,
    schoolYear = null, withArchived = false, directionId = null, trendId = null
  }: GetStudentsParams = {}): Promise<AxiosResponse<OurStudents[]>> => axios.get(
    `${usersApi}/students_by_operator_school`, {
      params: {
        event_title: eventTitle,
        fio,
        page,
        per_page: perPage,
        status,
        timeslot_id: timeslotId,
        school_year: schoolYear,
        with_archived: withArchived,
        direction_id: directionId,
        trend_id: trendId
      },
    },
  );

  exportToXlsx = async ({ schoolYear = null }: ExportToXlsxParams = {}): Promise<AxiosResponse> => (
    axios.get(`${usersApi}/students_by_operator_school/xlsx`, {
      params: {
        school_year: schoolYear,
      },
      responseType: 'blob',
    })
  );

  exportStudentFromOthersSchoolsToXlsx = async ({ schoolYear = null }: ExportToXlsxParams = {}): Promise<AxiosResponse> => (
    axios.get(`${usersApi}/students_of_other_schools/xlsx`, {
      params: {
        school_year: schoolYear,
      },
      responseType: 'blob',
    })
  );

  updateDesiredLocation = async (
    eventId: number, userId: number, locationId: number,
  ): Promise<AxiosResponse<AfterUpdateLocation>> => (
    axios.put(`${eventsApi}/change_desired_location`, null, {
      params: {
        event_id: eventId,
        user_id: userId,
        location_id: locationId,
      },
    })
  );
}
