import axios, { AxiosResponse } from 'axios';
import { eventsApi } from 'constants/api';
import { DateFormats } from 'constants/dateFormats';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { EventsCalendarType } from 'store/apiModels/eventsCalendar/types';


export interface EventsCalendarGetParams {
  from?: string,
  to?: string,
}

export class EventsCalendarService {
  private readonly fromDefault = format(startOfMonth(new Date()), DateFormats.isoDate);
  private readonly toDefault = format(endOfMonth(new Date()), DateFormats.isoDate);

  get = async (
    { from = this.fromDefault, to = this.toDefault }: EventsCalendarGetParams = {},
  ): Promise<AxiosResponse<EventsCalendarType[]>> => axios.get(
    `${eventsApi}/calendar`, {
      params: { from, to },
    },
  );
}
