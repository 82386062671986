import { makeAutoObservable } from 'mobx';
import { Input } from 'store/utilityClasses/Input/Input';
import { Block, BlockKeys } from '../types';


export class EditableBlock {
  blockKey = new Input();
  blockKeyLast = new Input();
  title = new Input();
  content = new Input();

  constructor() {
    makeAutoObservable(this);
  }

  setAll(blockKey: BlockKeys, block: Block): void {
    this.blockKey.setValue(blockKey);
    this.blockKeyLast.setValue(blockKey);
    this.title.setValue(block.title);
    this.content.setValue(block.content);
  }

  clearAll(): void {
    this.blockKey.clear();
    this.title.clear();
    this.content.clear();
  }
}
