import React, { ReactElement, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useGlobalStores } from 'hooks/useGlobalStores';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import styles from './styles.scss';


let ErrorNotificationTimerId: ReturnType<typeof setTimeout>;

export const stopErrorNotificationTimer = (): void => clearTimeout(ErrorNotificationTimerId);

const ErrorNotificationComponent = (): ReactElement => {
  const [visible, setVisibility] = useState(true);
  const { errorStore: { messagesArr, messages }, profileTypeStore: { isRegistered } } = useGlobalStores();

  const removeError = () => {
    if (!visible) {
      messages.delete(messagesArr[0]);
      setVisibility(true);
    }
  };

  useEffect(() => {
    if (messagesArr.length) {
      ErrorNotificationTimerId = setTimeout(() => {
        setVisibility(false);
      }, 5000);
    }
    return stopErrorNotificationTimer;
  }, [messagesArr.length]);

  if (!messagesArr.length || !isRegistered) return <></>;

  return (
    <div
      className={clsx(styles.root, !visible && styles.hide)}
      onAnimationEnd={removeError}
      onClick={stopErrorNotificationTimer}
      onKeyDown={stopErrorNotificationTimer}
      role="button"
      tabIndex={-1}
    >
      <p>{messagesArr[0]}</p>
      <IconButton
        title="Закрыть"
        size="small"
        className={styles.closeBtn}
        onClick={() => setVisibility(false)}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export const ErrorNotification = observer(ErrorNotificationComponent);

