import { TimeslotApiModel } from 'common/timeslot/types';
import { EventUserStatuses } from 'common/user/enum';


export interface CreateFormattedTimeslotParams {
  timeslot?: TimeslotApiModel
  isSchool: boolean
  status: EventUserStatuses
}

export function createFormattedTimeslot(params: CreateFormattedTimeslotParams): string {
  const { timeslot, status, isSchool } = params;
  const statusIsSubscribed = status === EventUserStatuses.subscribed;

  return (timeslot && !(isSchool && statusIsSubscribed))
    ? timeslot.timeInterval.replace('-', '–')
    : '–';
}
