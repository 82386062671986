import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { RegionApiModel, RegionResponseApi, RegionsService } from 'store/apiClients/regions';
import { Status } from 'constants/status';


export class RegionsStore {
  private readonly regionService = new RegionsService();
  status = Status.Initial;
  regions = new Map<number, RegionApiModel>();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get regionsIds(): number[] {
    return [...this.regions.keys()];
  }

  getRegionById(id: number): RegionApiModel | null {
    return this.regions.get(id) || null;
  }

  getNameRegionById(id: number | null): string {
    return this.getRegionById(Number(id))?.title || '';
  }

  * fetchRegions(withFederalCities = false): Generator {
    try {
      this.status = Status.Pending;
      const { data } = (yield this.regionService.get()) as AxiosResponse<RegionResponseApi>;
      this.regions.clear();
      this.setFederalCities(withFederalCities);
      this.setRegionsFromApi(data.items);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  setRegionsFromApi(apiData: RegionApiModel[]): void {
    apiData.forEach((region) => {
      this.regions.set(region.id, region);
    });
  }

  setFederalCities(withFederalCities: boolean): void {
    if (!withFederalCities) return;
    this.regions
      .set(1, { id: 1, title: 'Москва' })
      .set(2, { id: 2, title: 'Санкт-Петербург' });
  }

  removeFederalCities(): void {
    this.regions.delete(1);
    this.regions.delete(2);
  }
}

