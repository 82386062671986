import axios, { AxiosResponse } from 'axios';
import { resultsApi } from 'constants/api';
import { ResultParams } from 'store/apiModels/results/types';
import { ResultEditApi } from 'store/states/resultEdit/types';
import { AttachmentsResponseType, ResultApi, SaveEventResultAttachmentsParams } from './types';


export class ResultsService {
  getResult = async (params: ResultParams): Promise<AxiosResponse<ResultApi>> => (
    axios.get(`${resultsApi}/userResult`, { params })
  );

  getResults = async (userId: number): Promise<AxiosResponse<ResultApi[]>> => (
    axios.get(`${resultsApi}/userResults`, {
      params: {
        userId,
      },
    })
  );

  saveResult = async (params: ResultEditApi): Promise<AxiosResponse<ResultApi>> => (
    axios.post(`${resultsApi}/save/test_result`, params)
  );

  saveFastResult = async (params: ResultEditApi): Promise<AxiosResponse<ResultApi>> => (
    axios.put(`${resultsApi}/save/short_test_result`, params)
  );

  exportEventResult = async (eventId: number): Promise<AxiosResponse> => (
    axios.get(`${resultsApi}/export/eventResults/xlsx`, { params: { eventId }, responseType: 'blob' })
  );

  importEventResults = async (data: FormData, eventId: number): Promise<AxiosResponse> => (
    axios.post(`${resultsApi}/import/fromXlsx`, data, {
      params: {
        eventId,
      },
    })
  );

  saveEventResultAttachments = async (
    { attachments, eventId }: SaveEventResultAttachmentsParams,
  ): Promise<AxiosResponse> => (
    axios.post(`${resultsApi}/save/event_result_attachments`, {
      attachments,
      eventId,
    })
  );

  deleteEventResultAttachments = async (eventId: number): Promise<AxiosResponse> => (
    axios.delete(`${resultsApi}/delete/event_result_attachments`, { params: { eventId } })
  );

  getEventResultAttachments = async (eventId: number): Promise<AxiosResponse<AttachmentsResponseType>> => (
    axios.get(`${resultsApi}/getEventResultAttachments`, { params: { eventId } })
  );

  exportStatement = async (eventId: number): Promise<AxiosResponse> => (
    axios.get(`${resultsApi}/export/statement/xlsx`, { params: { eventId }, responseType: 'blob' })
  );
}
