import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, IconButton } from '@material-ui/core';
import {
  ExitToApp as ExitToAppIcon,
  AccountCircle as AccountCircleIcon,
} from '@material-ui/icons';
import { useGlobalStores } from 'hooks/useGlobalStores';

import styles from './styles.scss';
import {LOGIN_URL} from "../../../constants/api";


const LogInButtonComp = (): ReactElement => {
  const { profileTypeStore: { profile, logout } } = useGlobalStores();

  if (!profile) {
    return (
      <Button href={LOGIN_URL} className={styles.root}>
        <AccountCircleIcon />
        <span className={styles.label}>Войти</span>
      </Button>
    );
  }

  return (
    <IconButton onClick={logout} className={styles.root} title="Выйти">
      <ExitToAppIcon />
    </IconButton>
  );
};

export const LogInButton = observer(LogInButtonComp);
