import { makeAutoObservable } from 'mobx';
import { Input } from 'store/utilityClasses/Input/Input';
import { SingleSelect } from 'store/utilityClasses/Select/SingleSelect';
import { DepartmentTypesId } from 'common/departmens/enums';
import { LocationsService } from 'store/apiClients/locations';
import { AxiosResponse } from 'axios';
import { DepartmentTypeSelect } from 'store/utilityClasses/DepartmentTypeSelect';
import { Status } from 'constants/status';
import { globalStore } from 'store/contexts';


export class CreateLocationState {
  status = Status.Initial;

  departmentSelect = new SingleSelect();
  departmentTypeSelect = new DepartmentTypeSelect();

  inputAddress = new Input();
  inputPlatform = new Input();

  readonly service = new LocationsService();
  readonly profileTypeStore = globalStore.profileTypeStore;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.departmentTypeSelect.setSelectId(DepartmentTypesId.jurisdiction);
  }

  * createLocation(): Generator {
    if (this.organizationId) {
      try {
        this.status = Status.Pending;
        const data = {
          locationAddress: this.inputAddress.value,
          locationName: this.inputPlatform.value,
          orgId: this.organizationId,
        };

        (yield this.service.create(data)) as AxiosResponse<number | null>;
        this.status = Status.Fulfilled;
      } catch (e) {
        this.status = Status.Rejected;
      }
    }
  }

  setDepartmentTypeField(id: DepartmentTypesId): void {
    this.departmentSelect.reset();
    this.departmentTypeSelect.setSelectId(id);
  }

  get isValidForm(): boolean {
    const { inputAddress, inputPlatform, organizationId } = this;
    const inputsValid = inputAddress.value.trim() && inputPlatform.value.trim();

    return Boolean(inputsValid && organizationId);
  }

  get organizationId(): number | null {
    const { profileTypeStore, departmentSelect } = this;

    return profileTypeStore.isOperator ? departmentSelect.selectId : profileTypeStore.departmentUnitId;
  }

  get isLoaded(): boolean {
    return Boolean(this.status === Status.Fulfilled);
  }
}
