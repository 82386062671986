import { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';
import { LocationsService } from 'store/apiClients/locations';
import { LocationApi } from 'store/apiClients/locations/types';


export class LocationsState {
  locations = new Map<number, LocationApi>();

  readonly service = new LocationsService();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  * fetchByEvent(eventId: number): Generator {
    try {
      const { data } = (yield this.service.getByEvent(eventId)) as AxiosResponse<LocationApi[]>;
      this.clearLocations();
      this.setLocationsFromApi(data);
    } catch (e) {
      //
    }
  }

  * fetchByOrgId(orgId: number): Generator {
    try {
      const { data } = (yield this.service.getAllByOrg(orgId)) as AxiosResponse<LocationApi[]>;
      this.clearLocations();
      this.setLocationsFromApi(data);
    } catch (e) {
      //
    }
  }

  setLocationsFromApi(data: LocationApi[]): void {
    data.forEach((location) => {
      this.locations.set(location.id, location);
    });
  }

  getLocationNameById(id: string | number | null): string {
    return this.locations.get(Number(id))?.locationName || '';
  }

  getLocationAddressById(id: number | string | null): string {
    return this.locations.get(Number(id))?.locationAddress || '';
  }

  clearLocations(): void {
    this.locations.clear();
  }

  get locationsIds(): number[] {
    return [...this.locations.keys()];
  }
}
