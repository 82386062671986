import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { CitiesService, CityModel, CityResponseApi, GetCityParams } from 'store/apiClients/cities';
import { Status } from 'constants/status';


export class CitiesStore {
  private citiesService = new CitiesService();
  status = Status.Initial;
  count = 0;
  cities = new Map<number, CityModel>();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get listCitiesIds(): number[] {
    return [...this.cities.keys()];
  }

  getCityById(id: number): CityModel | null {
    return this.cities.get(id) || null;
  }

  getCityTitleById(id: number | null): string {
    return this.getCityById(Number(id))?.title || '';
  }

  * fetchCities(params: GetCityParams = {}): Generator {
    try {
      this.status = Status.Pending;
      const { data } = (yield this.citiesService.get(params)) as AxiosResponse<CityResponseApi>;
      this.clear();
      this.setCitiesFromApi(data);
      this.status = Status.Fulfilled;
    } catch (e) {
      this.status = Status.Rejected;
    }
  }

  setCitiesFromApi(apiData: CityResponseApi): void {
    apiData.items.forEach((city) => {
      this.cities.set(city.id, city);
    });
    this.count = apiData.count;
  }

  clear(): void {
    this.cities.clear();
  }
}

