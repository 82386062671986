import { ChangeEvent } from 'react';
import { makeAutoObservable } from 'mobx';
import {
  getInitialParamStr,
  updateSearchParamInURL,
  deleteSearchParamInURL,
} from 'utils/searchParams/utils';


export class FilterInput<T = string> {
  filterName = '';
  value: T | string;
  fetchData: () => void;
  resetPage: () => void;

  constructor(filterName: string, fetchData?: () => void, resetPage?: () => void) {
    this.filterName = filterName;
    this.value = getInitialParamStr(this.filterName);
    this.fetchData = fetchData || (() => {});
    this.resetPage = resetPage || (() => {});
    makeAutoObservable(this, { filterName: false, fetchData: false, resetPage: false }, { autoBind: true });
  }

  get selectedValue(): T | null {
    return this.value as T || null;
  }

  get selectedNumberValue(): number | null {
    return this.value === '' ? null : Number(this.value);
  }

  get selectId(): number | null {
    return this.selectedNumberValue;
  }

  setId(id: number | null): void {
    this.setSelectFilter(null, id);
  }

  setFilter(event: ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    this.value = value;
    updateSearchParamInURL(this.filterName, this.value);
    this.resetPage();
    this.fetchData();
  }

  setSelectFilter(event: unknown, selectValue: number | string | null, needUpdateURL: boolean = true): void {
    this.value = selectValue === null ? '' : String(selectValue);
    if (needUpdateURL) {
      updateSearchParamInURL(this.filterName, this.value);
    }
    this.resetPage();
    this.fetchData();
  }

  clearFilter(): void {
    this.value = '';
    deleteSearchParamInURL(this.filterName);
  }

  reset(): void {
    this.clearFilter();
  }
}
