import { ResultCheckType } from 'store/apiClients/results/types';
import { mapResultStatusCheckWithName } from './constants';
import { ResultStatusCheck } from './enums';
import { ResultScores } from './types';


export const getResultCheckNameByType = <T>(
  checkType: ResultCheckType | undefined, defaultName: T,
): ResultStatusCheck | T => (
    (checkType !== undefined && mapResultStatusCheckWithName.get(checkType)) || defaultName
  );

export const getFinalResult = (result?: ResultScores): string => {
  if (!result || !result.isProctorChecked) return '—';
  return `${result.score}/${result.maxScore}`;
};
