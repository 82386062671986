import { makeAutoObservable } from 'mobx';
import { TypeOfEvent } from './enum';


export class SelectTypeOfEventClass {
  public type: TypeOfEvent | null = null;

  constructor(type: TypeOfEvent | null = null) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.setType(type);
  }

  setType(type: TypeOfEvent | null): void {
    this.type = type;
  }

  reset(): void {
    this.type = null;
  }
}
