import axios, { AxiosResponse } from 'axios';
import { metaEventApi, metaEventsApi } from 'constants/api';

import { GetGlobalEventsParams } from 'store/apiModels/globalEvents/types';
import {
  CertificateTemplatesCreateApi,
  CertificateTemplatesGetApi,
  CreateMetaEventType,
  MetaEventCodeType,
  MetaEventType,
  OrganizerApi,
} from './types';


export class GlobalEventService {
  createGlobalEvent = async (data: CreateMetaEventType | null): Promise<AxiosResponse<CreateMetaEventType | null>> => (
    axios.post(`${metaEventApi}/save`, data)
  );

  getDirections = async (): Promise<AxiosResponse<MetaEventCodeType[]>> => (
    axios.get(`${metaEventApi}/directions`)
  );

  getGlobalEventsTypes = async (): Promise<AxiosResponse<MetaEventCodeType[]>> => (
    axios.get(`${metaEventApi}/globalEvents`)
  );

  getGlobalEventsOrganizers = async (): Promise<AxiosResponse<OrganizerApi[]>> => (
    axios.get(`${metaEventsApi}/organizers`)
  );

  getGlobalEvents = async ({ page = 1, perPage = 100, organizerId, globalEventId }: GetGlobalEventsParams = {})
  : Promise<AxiosResponse<MetaEventType[]>> => (
    axios.get(metaEventsApi, {
      params: { page, perPage, organizer_id: organizerId, global_event_id: globalEventId },
    })
  );

  getCompetitionsByGlobalEventType = async (id: number): Promise<AxiosResponse<MetaEventCodeType[]>> => (
    axios.get(`${metaEventApi}/competitions/byGlobalEventCode`, { params: { globalEventCode: id } })
  );

  getGlobalEventById = async (id: number): Promise<AxiosResponse<MetaEventType>> => (
    axios.get(`${metaEventApi}/getById`, { params: { metaEventId: id } })
  );

  getCertificateTemplates = async (templateId: number): Promise<AxiosResponse<CertificateTemplatesGetApi>> => (
    axios.get(`${metaEventApi}/certificateTemplates/getById`, { params: { templateId } })
  );

  createCertificateTemplates = async (
    data: CertificateTemplatesCreateApi,
  ): Promise<AxiosResponse<CertificateTemplatesGetApi>> => (
    axios.post(`${metaEventApi}/certificateTemplates/save`, data)
  );
}
