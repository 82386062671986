import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { Editor } from '../Editor';
import styles from './styles.scss';


const RedMessageCmp = (): ReactElement => {
  const BLOCK_KEY = 'redMessage';
  const {
    landingStore: { getBlock },
    profileTypeStore: { profile, isOperator },
  } = useGlobalStores();
  const { content } = getBlock(BLOCK_KEY);
  const isEmptyContent = !content || content === '<br>';

  if (isEmptyContent && (!profile || !isOperator)) {
    return <></>;
  }

  return (
    <div className={styles.warnBanner}>
      <Editor
        blockKey={BLOCK_KEY}
        withContent
        classes={{
          root: styles.editor,
          content: styles.content,
          viewMode: styles.viewMode,
        }}
      />
    </div>
  );
};

export const RedMessage = observer(RedMessageCmp);
