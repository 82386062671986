import { SubscribeStatus } from 'store/apiModels/events/types';
import { TypeOfEvent } from 'store/states/createEvent/utilityClasses/SelectTypeOfEvent/enum';


export const title = {
  [SubscribeStatus.accessible]: 'Зарегистрироваться',
  [SubscribeStatus.registered]: 'Вы зарегистрированы',
  [SubscribeStatus.reg_closed]: 'Регистрация окончена',
  [SubscribeStatus.inaccessible]: '',
  [SubscribeStatus.expectResults]: '',
  [SubscribeStatus.results]: '',
};

/**
 * Названия противоположные событиям
 */
export const pairEventText = {
  [TypeOfEvent.PRACTICE]: 'теоретические',
  [TypeOfEvent.THEORY]: 'практические',
  [TypeOfEvent.CONSULTATION]: '',
};

export const pairEventType = {
  [TypeOfEvent.THEORY]: TypeOfEvent.PRACTICE,
  [TypeOfEvent.PRACTICE]: TypeOfEvent.THEORY,
  [TypeOfEvent.CONSULTATION]: TypeOfEvent.CONSULTATION,
};
