import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { makeAutoObservable } from 'mobx';
import { EventsCalendarGetParams, EventsCalendarService } from 'store/apiClients/eventsCalendar';
import { StudentStatus } from 'store/states/eventsNext/types';
import { EventsCalendarType } from './types';


export class EventsCalendarStore {
  private readonly eventsCalendarService;
  status = Status.Initial;
  eventsCalendar = new Map<string, StudentStatus[]>();

  constructor() {
    makeAutoObservable(this);
    this.eventsCalendarService = new EventsCalendarService();
  }

  * fetchEventsCalendar(range?: EventsCalendarGetParams): Generator {
    try {
      this.status = Status.Pending;
      const { data } = (yield this.eventsCalendarService.get(range)) as AxiosResponse<EventsCalendarType[]>;
      this.setEventsCalendar(data);
      this.status = Status.Fulfilled;
    } catch (error) {
      this.status = Status.Rejected;
    }
  }

  setEventsCalendar(data: EventsCalendarType[]): void {
    data.forEach((item) => {
      this.eventsCalendar.set(item.date, item.statuses);
    });
  }
}
