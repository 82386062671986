import { AttachmentEntityType } from 'store/apiClients/events/types';
import { StudentStatus } from 'store/states/eventsNext/types';
import { TypeOfEvent } from 'store/states/createEvent/utilityClasses/SelectTypeOfEvent/enum';
import { EventStatus } from 'common/events/enums';
import { ProfileType } from 'common/profile/enums';


export enum SubscribeStatus {
  reg_closed = 'reg_closed',
  closed = 'closed',
  accessible = 'accessible',
  inaccessible = 'inaccessible',
  registered = 'registered',
  expectResults = 'expect_results',
  results = 'results',
}

export interface GlobalEventCardType {
  directionIds: number[];
  endAt: string;
  id: number;
  name: string;
  startAt: string;
  subscribed: boolean;
  subscriberProfileType: ProfileType
}

export interface EventOrganizationLocation {
  id: number,
  locationName: string,
  locationAddress: string,
}

export interface EventOrganization {
  orgId: number,
  orgName: string,
  orgTypeId: number,
  locations: Array<EventOrganizationLocation>,
}

export interface SchoolEventResult {
  countStage1: number,
  countStage2: number,
  departmentUnitId: number,
  departmentUnitName: string
}

export interface CategorySchoolEventResult {
  categoryName: string;
  topList: SchoolEventResult[];
}

export type EventCardType = {
  acceptedCount?: number,
  subscribedCount?: number,
  attachments?: AttachmentEntityType[],
  direction: {
    id: number,
    name: string
    latinName: string,
  },
  eventEnd: string,
  eventStart: string,
  fullText: string,
  id: number,
  eventStatus: EventStatus,
  subscribeStatus?: SubscribeStatus,
  studentStatus?: StudentStatus,
  editable?: boolean,
  partners: Array<
  {
    id: number,
    partnerName: string,
    partnerTypeId: number,
  }>
  regEnd: string,
  regStart: string,
  shortText: string,
  tags: Array<
  {
    id: number,
    name: string
  }>,
  title: string,
  trends: Array<{
    directionId: number,
    id: number,
    name: string
  }>
  regConditions?: string, // JSON
  maxScore?: number,
  openReg: boolean,
  openRegLink: string | null,
  giveChooseLocation: boolean,
  giveChooseTimeslot: boolean,
  inPresentio: boolean,
  eventOrgs: Array<EventOrganization>,
  donm: boolean,
  eventType: TypeOfEvent,
  isTeam: boolean,
  teamPartNumber: number | null,
  reserve: boolean,
  reserveStreamNumber?: number | null
};

export type EventCardCollectionType = Array<Exclude<EventCardType, 'openRegLink'>>;
