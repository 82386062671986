import axios, { AxiosResponse } from 'axios';
import { academicYearApi } from 'constants/api';
import { AcademicYearApi } from './types';


export class AcademicYearService {
  getYears = async (): Promise<AxiosResponse<AcademicYearApi[]>> => (
    axios.get(academicYearApi)
  );
}

