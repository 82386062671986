export enum ProfileType {
  admin = 'admin',
  operator = 'operator',
  school = 'school',
  student = 'student',
  teacher = 'teacher',
  technical = 'technical',
  university = 'university',
  parent = 'parent',
  verifier = 'verifier'
}

export enum ProfileStatuses {
  accepted = 'accepted',
  canceled = 'canceled',
  waiting = 'waiting',
}
