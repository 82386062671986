import React, { ReactElement, useState } from 'react';
import { ClickAwayListener, MenuList, MenuItem, Popover } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { NavLinkType } from 'constants/routes';
import { Routes } from 'common/routes/enums';
import styles from './styles.scss';
import clsx from 'clsx';

interface Props {
  item: NavLinkType,
  isActive: boolean
}

const DropdownNavItemButtonComp = ({ item, isActive }: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  
  const handleClick = (event: MouseEvent) => {
	setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
	setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  return (
	<>
	  <button
		className={clsx(styles.navItemButton, isActive && styles.active)}
		onClick={handleClick}
	  >
		{item.title}
	  </button>
	  <Popover
		id={id}
		open={open}
		anchorEl={anchorEl}
		onClose={handleClose}
		anchorOrigin={{
		  vertical: 'bottom',
		  horizontal: 'left',
		}}
		transformOrigin={{
		  vertical: 'top',
		  horizontal: 'left',
		}}
	  >
		<ClickAwayListener onClickAway={handleClose}>
		  <MenuList>
			{item.children?.map((item: NavLinkType) => (
			  <MenuItem key={item.title} onClick={handleClose}>
				<NavLink
				  exact={item.to === Routes.landing}
				  to={item.to}
				  className={styles.navItemButtonLink}
				  activeClassName={styles.active}
				>
				  {item.title}
				</NavLink>
			  </MenuItem>
			))}
		  </MenuList>
		</ClickAwayListener>
	  </Popover>
	</>
  );
};

export const DropdownNavItemButton = observer(DropdownNavItemButtonComp);
