import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { CreateCombineEventDto, UpdateCombineEventDto } from 'store/apiClients/combineEvents/types';
import { CombineEventsService } from 'store/apiClients/combineEvents';
import { GlobalEventCardType } from 'store/apiModels/events/types';
import { DateInterval } from 'store/utilityClasses/DateInterval/DateInterval';
import { Input } from 'store/utilityClasses/Input/Input';
import { ProfileType } from '../../../common/profile/enums';
import { MultiSelect } from '../../utilityClasses/Select/MultiSelect';

export class CreateCombineEventState {
  status = Status.Initial;
  
  selectDirection = new MultiSelect();
  inputEventName = new Input();
  dateIntervalEvent = new DateInterval();
  profileTypeId: ProfileType | null = null;
  
  subscribed: boolean = false;
  
  readonly service = new CombineEventsService();
  
  constructor() {
	makeAutoObservable(this, {}, { autoBind: true });
  }
  
  * fetchCombineEventById(id: number): Generator {
	try {
	  this.status = Status.Pending;
	
	  const response = yield this.service.getOne(id) as AxiosResponse<GlobalEventCardType>;
	  this.setApiData(response.data);
	
	  this.status = Status.Fulfilled;
	} catch (err) {
	  console.error(err);
	  this.status = Status.Rejected;
	}
  }
  
  
  * fetchCreateCombineEvent(data: CreateCombineEventDto): Generator {
	try {
	  this.status = Status.Pending;
	  
	  yield this.service.create(data);
	  
	  this.status = Status.Fulfilled;
	} catch (err) {
	  console.error(err);
	  this.status = Status.Rejected;
	}
  }
  
  * fetchUpdateCombineEvent(data: UpdateCombineEventDto): Generator {
	try {
	  this.status = Status.Pending;
	  
	  yield this.service.update(data);
	  
	  this.status = Status.Fulfilled;
	} catch (err) {
	  console.error(err);
	  this.status = Status.Rejected;
	}
  }
  
  setApiData(event: GlobalEventCardType): void {
  	const { directionIds, name, startAt, endAt, subscriberProfileType, subscribed } = event;

  	this.inputEventName.setValue(name);
  	this.profileTypeId = subscriberProfileType;
  	this.selectDirection.setValue(directionIds || []);
	this.dateIntervalEvent.onChange({ startDate: new Date(startAt), endDate: new Date(endAt) });
	this.subscribed = subscribed;
  }
  
  setSelectedProfileType(profileTypeId: ProfileType): void {
	this.profileTypeId = profileTypeId
  };
  
  setSelectedDirection(ids: number[]): void {
	this.selectDirection.setValue(ids);
  }
  
  get isValidForm(): boolean {
	return !!this.inputEventName.value.trim()
	  && !!this.profileTypeId
	  && !!this.dateIntervalEvent.startDate
	  && !!this.dateIntervalEvent.endDate
	  && this.selectDirection.selectedIds.length > 0;
  }
  
  get isLoaded(): boolean {
    return this.status === Status.Fulfilled;
  }
}
