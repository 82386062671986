import { makeAutoObservable } from 'mobx';
import { Switch } from 'store/utilityClasses/Switch';
import { Input } from 'store/utilityClasses/Input/Input';
import { Position } from 'store/states/certificateConstructor/types';
import { SingleSelect } from 'store/utilityClasses/Select/SingleSelect';
import { DEFAULT_FONT_SIZE, DEFAULT_INPUT_POSITION } from 'store/states/certificateConstructor/constants';


export class InputConstructor {
  input = new Input();
  switch = new Switch(true);
  selectFontSize = new SingleSelect(DEFAULT_FONT_SIZE);
  position: Position = DEFAULT_INPUT_POSITION;

  constructor(defaultPosition: Partial<Position> = DEFAULT_INPUT_POSITION) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.setPosition(defaultPosition);
  }

  setPosition(position: Partial<Position>): void {
    this.position = { ...this.position, ...position };
  }

  isValidPosition(imgRelHeight: number): boolean {
    return (this.position.y + this.position.height) <= imgRelHeight;
  }
}
