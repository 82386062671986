import { TimeslotApiModel } from 'common/timeslot/types';
import { EventGroupModelApi, UpdateGroupParams } from 'store/apiClients/eventGroups/types';


interface CreateNewEventGroupParams {
  eventGroup: EventGroupModelApi,
  timeslot: Partial<TimeslotApiModel>,
  userLimit?: number,
}

export const createNewEventGroup = ({ eventGroup, timeslot, userLimit }: CreateNewEventGroupParams): UpdateGroupParams => ({
  id: eventGroup.id,
  eventId: eventGroup.eventId,
  userCount: eventGroup.userCount,
  userLimit: userLimit || eventGroup.userLimit,
  timeslots: [timeslot],
});
