import React, { ReactElement, ReactNode } from 'react';
import { FooterListItem } from 'components/pages/LandingPage/constants/footer';
import styles from './styles.scss';


interface Props {
  title: ReactNode;
  list: Array<FooterListItem>;
}

export const Card = ({ title, list }: Props): ReactElement => (
  <div className={styles.root}>
    <div className={styles.title}>{title}</div>
    {list.map((item) => (
      <a
        key={item.caption}
        className={styles.caption}
        href={item.href}
      >
        {item.caption}
      </a>
    ))}
  </div>
);
