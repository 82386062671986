import axios, { AxiosResponse } from 'axios';
import { directionsApi } from 'constants/api';
import { DirectionAndTrendsApi, DirectionsApi } from 'store/apiClients/directions/types';


export class DirectionsService {
  getAll = async (): Promise<AxiosResponse<DirectionsApi[]>> => (
    axios.get(`${directionsApi}/all`)
  );

  getDirectionAndTrends = async (): Promise<AxiosResponse<DirectionAndTrendsApi[]>> => (
    axios.get(`${directionsApi}/direction_and_trends`)
  );
}
