import { makeAutoObservable } from 'mobx';
import { ChangeEvent } from 'react';
import { CertificateTypes } from 'store/apiClients/personalData/types';


export class SelectCertificateType {
  selectedId: CertificateTypes | null = CertificateTypes.PARTICIPANT;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setSelectId(event: ChangeEvent<Record<string, unknown>>, value: CertificateTypes | null): void {
    this.selectedId = value;
  }
}
