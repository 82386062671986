import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, generatePath } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { Routes } from 'common/routes/enums';
import { REQUIRED_PROFILE_FIELDS } from './constants';
import styles from './styles.scss';

const IncompleteProfileModalComp = (): ReactElement => {
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const { profileTypeStore: { profile, isStudentPure } } = useGlobalStores();
  
  useEffect(() => {
    if (profile) {
      const fieldsToUpdate = [];
      if (!profile.fotoAttachment) fieldsToUpdate.push(REQUIRED_PROFILE_FIELDS[0]);
      if (!profile.user.birthDate) fieldsToUpdate.push(REQUIRED_PROFILE_FIELDS[1]);
      if (!profile.user.snils) fieldsToUpdate.push(REQUIRED_PROFILE_FIELDS[2]);

      setInvalidFields(fieldsToUpdate);
	  setOpen(Boolean((!profile.fotoAttachment || !profile.user.snils || !profile.user.birthDate) && isStudentPure));
	}
  }, [profile]);
  
  const onConfirm = () => {
	onClose();
	history.push(generatePath(Routes.profile, {id: profile?.user.id}));
  };
  
  const onClose = () => {
	setOpen(false);
  };
  
  return (
	<Dialog
	  open={open}
	  onClose={onClose}
	  className={styles.dialog}
	  classes={{
		paper: styles.paperOverride
	  }}
	>
	  <IconButton aria-label="закрыть окно" className={styles.buttonClose} onClick={onClose}>
		<IconClose />
	  </IconButton>
	  <DialogContent className={styles.dialogContent} dividers>
		<Typography variant="h5">Ваш профиль не заполнен полностью</Typography>
		<Box className={styles.contentBlock}>
		  <Typography>В Вашем профиле есть незаполненные поля. Для корректной работы в системе Вам необходимо:</Typography>
		  <ul className={styles.requirementsList}>
			{
				invalidFields.map((requirement, idx) => (
				<li key={idx}><Typography>{requirement}</Typography></li>
			  ))
			}
		  </ul>
		</Box>
	  </DialogContent>
	  <DialogActions className={styles.buttonActionBox}>
		<Button onClick={onConfirm} color="primary">Перейти в редактирование профиля</Button>
		<Button onClick={onClose} color="primary">Закрыть</Button>
	  </DialogActions>
	</Dialog>
  );
};

export const IncompleteProfileModal = observer(IncompleteProfileModalComp);
