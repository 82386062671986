import { ProfileType } from 'common/profile/enums';
import { User } from 'store/apiModels/user/types';
import { CreateNewUserProfileOutput, CreateNewUserProfileParams } from './types';


export const isMoscowOrganization = (user: User): boolean => {
  const { profileType } = user.profile;
  return profileType === ProfileType.operator || profileType === ProfileType.university;
};
export const createNewUserProfile = ({
  profileId, userId, isMoscow, isVkSchool, phoneNumber, departmentId, grade, vkCityId,
  vkRegionId, departmentShortname, fio, email, trendIds, birthDate, snils, avatarId
}: CreateNewUserProfileParams): CreateNewUserProfileOutput => {
  const school = {
    isMoscow,
    ...(vkCityId && { vkCityId }),
    ...(vkRegionId && { vkRegionId }),
    ...(departmentId && { vkSchoolId: departmentId }),
  };

  const user = {
    id: userId,
    ...(birthDate && { birthDate }),
    ...(snils && { snils }),
    ...(fio && { fio }),
    ...(email && { email }),
  };

  return ({
    id: profileId,
    ...(phoneNumber && { phoneNumber }),
    departmentUnit: {
      ...(isMoscow && departmentId && { id: departmentId }),
      ...(isVkSchool && { shortname: departmentShortname }),
      ...(isVkSchool && { school }),
    },
    ...(grade && { studentProfile: { grade } }),
    user,
    trendIds,
    ...(avatarId && { fotoAttachment: { id: avatarId } })
  });
};
