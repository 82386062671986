import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Classes } from 'common/types';
import { useGlobalStores } from 'hooks/useGlobalStores';
import { BlockKeys } from 'store/apiModels/landing/types';
import { Loader } from 'components/elements/Loader';
import { Variant } from '@material-ui/core/styles/createTypography';
import { EditorTitle } from './EditorTitle';
import { EditorText } from './EditorText';
import { ButtonEdit } from './ButtonEdit';
import { ButtonsAcceptor } from './ButtonsAcceptor';
import styles from './styles.scss';


interface Props {
  blockKey: BlockKeys,
  variantTitle?: Variant,
  withTitle?: boolean,
  withContent?: boolean,
  classes?: Classes<'root' | 'title' | 'content' | 'viewMode'>,
}

const EditorComp = ({ blockKey, variantTitle, withTitle, withContent, classes }: Props): ReactElement => {
  const { landingStore: { getBlock, isUploading, isEditing } } = useGlobalStores();

  const { title, content } = getBlock(blockKey);

  const isEdit = isEditing(blockKey);
  const isEmpty = !title && !content;

  return (
    <div className={clsx(
      styles.root,
      classes?.root,
      isEdit && styles.rootEdit,
      isEmpty && styles.empty,
    )}
    >
      {withTitle && (
        <EditorTitle
          variant={variantTitle}
          title={title}
          isEdit={isEdit}
          className={classes?.title}
        />
      )}
      {withContent && (
        <EditorText
          content={content}
          isEdit={isEdit}
          className={clsx(
            classes?.content,
            withTitle && isEdit && styles.withTitle,
            !isEdit && classes?.viewMode,
          )}
        />
      )}
      {isEdit
        ? <ButtonsAcceptor />
        : <ButtonEdit blockKey={blockKey} className={styles.button} />}
      <Loader show={isUploading(blockKey)} />
    </div>
  );
};

export const Editor = observer(EditorComp);
