import { ChangeEvent } from 'react';
import { InputNumeric } from 'store/utilityClasses/InputNumeric';
import { RegConditionApiType } from 'store/apiClients/events/types';
import { RegConditionTypes } from 'store/apiClients/events/enums';
import { SelectParallels } from 'store/states/createEvent/utilityClasses/SelectParallels';
import { SelectProfileType } from 'store/states/createEvent/utilityClasses/SelectProfileType';
import { RegConditionByResultForm } from 'store/states/createEvent/utilityClasses/RegConditionByResultForm';
import { RegConditionProfileTypes } from 'store/states/createEvent/utilityClasses/SelectProfileType/types';
import { RegConditionOptionalFormType } from './types';
import { registrationConditionForms } from './constants';


type AllAvailableForms =
  SelectParallels | SelectProfileType | InputNumeric<ChangeEvent<HTMLInputElement>> | RegConditionByResultForm;

export const RegConditionParser = {
  fromBackend: ({ cond_type, cond_value }: RegConditionApiType): RegConditionOptionalFormType | null => {
    switch (cond_type) {
      case RegConditionTypes.BY_RESULTS: {
        if (typeof cond_value === 'object') {
          return { [cond_type]: new registrationConditionForms[cond_type](cond_value) };
        }
        return null;
      }
      case RegConditionTypes.BY_PARALLELS:
        if (typeof cond_value === 'string') {
          const parallelsValue = new Set(cond_value.split(',').map(Number));
          return { [cond_type]: new registrationConditionForms[cond_type](parallelsValue) };
        }
        return null;
      case RegConditionTypes.BY_PROFILE_TYPE:
        if (typeof cond_value === 'string') {
          return { [cond_type]: new registrationConditionForms[cond_type](cond_value as RegConditionProfileTypes) };
        }
        return null;
      case RegConditionTypes.BY_PARTICIPATION_IN_EVENT:
      case RegConditionTypes.BY_NOT_PARTICIPATION_IN_EVENT:
        if (typeof cond_value === 'string') {
          return { [cond_type]: new registrationConditionForms[cond_type](cond_value) };
        }
        return null;
      default: return null;
    }
  },

  toBackend: (type: RegConditionTypes, form: AllAvailableForms | undefined): RegConditionApiType | null => {
    if (form instanceof InputNumeric && form.value) {
      return {
        cond_type: type,
        cond_value: form.value,
      };
    }
    if (form instanceof RegConditionByResultForm && form.parsedRegConditionResultForms) {
      return {
        cond_type: type,
        cond_value: form.parsedRegConditionResultForms,
      };
    }
    if (form instanceof SelectProfileType && form.selectedType) {
      return {
        cond_type: type,
        cond_value: form.selectedType,
      };
    }
    if (form instanceof SelectParallels && form.stringifyParallels) {
      return {
        cond_type: type,
        cond_value: form.stringifyParallels,
      };
    }
    return null;
  },
};
