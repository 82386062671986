import { makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { Pagination } from 'store/utilityClasses/Pagination';
import { CombineEventsService } from 'store/apiClients/combineEvents';
import { GlobalEventCardType } from 'store/apiModels/events/types';

export class CombineEventsState {
  status = Status.Initial;
  combineEvents: GlobalEventCardType[] = [];
  
  pagination = new Pagination();
  
  readonly service = new CombineEventsService();
  
  constructor() {
	makeAutoObservable(this, {}, { autoBind: true });
  }
  
  * fetchCombineEvents(): Generator {
    try {
	  this.status = Status.Pending;
  
	  const { headers, data } = (yield this.service.getAll({
		page: this.pagination.page,
		per_page: 50
	  })) as AxiosResponse<any[]>;
	  this.pagination.setPages(headers);
	  this.combineEvents = data;
	  
	  this.status = Status.Fulfilled;
    } catch (err) {
      console.error(err);
	  this.status = Status.Rejected;
    }
  }
  
  * fetchDeleteCombineEvent(id: number): Generator {
    try {
	  this.status = Status.Pending;
	  
	  yield this.service.delete(id);
	  
	  this.fetchCombineEvents();
	  
	  this.status = Status.Fulfilled;
    } catch (err) {
      console.error(err);
	  this.status = Status.Rejected;
    }
  }
  
  get isLoading(): boolean {
	return this.status === Status.Pending;
  }
}
