import { makeAutoObservable } from 'mobx';


export class ErrorStore {
  messages = new Set<string>();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setError(message: string): void {
    this.messages.add(message);
  }

  get messagesArr(): string[] {
    return [...this.messages];
  }
}
