import { makeAutoObservable } from 'mobx';
import { SingleSelect } from 'store/utilityClasses/Select/SingleSelect';
import { MultiSelect } from 'store/utilityClasses/Select/MultiSelect';
import { Input } from 'store/utilityClasses/Input/Input';
import { UserStore } from 'store/apiModels/user';
import { globalStore } from 'store/contexts';
import { ProfileStatuses, ProfileType } from 'common/profile/enums';
import { RegionsStore } from 'store/apiModels/regions';
import { CitiesStore } from 'store/apiModels/cities';
import { AttachmentsStore } from 'store/apiModels/attachments';
import { DepartmentTypesId } from 'common/departmens/enums';
import { ProfileUserType, StepType } from './types';


export class RegistrationState {
  readonly userStore: UserStore = globalStore.userStore;
  readonly regionsStore: RegionsStore = globalStore.regionsStore;
  readonly citiesStore: CitiesStore = globalStore.citiesStore;
  readonly departmentsStore = globalStore.departmentsStore;
  readonly profileTypeStore = globalStore.profileTypeStore;
  // проекты
  readonly directionsStore = globalStore.directionsStore;
  // направления
  readonly trendsStore = globalStore.trendsStore;
  // аттачменты для загрузки фото (студенты)
  readonly attachmentStore = new AttachmentsStore();

  inputFullName = new Input();
  inputEmail = new Input();
  inputDate = new Input();
  inputSnils = new Input();

  selectRegion = new SingleSelect();
  selectCity = new SingleSelect();
  selectSchool = new SingleSelect();
  selectGrade = new SingleSelect();
  isSelectMoscow = false;
  trendSelect = new MultiSelect();


  step: StepType = 0;
  profileType: ProfileUserType | null = null;
  isSuccessfulUpdateUserData = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setStep(step: StepType): void {
    this.step = step;
  }

  setProfileType(profileType: ProfileUserType): void {
    this.profileType = profileType;
  }

  setIsSelectMoscow(isSelectMoscow = false): void {
    this.isSelectMoscow = isSelectMoscow;
  }

  get isSelectLocation(): boolean {
    return Boolean(this.isSelectMoscow || this.selectCity.selectId);
  }

  get isValidForm(): boolean {
    return Boolean(
      this.inputFullName.value.trim()
      && (this.profileType !== ProfileType.student || this.selectGrade.selectId)
      && this.isSelectLocation
      && this.selectSchool.selectId
      && this.snilsIsValid
      && this.birthDateIsValid
    );
  }
  
  get snilsIsValid(): boolean {
    return this.profileType === ProfileType.student ? Boolean(this.inputSnils.value.trim()) : true;
  }
  
  get birthDateIsValid(): boolean {
    return this.profileType === ProfileType.student ? Boolean(this.inputDate.value.trim()) : true;
  }

  get isSelectProfileType(): boolean {
    return Boolean(this.profileType);
  }

  get isTrendControlValid(): boolean {
    const trendCondition = Boolean(this.trendSelect.selectIds)
      && this.trendSelect.selectIds.length > 0;

    return this.profileType !== ProfileType.student || trendCondition;
  }

  * updateUserData(): Generator {
	if (!this.profileType) return;

    const {
      selectSchool, selectGrade, selectRegion, selectCity, trendSelect,
    } = this;
    const vkRegionName = this.regionsStore.getNameRegionById(selectRegion.selectId);
    const vkCityName = this.citiesStore.getCityTitleById(selectCity.selectId);
    const schoolShortname = this.departmentsStore.getShortNameById(
      selectSchool.selectId, DepartmentTypesId.school,
    );
    const avatarId = this.attachmentStore.images.length ? this.attachmentStore.images[0].id : null;

    try {
      yield this.userStore.updateUserData({
        profileType: this.profileType,
        email: this.inputEmail.value,
        fio: this.inputFullName.value,
        shortname: schoolShortname,
        departmentId: selectSchool.selectId,
        status: ProfileStatuses.waiting,
        gradeId: selectGrade.selectId,
        isVkSchool: !this.isSelectMoscow,
        birthDate: this.inputDate.value,
        snils: this.inputSnils.value,
        vkRegionName,
        vkCityName,
        vkRegionId: selectRegion.selectId,
        vkCityId: selectCity.selectId,
        vkSchoolId: selectSchool.selectId,
        // trendIds: trendSelect.selectIds,
		avatarId
      });

      this.profileTypeStore.fetchProfileType();
      this.isSuccessfulUpdateUserData = true;
    } catch (e) {
      this.isSuccessfulUpdateUserData = false;
    }
  }
}
